import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fade,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { makeStyles } from "@material-ui/styles";
import { exportView } from "../../crud/calendar.crud";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { useDispatch } from "react-redux";
import { Trans } from "react-i18next";

const useStyles = makeStyles(theme => ({
  calendarFullWidth: {
    width: "100%"
  },
  buttonNoWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }
}));

export const TaskCalendarRangeDialog = ({ userId, date }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDate, handleDateChange] = useState([
    date.toDate(),
    date.toDate()
  ]);

  useEffect(() => {
    handleDateChange([date.toDate(), date.toDate()]);
  }, [date.toISOString()]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onExport = async () => {
    try {
      await exportView(userId, selectedDate);
      setIsOpen(false);
      handleDateChange([date.toDate(), date.toDate()]);
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  };

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className={`btn btn-secondary ${classes.buttonNoWrap}`}
      >
        <Trans>Экспорт в Excel</Trans>
      </button>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          <Trans>Экспорт задач</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Trans>
              Выберите период, за который необходимо экспортировать задачи
            </Trans>
          </DialogContentText>
          <Calendar
            className={classes.calendarFullWidth}
            selectRange
            maxDetail="year"
            minDetail="year"
            onChange={date => handleDateChange(date)}
            value={selectedDate}
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            <Trans>Закрыть</Trans>
          </Button>
          <Button onClick={onExport} color="secondary">
            <Trans>Экспортировать</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
