import { useDispatch, useSelector } from "react-redux";
import { usePopupState } from "material-ui-popup-state/hooks";
import { useEffect } from "react";
import { closeTaskDialog } from "../redux/features/popperTaskFeatureSlice";

export const useTaskDialogState = () => {
  const dispatch = useDispatch();
  const popupState = usePopupState({
    variant: "popper",
    popupId: "task-dialog"
  });
  const { target, isOpen, hasWarning } = useSelector(state => state.popperTask);

  useEffect(() => {
    if (hasWarning && isOpen) {
      return;
    }

    popupState.setOpen(isOpen, target);
  }, [target, isOpen, hasWarning]);

  useEffect(() => () => dispatch(closeTaskDialog()), []);

  return popupState;
};
