import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import Auth from "./Auth";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import * as routerHelpers from "../router/RouterHelpers";
import { createSelector } from "reselect";
import { useQuery } from "../utils/use-query";
import { useTranslation } from "react-i18next";
import { ProtectedRoute } from "./ProtectedRoute";
import Calendar from "../pages/home/Calendar";
import { Subscription } from "../pages/home/Subscription";
import Settings from "../pages/home/Settings";
import { ErrorPage6 } from "../pages/errors/ErrorPage6";
import { ErrorPage1 } from "../pages/errors/ErrorPage1";
import Snackbar from "../components/Snackbar";
import { Statistics } from "../pages/home/Statistics";
import { useCookies } from "react-cookie";
import moment from "moment";
import { AdminRoutes } from "./AdminRoutes";
import { ModalTutorial } from "../components/tutorial/ModalTutorial";
import { ProjectRoutes } from "./ProjectRoutes";
import { useLocation, useRouteMatch } from "react-router";
import { usePrevious } from "../hooks/usePrevious";
import { ProjectView } from "../pages/home/ProjectView";
import Employees from "../pages/home/Employees";

const configSelector = createSelector(
  ({ auth }) => auth,
  ({ builder }) => builder.menuConfig,
  (authInfo, menuConfig) => ({ authInfo, menuConfig })
);

const allowedLanguages = ["ru", "en"];

export const Routes = withRouter(({ Layout, history }) => {
  const dispatch = useDispatch();
  const { authInfo, menuConfig } = useSelector(configSelector);
  const [cookies, setCookie] = useCookies(["start_url", "referer"]);
  const { user } = authInfo;

  /** косытль, todo: убрать через 2 минорных версии (добавлено на: 1.16.1) **/
  if (user && user.role === undefined) {
    dispatch({ type: "RESET" });
    history.push("/auth/login");
    return;
  }

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const userLastLocation = routerHelpers.getLastLocation();
  const query = useQuery();
  const { i18n } = useTranslation();
  const lang = query.get("lang");

  useEffect(() => {
    if (lang && allowedLanguages.includes(lang)) {
      (async () => {
        await i18n.changeLanguage(lang);
      })();
    }

    if (!cookies.start_url && history.location.pathname.match("auth")) {
      setCookie("start_url", document.location.href, {
        path: "/",
        expires: moment()
          .add(1, "month")
          .toDate()
      });
      setCookie("referer", document.referrer, {
        path: "/",
        expires: moment()
          .add(1, "month")
          .toDate()
      });
    }
  }, []);

  return (
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      <Switch>
        <ProtectedRoute
          authInfo={authInfo}
          allowedRoles={["root"]}
          path="/admin"
        >
          <Layout>
            <AdminRoutes />
          </Layout>
        </ProtectedRoute>

        <ProtectedRoute
          authInfo={authInfo}
          allowedRoles={["root", "owner", "manager"]}
          path="/calendar/:id?"
        >
          <Layout>
            <Calendar />
          </Layout>
        </ProtectedRoute>

        <ProtectedRoute
          authInfo={authInfo}
          allowedRoles={["root", "owner", "manager"]}
          path="/projects"
        >
          <Layout>
            <ProjectRoutes />
          </Layout>
        </ProtectedRoute>

        <Route
          path={`/project/view/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})?`}
          component={ProjectView}
        />

        {/*<ProtectedRoute*/}
        {/*  authInfo={authInfo}*/}
        {/*  allowedRoles={["root", "owner", "manager"]}*/}
        {/*  path="/subscription"*/}
        {/*>*/}
        {/*  <Layout>*/}
        {/*    <Subscription />*/}
        {/*  </Layout>*/}
        {/*</ProtectedRoute>*/}

        <ProtectedRoute
          authInfo={authInfo}
          allowedRoles={["root", "owner", "manager"]}
          path="/work-statistic/:id"
        >
          <Layout>
            <Statistics />
          </Layout>
        </ProtectedRoute>

        <ProtectedRoute
          authInfo={authInfo}
          allowedRoles={["root", "owner", "manager"]}
          exact
          path="/employees"
        >
          <Layout>
            <Employees />
          </Layout>
        </ProtectedRoute>

        <ProtectedRoute
          authInfo={authInfo}
          allowedRoles={["root", "owner", "manager"]}
          exact
          path="/settings"
        >
          <Layout>
            <Settings />
          </Layout>
        </ProtectedRoute>

        <Route path="/auth">
          <Auth authInfo={authInfo} />
        </Route>
        <Route path="/error" component={ErrorPage6} />
        <Route exact path="/logout" component={LogoutPage} />

        {user && user.status === "active" && (
          <Redirect exact from="/" to="/calendar" />
        )}
        {user && user.status === "deleted" && <Redirect to="/logout" />}
        {(!user || user.status !== "active") && <Redirect to="/auth" />}
        {user && <Redirect from="/auth" to={userLastLocation} />}
        <Route component={ErrorPage1} />
      </Switch>
      <ScrollToTop />
      <Snackbar />
      <ModalTutorial />
    </LayoutContextProvider>
  );
});

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const [, parent] = pathname.split("/");
  const prevPathname = usePrevious(parent);
  const match = useRouteMatch({ path: `/${prevPathname}` });

  useEffect(() => {
    if (match === null) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return <></>;
};
