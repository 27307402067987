import React, { useEffect, useMemo, useState } from "react";
import { Grow, Paper, Popper } from "@material-ui/core";
import { bindPopper } from "material-ui-popup-state";
import { TRANSITION_DURATION } from "../../redux/features/popperTaskFeatureSlice";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/styles";
import { usePopupState } from "material-ui-popup-state/hooks";

const useStyles = makeStyles(theme => ({
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      borderColor: `${theme.palette.info.main} transparent transparent transparent !important`,
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  popper: {
    zIndex: 10,
    maxWidth: "350px",
    marginLeft: "7px",
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  }
}));

export const ProjectSelectHelperPopper = ({
  containerRef,
  text,
  isOpen,
  arrow = true,
  type = "info",
  placement = "top"
}) => {
  const id = useMemo(() => Math.random(), []);
  const classes = useStyles();
  const popupState = usePopupState({
    variant: "popper",
    popupId: `helper-alert-${id}`
  });
  const [arrowRef, setArrowRef] = useState(null);

  useEffect(() => {
    popupState.setOpen(isOpen, containerRef.current);
  }, [isOpen]);

  return (
    <Popper
      {...bindPopper(popupState)}
      transition
      placement={placement}
      modifiers={{ arrow: arrow && { enabled: true, element: arrowRef } }}
      className={classes.popper}
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          timeout={TRANSITION_DURATION}
          in={popupState.isOpen}
          mountOnEnter
          unmountOnExit
        >
          <Paper>
            {arrow && <span className={classes.arrow} ref={setArrowRef} />}
            <Alert variant="outlined" severity={type}>
              {text}
            </Alert>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
