import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { LayoutSplashScreen, ThemeProvider } from "./_metronic";
import "./_metronic/_assets/sass/global/global.css";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTranslation, withTranslation } from "react-i18next";
import { ErrorBoundary } from "./app/ErrorBoundary";
import { CookiesProvider } from "react-cookie";

if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {});
}

function App({ store, Layout, persistor, basename }) {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  return (
    <Provider store={store} loading={<LayoutSplashScreen />}>
      <PersistGate persistor={persistor}>
        <React.Suspense fallback={<LayoutSplashScreen />}>
          <CookiesProvider>
            <BrowserRouter basename={basename}>
              <ErrorBoundary>
                <LastLocationProvider>
                  <ThemeProvider>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Routes Layout={Layout} />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </LastLocationProvider>
              </ErrorBoundary>
            </BrowserRouter>
          </CookiesProvider>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}

export default withTranslation()(App);
