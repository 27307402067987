import {
  resetPerformer,
  setPerformer
} from "../features/impersonatePerformerFeatureSlice";
import { setActiveUser } from "../features/calendarFeatureSlice";
import {
  fetchCompany,
  restorePerformer,
  setAuthTokens,
  setUser
} from "../features/authFeatureSlice";
import { impersonateById } from "../../crud/auth.crud";
import { openSnackbar } from "../features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { extractErrorInfo } from "../../utils/extractError";
import { getUsersList } from "./user.thunk";
import { batch } from "react-redux";

export const impersonate = (id, history) => (dispatch, getState) => {
  const { auth } = getState();
  batch(async () => {
    try {
      const { data } = await impersonateById(id);
      const { user, jwtToken, refreshToken } = data;
      if (user.status !== "active") {
        dispatch(
          openSnackbar(
            Fade,
            `Не возможно зайти под неактивным пользователем`,
            "info"
          )
        );
        return;
      }
      dispatch(setPerformer(auth));
      dispatch(
        setAuthTokens({
          token: jwtToken,
          refreshToken
        })
      );

      await dispatch(fetchCompany());
      await dispatch(getUsersList());

      dispatch(setActiveUser({ userId: user.id }));

      dispatch(
        setUser({
          user
        })
      );
      history.replace("/");
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  });
};

export const returnBack = history => async (dispatch, getState) => {
  const { impersonate } = getState();
  batch(async () => {
    dispatch(restorePerformer(impersonate));
    await dispatch(getUsersList());
    dispatch(resetPerformer());
    history.replace("/admin/registrations");
  });
};
