import React from "react";
import { TextField } from "@material-ui/core";
import TimeMaskInput from "../TimeMaskInput";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import clsx from "clsx";
import { Formik } from "formik";
import getTimezoneEntries from "../../utils/getTimezones";
import * as Yup from "yup";
import zones from "../../utils/timezones";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment-timezone";
import styled from "styled-components";

const timezones = getTimezoneEntries();

const CompanySchema = Yup.object().shape({
  email: Yup.string()
    .email("Неверный email")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  company: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  positionName: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле")
});

const Button = styled.button`
  &&& {
    padding: 13px 26px !important;
  }
`;

export default function FormCompany({
  loadingButtonStyle,
  loading,
  enableLoading,
  onSubmitCompany
}) {
  const { t } = useTranslation();
  const defaultTimeZone = moment.tz.guess();
  return (
    <Formik
      initialValues={{
        email: "",
        positionName: "",
        company: "",
        workStartsAt: "",
        workEndsAt: "",
        timeZone: defaultTimeZone
      }}
      validationSchema={CompanySchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        enableLoading();
        onSubmitCompany(values, setSubmitting, setStatus);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate={true}
          autoComplete="off"
          className="kt-form"
          onSubmit={handleSubmit}
        >
          <TextField
            type="text"
            margin="normal"
            label={t("Название компании")}
            className="kt-width-full"
            name="company"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.company}
            helperText={touched.company && t(errors.company)}
            error={Boolean(touched.company && errors.company)}
          />

          <TextField
            type="email"
            label={t("Корпоративный email")}
            margin="normal"
            className="kt-width-full"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email}
            helperText={touched.email && t(errors.email)}
            error={Boolean(touched.email && errors.email)}
          />

          <TextField
            type="text"
            margin="normal"
            label={t("Должность")}
            className="kt-width-full"
            name="positionName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.positionName}
            helperText={touched.positionName && t(errors.positionName)}
            error={Boolean(touched.positionName && errors.positionName)}
          />

          <TextField
            type="text"
            margin="normal"
            label={t("Начало рабочего дня")}
            className="kt-width-full"
            name="workStartsAt"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.workStartsAt}
            InputProps={{
              inputComponent: TimeMaskInput
            }}
            helperText={touched.workStartsAt && t(errors.workStartsAt)}
            error={Boolean(touched.workStartsAt && errors.workStartsAt)}
          />

          <TextField
            type="text"
            margin="normal"
            label={t("Конец рабочего дня")}
            className="kt-width-full"
            name="workEndsAt"
            onBlur={handleBlur}
            onChange={handleChange}
            InputProps={{
              inputComponent: TimeMaskInput
            }}
            value={values.workEndsAt}
            helperText={touched.workEndsAt && t(errors.workEndsAt)}
            error={Boolean(touched.workEndsAt && errors.workEndsAt)}
          />

          <Autocomplete
            name="timeZone"
            getOptionLabel={([, value]) => value}
            value={[values.timeZone, zones[values.timeZone]]}
            options={timezones}
            onChange={(e, value) => {
              if (Array.isArray(value)) {
                setFieldValue("timeZone", value[0]);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                size="small"
                margin="normal"
                label={t("Часовой пояс")}
              />
            )}
          />

          <div className="text-center pt-2">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              <Trans>Войти</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
