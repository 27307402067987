import React, { useState, useEffect } from "react";
import getDaysInMonth from "../../../utils/getDaysInMonth";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { holidaysSelector } from "../../../redux/features/calendarFeatureSlice";

export default function MiniCalendar({
  clickable,
  className = "",
  currentDate = moment(),
  setCurrentDate = () => {},
  handleOnPrevMonth,
  handleOnNextMonth
}) {
  const { i18n } = useTranslation();
  const months = {
    0: i18n.t("Январь"),
    1: i18n.t("Февраль"),
    2: i18n.t("Март"),
    3: i18n.t("Апрель"),
    4: i18n.t("Май"),
    5: i18n.t("Июнь"),
    6: i18n.t("Июль"),
    7: i18n.t("Август"),
    8: i18n.t("Сентябрь"),
    9: i18n.t("Октябрь"),
    10: i18n.t("Ноябрь"),
    11: i18n.t("Декабрь")
  };
  const today = moment();
  const [selectedDay, setSelectedDay] = useState(currentDate);
  const [days, setDays] = useState(getDaysInMonth(currentDate));
  useEffect(() => {
    setDays(getDaysInMonth(currentDate));
    // eslint-disable-next-line
  }, [currentDate]);

  const holidays = useSelector(holidaysSelector);

  const setNewSelectedDay = day => {
    setSelectedDay(day);
    setCurrentDate(day);
  };

  function getDaysAndWeeks() {
    const daysCopy = days.slice();
    const weeks = days.length / 7;
    const groupedDaysAndWeeks = [];
    for (let i = weeks; i > 0; i--) {
      groupedDaysAndWeeks[i] = [];
    }
    for (const value in groupedDaysAndWeeks) {
      const daysSlice = daysCopy.slice(0, 7);
      if (groupedDaysAndWeeks.hasOwnProperty(value)) {
        groupedDaysAndWeeks[value].push(...daysSlice);
      }
      daysCopy.splice(0, 7);
    }
    return groupedDaysAndWeeks;
  }

  function drawCalendar() {
    const calendar = getDaysAndWeeks();
    return calendar.map((week, index) => {
      return (
        <tr key={index}>
          {week.map((day, index) => {
            const holiday = (holidays || []).find(holiday =>
              moment(holiday.date).isSame(day, "day")
            );
            return (
              <td
                key={index}
                className={selectedDay.isSame(day, "day") ? "today day" : "day"}
                style={{
                  background: today.isSame(day, "day")
                    ? "#ffb200"
                    : holiday && holiday.isHoliday
                    ? "#ffe0e0"
                    : ""
                }}
                onClick={clickable ? () => setNewSelectedDay(day) : undefined}
              >
                {day.date()}
              </td>
            );
          })}
        </tr>
      );
    });
  }
  return (
    <div className={`datepicker ${className}`}>
      <div className="datepicker-days">
        <table className="table-condensed">
          <thead>
            <tr>
              <th
                colSpan="7"
                className="datepicker-title"
                style={{ display: "none" }}
              />
            </tr>
            <tr>
              <th onClick={handleOnPrevMonth} className="prev">
                «
              </th>
              <th colSpan="5" className="datepicker-switch">
                {months[currentDate.month()]} {currentDate.year()}
              </th>
              <th onClick={handleOnNextMonth} className="next">
                »
              </th>
            </tr>
            <tr>
              <th className="dow">
                <Trans>Пн</Trans>
              </th>
              <th className="dow">
                <Trans>Вт</Trans>
              </th>
              <th className="dow">
                <Trans>Ср</Trans>
              </th>
              <th className="dow">
                <Trans>Чт</Trans>
              </th>
              <th className="dow">
                <Trans>Пт</Trans>
              </th>
              <th className="dow">
                <Trans>Сб</Trans>
              </th>
              <th className="dow">
                <Trans>Вс</Trans>
              </th>
            </tr>
          </thead>
          <tbody>{drawCalendar()}</tbody>
          <tfoot>
            <tr>
              <th colSpan="7" className="today" style={{ display: "none" }}>
                <Trans>Сегодня</Trans>
              </th>
            </tr>
            <tr>
              <th colSpan="7" className="clear" style={{ display: "none" }}>
                <Trans>Очистить</Trans>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
