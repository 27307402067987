import React from "react";
import { SortableUsers } from "../../components/settings/users/SortableUsers";

export default function Employees() {
  return (
    <>
      <div className="row settings__layout">
        <div className="col-md-12">
          <SortableUsers />
        </div>
      </div>
    </>
  );
}
