import React from "react";
import { Trans } from "react-i18next";

export default function CalendarWeeksHeader() {
  return (
    <header>
      <div className="row d-none d-sm-flex p-1 bg-dark text-white">
        <h5 className="col-sm p-1 text-center">
          <Trans>Понедельник</Trans>
        </h5>
        <h5 className="col-sm p-1 text-center">
          <Trans>Вторник</Trans>
        </h5>
        <h5 className="col-sm p-1 text-center">
          <Trans>Среда</Trans>
        </h5>
        <h5 className="col-sm p-1 text-center">
          <Trans>Четверг</Trans>
        </h5>
        <h5 className="col-sm p-1 text-center">
          <Trans>Пятница</Trans>
        </h5>
        <h5 className="col-sm p-1 text-center">
          <Trans>Суббота</Trans>
        </h5>
        <h5 className="col-sm p-1 text-center">
          <Trans>Воскресенье</Trans>
        </h5>
      </div>
    </header>
  );
}
