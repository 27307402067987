import { useMemo } from "react";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export const useDaysInMonth = currentDate => {
  return useMemo(() => {
    const startWeek = currentDate
      .clone()
      .startOf("month")
      .startOf("isoWeek");
    const endWeek = currentDate
      .clone()
      .endOf("month")
      .endOf("isoWeek");
    return Array.from(moment.range(startWeek, endWeek).by("days"));
  }, [currentDate.format()]);
};
