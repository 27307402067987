import React from "react";

export const SidebarPagination = ({ page, pageCount, changePage, display }) => {
  if (!display) {
    return <></>;
  }

  return (
    <div className="sidebar-pagination__container">
      <button
        className={`btn btn btn-clean btn-icon ${page !== 1 &&
          "btn-hover-primary"} sidebar-pagination__item`}
        disabled={page === 1}
        onClick={() => changePage(page - 1)}
      >
        <i className="fa fa-angle-left" />
      </button>
      <button
        className={`btn btn-clean btn-icon ${page !== pageCount &&
          "btn-hover-primary"} sidebar-pagination__item`}
        disabled={page === pageCount}
        onClick={() => changePage(page + 1)}
      >
        <i className="fa fa-angle-right" />
      </button>
    </div>
  );
};
