import axios from "axios";
import { PROMOCODE_URL } from "../constants/api";

export const checkPromocode = code => {
  return axios.get(`${PROMOCODE_URL}/by-code/${code}`);
};

export const activatePromocodeByCode = code => {
  return axios.post(`${PROMOCODE_URL}/activate`, { code });
};

export const updatePromocodeById = (id, values) => {
  return axios.post(`${PROMOCODE_URL}/${id}`, values);
};

export const createPromocode = values => {
  return axios.post(`${PROMOCODE_URL}/create`, values);
};
