import React, { useMemo } from "react";
import { CalendarSidebarItem } from "../components/calendar/sidebar/CalendarSidebarItem";
import { Link } from "react-router-dom";

export const useSidebarUsersList = ({ users, userId }) => {
  return useMemo(
    () =>
      users.map((user, index) => {
        return (
          <Link
            key={user.id}
            to={`/calendar/${user.id}`}
            style={{ cursor: "pointer" }}
            className={
              user.id === userId
                ? "kt-notification-v2__item active"
                : "kt-notification-v2__item"
            }
          >
            <CalendarSidebarItem
              index={index}
              title={user.name}
              subtitle={user.positionName}
            />
          </Link>
        );
      }),
    [users, userId]
  );
};
