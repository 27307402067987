import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";
import { add, remove, restore, update } from "../redux/thunks/user.thunk";
import { getUserDesktopAuthToken } from "../crud/settings.crud";

export const useUserCRUD = ({
  refresh,
  setEditModalState,
  setAddModalState,
  setSelectedUser,
  setAppToken
}) => {
  const dispatch = useDispatch();
  const createHandleUpdate = useCallback(
    user => async values => {
      const result = await dispatch(update(user.id, values));

      if (result) {
        await refresh();
        setEditModalState(false);
      }

      return result;
    },
    []
  );
  const createHandleRemove = useCallback(
    user => async () => {
      const result = await dispatch(remove(user));

      if (result) {
        await refresh();
        setEditModalState(false);
      }

      return result;
    },
    []
  );
  const createHandleRestore = useCallback(
    user => async () => {
      const result = await dispatch(restore(user));

      if (result) {
        await refresh();
        setEditModalState(false);
      }

      return result;
    },
    []
  );
  const handleAdd = useCallback(async values => {
    const result = await dispatch(add(values));

    if (result) {
      await refresh();
      setAddModalState(false);
    }

    return result;
  }, []);
  const createOnClickHandler = useCallback(
    user => async () => {
      const { data } = await getUserDesktopAuthToken(user.id);
      setSelectedUser(user);
      setAppToken(data.token);
      setEditModalState(true);
    },
    []
  );

  return useMemo(
    () => ({
      createHandleRemove,
      createHandleRestore,
      createHandleUpdate,
      createOnClickHandler,
      handleAdd
    }),
    [
      createHandleRemove,
      createHandleUpdate,
      createOnClickHandler,
      createHandleRestore,
      handleAdd
    ]
  );
};
