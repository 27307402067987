import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const CalendarItemsSelect = ({
  prop = "name",
  className,
  label,
  placeholder,
  items,
  currentId,
  onChange,
  ...props
}) => {
  const [currentItem, setCurrentItem] = useState([{}, ""]);
  const options = useMemo(() => items.map(item => [item, item[prop]]), [items]);

  const handleChange = useCallback(
    (e, value) => {
      if (Array.isArray(value)) {
        onChange(value[0].id);
      }
    },
    [onChange]
  );

  const getOptionLabel = useCallback(([, value]) => value, []);
  const renderInput = useCallback(
    params => (
      <TextField
        {...params}
        fullWidth
        size="small"
        margin="none"
        label={label}
        placeholder={placeholder}
        className="form-control"
        variant="outlined"
      />
    ),
    [label, placeholder]
  );

  useEffect(() => {
    const item = items.find(item => item.id === currentId);
    if (item) {
      setCurrentItem([item, item[prop]]);
    }
  }, [currentId, items]);

  return (
    <div className={`kt-portlet__head-label ${className}`}>
      <Autocomplete
        {...props}
        getOptionLabel={getOptionLabel}
        options={options}
        value={currentItem}
        onChange={handleChange}
        renderInput={renderInput}
      />
    </div>
  );
};
