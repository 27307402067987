import { createSlice } from "@reduxjs/toolkit";
import { useEffect, useReducer } from "react";
import moment from "moment";

const maxDate = (arr, cb) => Math.max(...arr.map(cb));
const minDate = (arr, cb) => Math.min(...arr.map(cb));

const getPlan = spans => spans.find(span => span.type === "plan");

const getActual = spans => {
  const withoutPlan = spans.filter(span => span.type !== "plan");

  if (!withoutPlan.length) {
    return 0;
  }

  const min = minDate(withoutPlan, span => moment(span.from).toDate());
  const max = maxDate(withoutPlan, span => moment(span.to).toDate());

  return moment(max).diff(min, "hours");
};

const getStart = sessions => {
  if (!sessions.length) {
    return undefined;
  }

  return minDate(sessions, sess => moment(sess.start).toDate());
};

const getEnd = sessions => {
  const closedSessions = sessions.filter(sess => sess.end);

  if (!closedSessions.length) {
    return undefined;
  }

  return maxDate(closedSessions, sess => moment(sess.end).toDate());
};

export const useWorkSummary = (appSessions, timeSpans) => {
  const initialState = {
    plan: undefined,
    actual: undefined,
    start: undefined,
    end: undefined,
    empty: undefined
  };

  const slice = createSlice({
    name: "work-day-summary",
    initialState,
    reducers: {
      setData: (state, action) => {
        return {
          ...state,
          ...action.payload
        };
      },
      resetData: () => {
        return initialState;
      }
    }
  });

  const { setData, resetData } = slice.actions;
  const [state, dispatch] = useReducer(slice.reducer, initialState);

  useEffect(() => {
    if (
      !appSessions.length ||
      !timeSpans.filter(span => span.type === "fill-span").length
    ) {
      dispatch(resetData());
    }

    const emptySpans = timeSpans.filter(span => span.type === "empty");
    const empty = emptySpans.length;
    const plan = getPlan(timeSpans);
    const start = getStart(appSessions);
    const end = getEnd(appSessions);
    const actual = getActual(timeSpans);
    dispatch(setData({ plan, start, end, empty, actual }));
  }, [JSON.stringify(appSessions), JSON.stringify(timeSpans)]);

  return state;
};
