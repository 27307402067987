import React, { useCallback } from "react";
import {
  Snackbar,
  IconButton,
  SnackbarContent,
  colors,
  makeStyles
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { closeSnack, resetSnack } from "../redux/features/snackbarFeatureSlice";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: colors.green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: colors.amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    fontSize: 18,
    display: "flex",
    alignItems: "center"
  }
}));

function SnackbarContentWrapper(props) {
  const classes = useStyles();
  const { className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}

export default function CustomSnackbar() {
  const { open, type, message, transition } = useSelector(
    state => state.snackbar
  );
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(closeSnack());
  });
  const onExited = useCallback(() => {
    dispatch(resetSnack());
  });

  return (
    <Snackbar
      open={open}
      onClose={onClose}
      onExited={onExited}
      autoHideDuration={3000}
      TransitionComponent={transition}
      ContentProps={{
        "aria-describedby": "message-id"
      }}
    >
      <SnackbarContentWrapper
        onClose={onClose}
        variant={type}
        message={message}
      />
    </Snackbar>
  );
}
