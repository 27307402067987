import moment from "moment";
import { createSlice } from "@reduxjs/toolkit";
import React, { useEffect, useReducer } from "react";
import axios from "axios";
import { useDispatch, batch, useSelector } from "react-redux";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { Pagination as BPagination } from "react-bootstrap";
import { setConfig } from "../../redux/features/paginationConfigFeatureSlice";

export const userStatusFormatter = cell => {
  switch (cell) {
    case "await-verification":
      return "Email не подтверждён";
    case "first-login":
      return "ЛК не настроен";
    case "active":
      return "Активный";

    default:
      return "(не известен)";
  }
};

export const invoiceStatusFormatter = cell => {
  switch (cell) {
    case "created":
      return "Создан";
    case "processed":
      return "Обработан";
    case "rejected":
      return "Отклонён";

    default:
      return "(не известен)";
  }
};

export const dateFormatter = cell => {
  if (!cell) {
    return "(не указано)";
  }

  return moment(cell).format("YYYY-MM-DD HH:mm");
};

export const emptyPlaceholderFormatter = cell => (cell ? cell : "(не указано)");

export const usePaginationData = (
  url,
  {
    page = 1,
    pageSize = 10,
    startDate = moment().startOf("year"),
    endDate = moment()
  } = {}
) => {
  const globalDispatch = useDispatch();
  const initialConfig = useSelector(state => state.paginationConfig[url] || {});
  const initialState = {
    page,
    pageSize,
    data: [],
    totalSize: 0,
    pageCount: 0,
    loading: true,
    startDate,
    endDate
  };
  const {
    actions: {
      onPageChange,
      onRangeChange,
      onRangeCancel,
      setPageSize,
      setLoading
    },
    reducer
  } = createSlice({
    name: "pagination",
    initialState,
    reducers: {
      setPageSize: (state, action) => {
        state.pageSize = action.payload;
      },
      setLoading: (state, action) => {
        state.loading = action.payload;
      },
      onRangeChange: (state, action) => {
        const { startDate, endDate } = action.payload;
        state.startDate = startDate;
        state.endDate = endDate;
      },
      onRangeCancel: state => {
        state.startDate = undefined;
        state.endDate = undefined;
      },
      onPageChange: (state, action) => {
        const {
          page,
          pageSize,
          data,
          totalSize,
          sortOrder,
          sortField
        } = action.payload;
        state.data = data;
        state.page = page;
        state.pageSize = pageSize;
        state.totalSize = totalSize;
        state.sortOrder = sortOrder;
        state.sortField = sortField;
        state.pageCount = Math.ceil(totalSize / pageSize);
      }
    }
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    fetchRemote(
      state.page,
      state.pageSize,
      initialConfig.sortOrder,
      initialConfig.sortField,
      undefined,
      state.startDate,
      state.endDate
    );
  }, []);

  const fetchRemote = (
    page,
    pageSize,
    sortOrder,
    sortField,
    searchText,
    startDate,
    endDate
  ) => {
    batch(async () => {
      dispatch(setLoading(true));
      try {
        const { data } = await axios.post(url, {
          offset: page - 1,
          limit: pageSize,
          sortOrder,
          sortField,
          searchText,
          startDate,
          endDate
        });
        dispatch(
          onPageChange({
            page,
            pageSize,
            data: data.items,
            totalSize: data.totalCount,
            sortOrder,
            sortField
          })
        );
      } catch (e) {
        const { header, message } = extractErrorInfo(e);
        globalDispatch(
          openSnackbar(Fade, `${header}. ${message || ""}`, "error")
        );
      }
      dispatch(setLoading(false));
    });
  };

  const onTableChange = async (type, newState) => {
    const {
      page,
      sizePerPage: pageSize,
      sortOrder,
      sortField,
      filters,
      searchText
    } = newState;

    globalDispatch(
      setConfig({
        key: url,
        config: { page, sizePerPage: pageSize, sortOrder, sortField, filters }
      })
    );

    await fetchRemote(
      page,
      pageSize,
      sortOrder,
      sortField,
      searchText,
      state.startDate,
      state.endDate
    );
  };

  const changePage = async nextPage => {
    await fetchRemote(
      nextPage,
      state.pageSize,
      state.sortOrder,
      state.sortField,
      undefined,
      state.startDate,
      state.endDate
    );
  };

  const refresh = async () => {
    await fetchRemote(
      state.page,
      state.pageSize,
      state.sortOrder,
      state.sortField,
      undefined,
      state.startDate,
      state.endDate
    );
  };

  const PaginationComponent = () => {
    const { page, pageCount } = state;
    const items = [];

    for (
      let currentProcessingPage = 1;
      currentProcessingPage <= 10 && currentProcessingPage <= pageCount;
      currentProcessingPage++
    ) {
      items.push(
        <BPagination.Item
          key={currentProcessingPage}
          active={currentProcessingPage === page}
          onClick={async () => await changePage(currentProcessingPage)}
        >
          {currentProcessingPage}
        </BPagination.Item>
      );
    }

    return (
      <BPagination>
        <BPagination.First
          disabled={page === 1}
          onClick={async () => await changePage(1)}
        />
        <BPagination.Prev
          disabled={page === 1}
          onClick={async () => await changePage(page - 1)}
        />

        {items}

        <BPagination.Next
          disabled={page === pageCount}
          onClick={async () => await changePage(page + 1)}
        />
        <BPagination.Last
          disabled={page === pageCount}
          onClick={async () => await changePage(pageCount)}
        />
      </BPagination>
    );
  };

  return {
    state,
    onTableChange,
    changePage,
    refresh,
    onRangeCancel: async (event, picker) => {
      picker.element.val("");
      dispatch(onRangeCancel());
      await fetchRemote(
        state.page,
        state.pageSize,
        state.sortOrder,
        state.sortField,
        state.searchText,
        undefined,
        undefined
      );
    },
    onRangeChange: async newRange => {
      dispatch(onRangeChange(newRange));
      await fetchRemote(
        state.page,
        state.pageSize,
        state.sortOrder,
        state.sortField,
        state.searchText,
        newRange.startDate,
        newRange.endDate
      );
    },
    Pagination: PaginationComponent
  };
};
