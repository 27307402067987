import React, { useMemo } from "react";
import { Draggable } from "react-beautiful-dnd";
import { colors } from "../../../constants/colors";
import styled from "styled-components";
import { Trans } from "react-i18next";

const UserItemContainer = styled.div`
  &&&& {
    .kt-notification-v2__itek-wrapper {
      flex-basis: 33%;
    }
    .kt-notification-v2__itek-wrapper:last-child {
      text-align: end;
    }
  }
`;

export const UserItem = ({ user, onClick, index, filtered, isDraggable }) => {
  const color = useMemo(() => colors[index % colors.length], [index]);
  const found = filtered.find(f => f.id === user.id);

  if (!found) {
    return null;
  }

  return (
    <Draggable
      draggableId={user.id}
      index={index}
      isDragDisabled={!isDraggable}
    >
      {(provided, snapshot) => (
        <UserItemContainer
          ref={provided.innerRef}
          className="kt-notification-v2__item transition-unset"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div className="kt-notification-v2__item-icon">
            <span
              className={`kt-badge kt-badge--unified-${color} kt-badge--lg kt-badge--bold`}
            >
              {user.name[0].toUpperCase()}
            </span>
          </div>
          <div className="kt-notification-v2__itek-wrapper">
            <div className="kt-notification-v2__item-title">{user.name}</div>
            <div className="kt-notification-v2__item-desc">
              {user.positionName}
            </div>
          </div>
          <div className="kt-notification-v2__itek-wrapper text-center">
            <div className="kt-notification-v2__item-title">
              {user.status === "active" && (
                <span className="btn btn-label-brand btn-sm btn-bold btn-upper">
                  <Trans>Активный</Trans>
                </span>
              )}
              {user.status === "deleted" && (
                <span className="btn btn-label-danger btn-sm btn-bold btn-upper">
                  <Trans>Уволен</Trans>
                </span>
              )}
            </div>
          </div>
          <div className="kt-notification-v2__itek-wrapper">
            <div className="kt-notification-v2__item-title">
              <button className="btn btn-primary btn-sm" onClick={onClick}>
                Редактировать
              </button>
            </div>
          </div>
        </UserItemContainer>
      )}
    </Draggable>
  );
};
