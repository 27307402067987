import axios from "axios";
import { COMPANY_URL, USER_URL } from "../constants/api";

export async function createUser(
  email,
  name,
  password,
  positionName,
  role,
  timeZone
) {
  return await axios.post(`${USER_URL}/create`, {
    email,
    name,
    password,
    positionName,
    role,
    timeZone
  });
}

export async function updateCompanyById(
  id,
  {
    name,
    logo,
    address,
    site,
    description,
    inn,
    email,
    timeZone,
    workStartsAt,
    workEndsAt
  }
) {
  return await axios.post(`${COMPANY_URL}/${id}`, {
    name,
    logo,
    address,
    site,
    description,
    inn,
    email,
    timeZone,
    workStartsAt,
    workEndsAt
  });
}

export const getCompanyByUserId = () => {
  return axios.get(`${COMPANY_URL}/current`);
};

export async function getUserDesktopAuthToken(userId) {
  return await axios.get(`${USER_URL}/app-token`, {
    params: { userId }
  });
}

export async function updateUserById(
  userId,
  { name, positionName, email, password, timeZone, role, order }
) {
  return await axios.post(`${USER_URL}/${userId}`, {
    name,
    email,
    positionName,
    timeZone,
    password,
    role,
    order
  });
}

export async function deleteUserById(userId) {
  return await axios.delete(`${USER_URL}/${userId}`);
}

export async function restoreUserById(userId) {
  return await axios.get(`${USER_URL}/${userId}/restore`);
}

export const disableTutorialByUserId = async id => {
  return axios.get(`${USER_URL}/disable-tutorial/${id}`);
};
