import React, { useState, useEffect } from "react";
import "../../../_metronic/_assets/sass/pages/login/login-6.scss";
import { Spinner } from "react-bootstrap";
import { Container, Grid } from "@material-ui/core";
import { useLocation, useHistory, Link } from "react-router-dom";
import qs from "query-string";
import { Alert } from "react-bootstrap";
import { verifyEmail } from "../../crud/auth.crud";
import { useDispatch, useSelector } from "react-redux";
import AuthLogo from "../../components/auth/AuthLogo";
import AuthHeader from "../../components/auth/AuthHeader";
import { extractErrorInfo } from "../../utils/extractError";
import { Trans, useTranslation } from "react-i18next";
import { AuthLayout, IntroduceMessage } from "../../components/auth/Container";
import { onAuthentication } from "../../redux/features/authFeatureSlice";
import FormLogin from "../../components/forms/FormLogin";

export default function ValidateEmailForm1() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token;
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function validateToken(token) {
      try {
        await verifyEmail(token);
        setLoading(false);
        setType("success");
        setMessage(
          t(
            "Email подтвержден. Вы будете перенаправлены на страницу авторизации"
          )
        );
      } catch (e) {
        setType("danger");
        const { header, message } = extractErrorInfo(e);
        setMessage(`${header}. ${message || ""}`);
      }
      setTimeout(() => {
        history.push("/auth/login");
      }, 3000);
    })(token);
  }, [dispatch, history, token]);

  return (
    <Container
      maxWidth="xl"
      style={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
      }}
    >
      <Grid direction="column" alignItems="center" justify="center" container>
        <AuthLogo />
        <AuthHeader title={t("Подтверждение email")} />
      </Grid>
    </Container>
  );
}

const ValidateEmailForm = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token;
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function validateToken(token) {
      try {
        await verifyEmail(token);
        setLoading(false);
        setType("success");
        setMessage(
          t(
            "Email подтвержден. Вы будете перенаправлены на страницу авторизации"
          )
        );
      } catch (e) {
        setType("danger");
        const { header, message } = extractErrorInfo(e);
        setMessage(`${header}. ${message || ""}`);
      }
      setTimeout(() => {
        history.push("/auth/login");
      }, 3000);
    })(token);
  }, [dispatch, history, token]);

  return (
    <>
      <a href="#" className="text-center pt-2">
        <img
          src="/media/auth/logo.png"
          style={{ maxHeight: "90px", maxWidth: "90px" }}
          alt=""
        />
      </a>
      <div className="d-flex flex-column-fluid flex-column flex-center">
        <div className="login-form login-signin py-11">
          <div className="text-center pb-8">
            <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
              Workapp
            </h2>
          </div>

          {isLoading && !message.length && (
            <Spinner animation="grow" variant="primary" />
          )}
          {message.length && <Alert variant={type}>{message}</Alert>}
        </div>
      </div>
      <center>
        <a href="https://linkodium.com" target="_blank">
          Linkodium
        </a>{" "}
        (2020)
      </center>
    </>
  );
};

export const NewValidateEmail = () => {
  return (
    <AuthLayout left={<ValidateEmailForm />} right={<IntroduceMessage />} />
  );
};
