import { Button, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  disableTutorial
} from "../../redux/features/tutorialFeatureSlice";

export const ModalTutorial = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const lang = i18n.language;
  const { open } = useSelector(state => state.tutorial);
  const { status } = useSelector(state =>
    state.auth.user ? state.auth.user : false
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (status === "active") {
      setTimeout(() => {
        setIsOpen(open);
      }, 1000);
    }
  }, [open, status]);

  return (
    <Modal centered className="modal fade" show={isOpen} backdrop="static">
      <Modal.Header>
        <Modal.Title>
          <Trans>Впервые с WorkApp?</Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Trans i18nKey="tutorial-modal">
          <p>
            На самом деле работать с WorkApp очень просто, но мы всё же
            рекомендуем перед началом работы изучить наши короткие обучающие
            видео и не большую инструкцию. Это поможет выжать из WorkApp
            максимальную эффективность!
          </p>
          Смотреть видео:{" "}
          <a href={`https://workapp.ai/${lang}/how-to-start/`} target="_blank">
            https://workapp.ai/{{ lang }}/how-to-start/
          </a>
        </Trans>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => dispatch(closeModal())}>
          <Trans>Напомнить позже</Trans>
        </Button>
        <Button variant="warning" onClick={() => dispatch(disableTutorial())}>
          <Trans>Больше не показывать</Trans>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
