import i18n from "../i18n/i18n";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

export const monthsMapping = {
  0: "01",
  1: "02",
  2: "03",
  3: "04",
  4: "05",
  5: "06",
  6: "07",
  7: "08",
  8: "09",
  9: "10",
  10: "11",
  11: "12"
};

export const weekDays = {
  0: i18n.t("Воскресенье"),
  1: i18n.t("Понедельник"),
  2: i18n.t("Вторник"),
  3: i18n.t("Среда"),
  4: i18n.t("Четверг"),
  5: i18n.t("Пятница"),
  6: i18n.t("Суббота")
};

export const weekDaysShort = {
  0: i18n.t("Вс"),
  1: i18n.t("Пн"),
  2: i18n.t("Вт"),
  3: i18n.t("Ср"),
  4: i18n.t("Чт"),
  5: i18n.t("Пт"),
  6: i18n.t("Сб")
};

export const createWeekDaysShort = t => [
  t("Вс"),
  t("Пн"),
  t("Вт"),
  t("Ср"),
  t("Чт"),
  t("Пт"),
  t("Сб")
];

export const months = {
  0: i18n.t("Январь"),
  1: i18n.t("Февраль"),
  2: i18n.t("Март"),
  3: i18n.t("Апрель"),
  4: i18n.t("Май"),
  5: i18n.t("Июнь"),
  6: i18n.t("Июль"),
  7: i18n.t("Август"),
  8: i18n.t("Сентябрь"),
  9: i18n.t("Октябрь"),
  10: i18n.t("Ноябрь"),
  11: i18n.t("Декабрь")
};

export const createMonths = t => [
  t("Январь"),
  t("Февраль"),
  t("Март"),
  t("Апрель"),
  t("Май"),
  t("Июнь"),
  t("Июль"),
  t("Август"),
  t("Сентябрь"),
  t("Октябрь"),
  t("Ноябрь"),
  t("Декабрь")
];

export function toDateObject(dateString) {
  const date = new Date(dateString);
  return {
    day: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    hour: date.getHours(),
    minutes: date.getMinutes()
  };
}

export default function getDaysInMonth(currentDate) {
  const startWeek = currentDate
    .clone()
    .startOf("month")
    .startOf("isoWeek");
  const endWeek = currentDate
    .clone()
    .endOf("month")
    .endOf("isoWeek");
  return Array.from(moment.range(startWeek, endWeek).by("days"));
}
