import axios from "axios";
import {
  TASKS_URL,
  USER_URL,
  HOLIDAYS_URL,
  EXPORT_URL,
  STATISTICS_URL,
  PROJECT_URL
} from "../constants/api";
import { debounce } from "lodash";

export async function getTasksByUserId(userId, from, to) {
  return await axios.get(TASKS_URL, {
    params: { userId, from, to }
  });
}

export const getWorkStatsInTimeSpan = (from, to, userId) => {
  return axios.get(`${STATISTICS_URL}/work/by-span`, {
    params: { userId, from, to }
  });
};

export const deleteTaskById = id => {
  return axios.delete(`${TASKS_URL}/${id}`);
};

export const getTaskSpan = (userId, from, to) => {
  return axios.get(`${TASKS_URL}/span`, {
    params: { userId, from, to }
  });
};

export const getTaskHistory = taskId => {
  return axios.get(`${TASKS_URL}/${taskId}/history`);
};

export const getRecentProjects = additionalIds => {
  return axios.post(`${PROJECT_URL}/recent`, additionalIds || []);
};

export const debouncedFetchProjectsByQuery = debounce(
  searchQuery => {
    return axios.get(`${PROJECT_URL}/by-query`, {
      params: {
        searchQuery
      }
    });
  },
  500,
  {
    leading: true
  }
);

export async function createTaskByUserId({
  userId,
  projectIds,
  newProjects,
  description,
  startDate,
  endDate
}) {
  return await axios.post(`${TASKS_URL}/create`, {
    userId,
    projectIds,
    newProjects,
    description,
    startDate,
    endDate
  });
}

export async function updateTaskById({
  id,
  projectIds,
  newProjects,
  description
}) {
  return await axios.post(`${TASKS_URL}/${id}`, {
    projectIds,
    newProjects,
    description
  });
}

export async function getUsersByCompanyId(companyId) {
  const params = {};

  if (companyId) {
    params.companyId = companyId;
  }

  return await axios.get(USER_URL, {
    params
  });
}

export async function getHolidaysByMonth(month, projectId) {
  return await axios.get(HOLIDAYS_URL, {
    params: { month },
    headers: { "X-Project-Token": projectId }
  });
}

export async function exportView(userId, monthRange) {
  const response = await axios.post(
    `${EXPORT_URL}`,
    { userId, monthRange },
    {
      responseType: "blob"
    }
  );

  const url = window.URL.createObjectURL(
    new Blob([response.data], { type: "application/vnd.ms-excel" })
  );
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "calendar-export.xlsx");
  link.click();
  window.URL.revokeObjectURL(url);
}
