import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Trans } from "react-i18next";
import { usePaginationData } from "../tables/utils";
import { USER_URL } from "../../constants/api";
import { AddUser } from "../forms/AddUser";
import { add } from "../../redux/thunks/user.thunk";
import { useSidebarUsersList } from "../../hooks/useSidebarUsersList";
import { CalendarSidebar } from "../calendar/sidebar/CalendarSidebar";

const sidebarSelector = createSelector(
  state => state.settings,
  state => state.auth,
  ({ users }, { user }) => {
    return { users, user };
  }
);

export const TaskCalendarSidebar = ({
  userId,
  date,
  handleOnPrevMonth,
  handleOnNextMonth
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector(sidebarSelector);
  const [isVisibleAddUserModal, toggleAddUserModal] = useState(false);
  const paginationProps = usePaginationData(`${USER_URL}/paginated-list`, {
    page: 1,
    pageSize: 5
  });

  const usersList = useSidebarUsersList({
    users: paginationProps.state.data,
    userId
  });
  return (
    <>
      <CalendarSidebar
        date={date}
        paginationProps={paginationProps}
        handleOnPrevMonth={handleOnPrevMonth}
        handleOnNextMonth={handleOnNextMonth}
        handleButtonClick={toggleAddUserModal}
        buttonContent={
          <>
            <i className="fa fa-plus" />{" "}
            <Trans>Добавить нового сотрудника</Trans>
          </>
        }
      >
        {usersList}
      </CalendarSidebar>

      <AddUser
        handleAdd={values => dispatch(add(values))}
        closeModal={() => toggleAddUserModal(false)}
        open={isVisibleAddUserModal}
        currentUser={user}
      />
    </>
  );
};
