import React from "react";
import { toAbsoluteUrl } from "../../../_metronic";
import "../../../_metronic/_assets/sass/pages/error/error-6.scss";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutAndClearStorage } from "../../redux/features/authFeatureSlice";
import { useHistory } from "react-router";

export function ErrorPage6() {
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    dispatch(logoutAndClearStorage());
    history.replace("/");
  };
  return (
    <>
      <div className="kt-grid kt-grid--ver kt-grid--root">
        <div
          className="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v6"
          style={{
            backgroundImage: `url(${toAbsoluteUrl("/media//error/bg6.jpg")})`
          }}
        >
          <div className="kt-error_container">
            <div className="kt-error_subtitle kt-font-light">
              <h1>
                <Trans>Ууупс...</Trans>
              </h1>
            </div>

            <p className="kt-error_description kt-font-light">
              <Trans>Похоже что-то сломалось на нашей стороне.</Trans>
              <br />
              <Trans>
                Команда разработки извещена о проблеме и скоро она будет
                устранена.
              </Trans>
            </p>
            <button
              className="btn btn-glow btn-wide btn-secondary kt-font-light kt-font-hover-dark d-none d-sm-inline-block"
              onClick={logout}
            >
              <Trans>Войти заново</Trans>
            </button>
            <button
              className="btn btn-glow btn-wide btn-dark kt-font-light kt-font-hover-light d-inline-block d-sm-none"
              onClick={logout}
            >
              <Trans>Войти заново</Trans>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
