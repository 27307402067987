/* eslint-disable no-use-before-define */
import React, { memo, useCallback, useEffect } from "react";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import { usePrevious } from "../../hooks/usePrevious";

const filter = createFilterOptions();

const useAutocompleteHandlers = ({ handleNewValue, setHighlight }) => {
  const handleSelectChange = useCallback((event, newValue) => {
    if (typeof newValue === "string") {
      handleNewValue(event, newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      handleNewValue(event, newValue);
    } else {
      handleNewValue(event, newValue);
    }
  }, []);

  const handleFilterOptions = useCallback((options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (
      params.inputValue !== "" &&
      !filtered.some(
        ({ text }) => text.toLowerCase() === params.inputValue.toLowerCase()
      )
    ) {
      filtered.push({
        key: params.inputValue,
        value: params.inputValue,
        text: `Добавить проект: "${params.inputValue}"`,
        new: true
      });
    }

    const [highlight] = filtered;
    setHighlight(highlight);

    return filtered;
  }, []);

  const handleOptionLabel = useCallback(option => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }

    if (option.new) {
      return option.value;
    }

    return option.text;
  }, []);

  const handleSelectedOptions = useCallback((option, projectOpt) => {
    return option.value === projectOpt.value;
  }, []);

  return {
    handleSelectChange,
    handleFilterOptions,
    handleOptionLabel,
    handleSelectedOptions
  };
};

const Component = ({
  searchProps,
  handleChange,
  clearText,
  closeText,
  noOptionsText,
  readonly,
  ...inputProps
}) => {
  const {
    query,
    searchState,
    setHighlight,
    onSearchChange,
    onChange,
    onBlur,
    onKeyDown
  } = searchProps;
  const {
    handleSelectChange,
    handleFilterOptions,
    handleOptionLabel,
    handleSelectedOptions
  } = useAutocompleteHandlers({ handleNewValue: onChange, setHighlight });
  const renderInput = useCallback(
    params => {
      const InputProps = !readonly
        ? { ...(params.InputProps || {}), ...(inputProps.InputProps || {}) }
        : { readOnly: readonly };
      return (
        <TextField
          {...params}
          {...inputProps}
          InputProps={InputProps}
          variant="outlined"
          fullWidth
        />
      );
    },
    [inputProps]
  );

  const previousProjectIds = usePrevious(searchState.projectIds);
  const previousProjectNames = usePrevious(searchState.newProjects);

  useEffect(() => {
    if (
      !Array.isArray(previousProjectNames) ||
      !Array.isArray(previousProjectIds)
    ) {
      return;
    }

    if (previousProjectNames.length !== searchState.newProjects.length) {
      handleChange({
        target: { name: "newProjects", value: searchState.newProjects }
      });
    }

    if (previousProjectIds.length !== searchState.projectIds.length) {
      handleChange({
        target: { name: "projectIds", value: searchState.projectIds }
      });
    }
  }, [searchState.newProjects, searchState.projectIds]);

  return (
    <Autocomplete
      multiple
      selectOnFocus
      handleHomeEndKeys
      autoHighlight
      filterSelectedOptions
      disableCloseOnSelect
      clearOnBlur={!readonly}
      value={searchState.selectedProjects}
      inputValue={query}
      onInputChange={onSearchChange}
      onChange={handleSelectChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      clearText={clearText}
      closeText={closeText}
      noOptionsText={noOptionsText}
      loading={searchState.loading}
      ChipProps={{ size: "small" }}
      filterOptions={handleFilterOptions}
      options={searchState.options}
      getOptionLabel={handleOptionLabel}
      getOptionSelected={handleSelectedOptions}
      renderOption={option => option.text}
      renderInput={renderInput}
    />
  );
};

export const ProjectSelectInput = memo(Component);
