import CalendarMonthsSwitcher from "../calendar/header/CalendarMonthsSwitcher";
import { TaskScoringInfo } from "./TaskScoringInfo";
import { TaskProgressStatistic } from "./TaskProgressStatistic";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { TaskViewSwitcher } from "./TaskViewSwitcher";
import { TaskCalendarHeaderButtons } from "./TaskCalendarHeaderButtons";
import { CalendarItemsSelect } from "../calendar/CalendarItemsSelect";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const userSelector = createSelector(
  state => state.settings.users || [],
  users => users
);

export const TaskCalendarHeader = ({
  date,
  userId,
  handleOnNextMonth,
  handleOnPrevMonth
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const users = useSelector(userSelector);
  const handleChange = useCallback(id => history.push(`/calendar/${id}`), []);

  return (
    <div className="row">
      <div className="col-md-2">
        <CalendarItemsSelect
          items={users}
          currentId={userId}
          onChange={handleChange}
          label={t("Пользователь")}
          placeholder={t("Начните вводить имя пользователя")}
        />
      </div>

      <div className="col-md-2">
        <TaskViewSwitcher />
      </div>

      <div className="col-md-5" style={{ textAlign: "center" }}>
        <CalendarMonthsSwitcher
          date={date}
          handleOnNextMonth={handleOnNextMonth}
          handleOnPrevMonth={handleOnPrevMonth}
        />
      </div>

      <div className="col-md-3">
        <TaskCalendarHeaderButtons userId={userId} date={date} />
      </div>

      <div className="col-12">
        <TaskScoringInfo date={date} />
      </div>

      <div className="col-12">
        <TaskProgressStatistic date={date} />
      </div>
    </div>
  );
};
