import React from "react";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import styled from "styled-components";

const RegistrationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Неверный email")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  password: Yup.string()
    .required("Обязательное поле")
    .min(6, "Пароль должен содержать не менее 6 символов")
    .max(255, "Максимальная величина пароля 255 символов"),
  repeatedPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Пароли не совпадают")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  name: Yup.string()
    .max(255, "Максимальная величина пароля 255 символов")
    .required("Обязательное поле")
});

const Button = styled.button`
  &&& {
    padding: 13px 26px !important;
  }
`;

export default function FormRegistration({
  loading,
  enableLoading,
  onSubmitRegistration,
  loadingButtonStyle
}) {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        password: "",
        repeatedPassword: ""
      }}
      validationSchema={RegistrationSchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        enableLoading();
        onSubmitRegistration(values, setSubmitting, setStatus);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate={true}
          autoComplete="off"
          className="kt-form"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <TextField
              type="name"
              label={t("Имя")}
              margin="normal"
              className="kt-width-full"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              helperText={touched.name && t(errors.name)}
              error={Boolean(touched.name && errors.name)}
            />
          </div>

          <div className="form-group">
            <TextField
              type="email"
              label={t("Email")}
              margin="normal"
              className="kt-width-full"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && t(errors.email)}
              error={Boolean(touched.email && errors.email)}
            />
          </div>

          <div className="form-group">
            <TextField
              type="password"
              margin="normal"
              label={t("Пароль")}
              className="kt-width-full"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && t(errors.password)}
              error={Boolean(touched.password && errors.password)}
            />
          </div>

          <div className="form-group">
            <TextField
              type="password"
              margin="normal"
              label={t("Подтверждение пароля")}
              className="kt-width-full"
              name="repeatedPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.repeatedPassword}
              helperText={
                touched.repeatedPassword && t(errors.repeatedPassword)
              }
              error={Boolean(
                touched.repeatedPassword && errors.repeatedPassword
              )}
            />
          </div>
          <div className="text-center pt-2">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              <Trans>Зарегистрироваться</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
