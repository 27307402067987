let url;
const { REACT_APP_ENV, NODE_ENV } = process.env;
const env = REACT_APP_ENV || NODE_ENV;

switch (env) {
  case "development":
    url = "http://backend.localhost";
    break;
  case "staging":
    url = "https://staging.workapp.ai";
    break;
  case "production":
    url = "https://private.workapp.ai";
    break;
  default:
    url = "http://backend.localhost";
    break;
}

export const URL = url;
export const API_URL = `${url}/api/v1`;
export const LOGIN_URL = `${API_URL}/auth/login`;
export const REGISTER_URL = `${API_URL}/auth/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/request-password-reset`;
export const IMPERSONATE_URL = `${API_URL}/auth/impersonate`;
export const VERIFY_EMAIL_URL = `${API_URL}/auth/verify`;
export const REFRESH_URL = `${API_URL}/auth/refresh`;
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset-password`;
export const COMPANY_BOOTSTRAP = `${API_URL}/company/bootstrap`;
export const COMPANY_URL = `${API_URL}/company`;
export const TASKS_URL = `${API_URL}/task`;
export const PROJECT_URL = `${API_URL}/project`;
export const HOLIDAYS_URL = `${API_URL}/day`;
export const USER_URL = `${API_URL}/user`;
export const EXPORT_URL = `${API_URL}/export`;
export const PAYMENT_URL = `${API_URL}/payment`;
export const SUBSCRIPTION_URL = `${API_URL}/subscription`;
export const PROMOCODE_URL = `${API_URL}/promocode`;
export const STATISTICS_URL = `${API_URL}/statistics`;
