import React, { useEffect } from "react";
import { logout } from "../../redux/features/authFeatureSlice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

export default function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(logout());
    history.push("/auth/login");
  });

  return <LayoutSplashScreen />;
}
