import { weekDays } from "../../../utils/getDaysInMonth";
import React from "react";

const createCssClasses = (isHoliday, isCurrentMonth, isLoading) => {
  const classNames = [
    "day",
    "col-sm",
    "p-2",
    "border",
    "border-left-0",
    "border-top-0",
    "text-truncate"
  ];

  if (isLoading) {
    classNames.push("day-loading");
  }

  if (isHoliday) {
    classNames.push("weekend");
  }

  if (!isCurrentMonth) {
    classNames.push("bg-light", "text-muted");
  }

  return "" + classNames.join(" ");
};

export const CalendarDayContainer = ({
  date,
  isLoading,
  isHoliday,
  calendarDay,
  classes,
  children
}) => {
  const day = calendarDay.date();
  const dayInWeek = calendarDay.day();
  const dayWithZero = day.toString().length === 1 ? `0${day}` : day;
  const classNames = createCssClasses(
    dayInWeek === 0 || dayInWeek === 6 || isHoliday,
    calendarDay.isSame(date, "month"),
    isLoading
  );

  return (
    <>
      <div className={`${classNames} ${classes}`}>
        <h5 className="row align-items-center">
          <span className="date col-1">{dayWithZero}</span>
          <small className="col d-sm-none text-center text-muted">
            {weekDays[dayInWeek]}
          </small>
          <span className="col-1" />
        </h5>
        {children}
      </div>
      {dayInWeek === 0 && <div className="w-100" />}
    </>
  );
};
