import React, { useState } from "react";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Неверный email")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  password: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле")
});

const Button = styled.button`
  &&& {
    padding: 13px 26px !important;
  }
`;

export default function FormLogin({ onSubmitLogin }) {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setStatus }) => {
        await onSubmitLogin(values, checked, setStatus);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate={true}
          autoComplete="off"
          className="kt-form"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <TextField
              type="email"
              label={t("Email")}
              margin="normal"
              className="kt-width-full"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && t(errors.email)}
              error={Boolean(touched.email && errors.email)}
            />
          </div>

          <div className="form-group">
            <TextField
              type="password"
              margin="normal"
              label={t("Пароль")}
              className="kt-width-full"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && t(errors.password)}
              error={Boolean(touched.password && errors.password)}
            />
          </div>
          <div className="text-center kt-login__extra">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  value="checked"
                  color="primary"
                />
              }
              label={t("Запомнить меня")}
            />
            <Link to="/auth/forgot-password">
              <Trans>Забыли пароль?</Trans>
            </Link>
          </div>

          <div className="text-center pt-2">
            <Button
              type="submit"
              disabled={isSubmitting}
              className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              <Trans>Войти</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
