import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Hint } from "../../Hint";
import { colors } from "../../../constants/colors";
import { getSummary } from "../../../redux/thunks/statistics.thunk";
import { useDispatch } from "react-redux";
import { getHours, getMinutes } from "../../../utils/getTime";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

const useStyles = makeStyles(theme => ({
  summaryItem: {
    "&:first-child": {
      width: "15%"
    },
    "&:last-child": {
      width: "80% !important",
      justifyContent: "left !important"
    }
  },
  label: {
    marginRight: "3%"
  }
}));

export const ProjectSummary = ({ projectId, date, singleView }) => {
  const dispatch = useDispatch();
  const [summary, setSummary] = useState([]);

  useEffect(() => {
    (async () => {
      const from = date.clone().startOf("month");
      const to = date.clone().endOf("month");
      const data = await dispatch(getSummary(projectId, from, to));

      if (data) {
        setSummary(data);
      }
    })();
  }, [projectId, date.format()]);

  if (!projectId) {
    return null;
  }

  return (
    <div className="kt-portlet kt-portlet--height-fluid kt-portlet--unelevate kt-portlet--marginless kt-padding-0">
      <div className="kt-portlet__body" style={{ margin: 0 }}>
        <SummaryTable summary={summary} singleView={singleView} />
      </div>
    </div>
  );
};

const SummaryTable = ({ summary, singleView }) => {
  const items = useMemo(
    () =>
      summary.map(({ user, plan, fact, spent }, index) => (
        <SummaryItem
          key={user.id}
          singleView={singleView}
          id={user.id}
          index={index}
          title={user.name}
          subtitle={user.positionName}
          plan={plan}
          fact={fact}
          spent={spent}
        />
      )),
    [summary]
  );

  const totalSpent = useMemo(
    () => summary.reduce((acc, { spent }) => acc + spent, 0),
    [summary]
  );

  return (
    <div className="kt-widget31">
      {/* prettier-ignore */}
      <Trans i18nKey='project-participated'>
        <p>В проекте участвовало <b>{{participantsCount: summary.length}} чел.</b> <br />Всего затрачено <b>{{hours: getHours(totalSpent)}} ч. {{minutes: getMinutes(totalSpent)}} м.</b></p>
      </Trans>
      <br />
      {items}
    </div>
  );
};

const SummaryItem = ({
  index,
  id,
  title,
  subtitle,
  plan,
  fact,
  spent,
  singleView
}) => {
  const classes = useStyles();
  return (
    <div className="kt-widget31__item" style={{ justifyContent: "left" }}>
      <div className={`kt-widget31__content ${classes.summaryItem}`}>
        <div className="kt-widget31__pic">
          <span
            className={`kt-badge kt-badge--unified-${
              colors[index % colors.length]
            } kt-badge--lg kt-badge--bold`}
          >
            {title[0].toUpperCase()}
          </span>
        </div>
        <div className="kt-widget31__info">
          <Link className="kt-widget31__username" to={`/calendar/${id}`}>
            {title}
          </Link>
          {subtitle && <p className="kt-widget31__text">{subtitle}</p>}
        </div>
      </div>
      {!singleView && (
        <div className={`kt-widget31__content ${classes.summaryItem}`}>
          <Hint
            text={
              <ParticipationTooltip plan={plan} fact={fact} spent={spent} />
            }
          >
            <span
              className={`btn btn-label-success btn-sm btn-bold btn-upper ${classes.label}`}
            >
              {getHours(spent)} <Trans>ч</Trans>. {getMinutes(spent)}{" "}
              <Trans>м</Trans>.
            </span>
          </Hint>
          <Hint text={<FactTooltip plan={plan} fact={fact} />}>
            <span
              className={`btn btn-label-brand btn-sm btn-bold btn-upper ${classes.label}`}
            >
              {getHours(fact)} <Trans>ч</Trans>. {getMinutes(fact)}{" "}
              <Trans>м</Trans>.
            </span>
          </Hint>
          <Hint text={<PlanTooltip plan={plan} />}>
            <span
              className={`btn btn-label-dark btn-sm btn-bold btn-upper ${classes.label}`}
            >
              {getHours(plan)} <Trans>ч</Trans>.
            </span>
          </Hint>
        </div>
      )}
    </div>
  );
};

const ParticipationTooltip = ({ plan, fact, spent }) => {
  return (
    <div>
      {/* prettier-ignore */}
      <Trans>
        <p>{{hours: getHours(spent)}} ч. {{minutes: getMinutes(spent)}} м. участвовал(а) в проекте</p>
      </Trans>

      {/* prettier-ignore */}
      <Trans>
        <p>{{percents: (spent / (fact / 100)).toFixed(2)}} % времени от фактически отработанного было уделено проекту</p>
      </Trans>

      {/* prettier-ignore */}
      <Trans>
        <p>{{percents: (spent / (plan / 100)).toFixed(2)}}% времени от планового было уделено проекту</p>
      </Trans>
    </div>
  );
};

const FactTooltip = ({ plan, fact }) => {
  return (
    <div>
      {/* prettier-ignore */}
      <Trans>
        <p>{{hours: getHours(fact)}} ч. {{minutes: getMinutes(fact)}} м. было фактически отработано часов в текущем месяце</p>
      </Trans>

      {/* prettier-ignore */}
      <Trans>
        <p>{{percents: (fact / (plan / 100)).toFixed(2)}}% от планового условия</p>
      </Trans>
    </div>
  );
};

const PlanTooltip = ({ plan }) => {
  return (
    <>
      {/* prettier-ignore */}
      <Trans>
        <p>{{hours: getHours(plan)}} ч. - плановое рабочее время в текущем месяце</p>
      </Trans>
    </>
  );
};
