import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createSelector } from "reselect";
import { useTranslation } from "react-i18next";

const userSelector = createSelector(
  state => state.settings.users || [],
  users => users.map(user => [user, user.name])
);

export const CalendarUserSelect = ({ userId, className, onChange }) => {
  const users = useSelector(userSelector);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState([{}, ""]);

  const handleChange = useCallback(
    (e, value) => {
      if (Array.isArray(value)) {
        onChange(value[0].id);
      }
    },
    [onChange]
  );

  useEffect(() => {
    const user = users.find(([user]) => user.id === userId);
    setCurrentUser(user);
  }, [userId, users]);

  return (
    <div className={`kt-portlet__head-label ${className}`}>
      <Autocomplete
        name="users"
        getOptionLabel={([, value]) => value}
        options={users}
        value={currentUser}
        onChange={handleChange}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            size="small"
            margin="none"
            label={t("Пользователь")}
            placeholder={t("Начните вводить имя пользователя")}
            className="form-control"
            variant="outlined"
          />
        )}
      />
    </div>
  );
};
