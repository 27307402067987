import { usePaginationData } from "../../tables/utils";
import { PROJECT_URL } from "../../../constants/api";
import { useSidebarProjectsList } from "../../../hooks/useSidebarProjectsList";
import { CalendarSidebar } from "../../calendar/sidebar/CalendarSidebar";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { useModalState } from "../../../hooks/useModalState";
import { ProjectModal } from "../ProjectModal";
import { useHistory } from "react-router";

export const ProjectsCalendarSidebar = ({
  date,
  projectId,
  handleOnPrevMonth,
  handleOnNextMonth
}) => {
  const history = useHistory();
  const paginationProps = usePaginationData(`${PROJECT_URL}`, {
    page: 1,
    pageSize: 5,
    startDate: date.clone().startOf("month"),
    endDate: date.clone().endOf("month")
  });

  const projectsList = useSidebarProjectsList({
    data: paginationProps.state.data,
    currentId: projectId
  });

  useEffect(() => {
    const startDate = date.clone().startOf("month");
    const endDate = date.clone().endOf("month");
    paginationProps.onRangeChange({ startDate, endDate });
  }, [date.format()]);

  const [open, close, isOpen] = useModalState(false);

  return (
    <>
      <CalendarSidebar
        date={date}
        paginationProps={paginationProps}
        handleOnPrevMonth={handleOnPrevMonth}
        handleOnNextMonth={handleOnNextMonth}
        handleButtonClick={open}
        buttonContent={
          <>
            <i className="fa fa-plus" /> <Trans>Добавить новый проект</Trans>
          </>
        }
      >
        {projectsList}
      </CalendarSidebar>
      <ProjectModal
        project={undefined}
        show={isOpen}
        close={close}
        refresh={result =>
          history.push(`/projects/statistic/${result.id}/summary`)
        }
      />
    </>
  );
};
