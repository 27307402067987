import { useEffect, useReducer } from "react";
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const isNotInTime = (date, boundary) => {
  const [hours, minutes] = boundary.split(":").map(v => parseInt(v));
  const dayStart = date.clone().set({ hours, minutes });
  const diff = date.diff(dayStart, "minutes");
  const isLate = diff >= 10;
  const isEarly = diff <= -10;

  return { isLate, isEarly };
};
const initialState = {
  start: undefined,
  end: undefined,
  count: undefined,
  plan: undefined,
  isLateStart: false,
  isEarlierStart: false,
  isLateEnd: false,
  isEarlierEnd: false
};
const slice = createSlice({
  initialState,
  name: "workDayStats",
  reducers: {
    setStats: (state, action) => {
      const { stats, company } = action.payload;

      if (!stats.count || !company) {
        let plan;

        if (company) {
          plan = moment(company.workEndsAt, "HH:mm").diff(
            moment(company.workStartsAt, "HH:mm"),
            "hours"
          );
        }

        return { ...initialState, plan };
      }

      state.plan = moment(company.workEndsAt, "HH:mm").diff(
        moment(company.workStartsAt, "HH:mm"),
        "hours"
      );

      state.count = stats.count;

      if (stats.start) {
        const date = moment(stats.start);
        const { isLate, isEarly } = isNotInTime(date, company.workStartsAt);
        state.start = date.format("HH:mm DD-MM-YYYY");
        state.isLateStart = isLate;
        state.isEarlierStart = isEarly;
      }

      if (stats.end) {
        const date = moment(stats.end);
        const { isLate, isEarly } = isNotInTime(date, company.workEndsAt);
        state.end = date.format("HH:mm DD-MM-YYYY");
        state.isLateEnd = isLate;
        state.isEarlierEnd = isEarly;
      }
    }
  }
});
export const useWorkDayStats = (stats, company) => {
  const [state, dispatch] = useReducer(slice.reducer, initialState);

  useEffect(() => {
    dispatch(slice.actions.setStats({ stats, company }));
  }, [JSON.stringify(stats), company]);

  return state;
};
