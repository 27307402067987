import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";

export const AuthLayout = ({ left, right }) => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700"
      ></link>
      <link rel="stylesheet" href="/css/auth.bundle.css" type="text/css"></link>
      <link rel="stylesheet" href="/css/auth.css" type="text/css"></link>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-2 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <LeftContainer>{left}</LeftContainer>

          <RightContainer>{right}</RightContainer>
        </div>
      </div>
    </>
  );
};

const LeftContainer = ({ children }) => {
  return (
    <div className="login-aside order-2 order-lg-1 d-flex flex-row-auto position-relative overflow-hidden">
      <div className="d-flex flex-column-fluid flex-column justify-content-between py-9 px-7 py-lg-13 px-lg-35" style={{width: '100%'}}>
        {children}
      </div>
    </div>
  );
};

export const IntroduceMessage = () => {
  return (
    <>
      <center>
        <img src="/media/auth/logo-full.png" style={{ maxHeight: "50px" }} />
      </center>
      <p
        className="font-weight-bolder font-size-h2-md font-size-lg text-dark opacity-70"
        style={{ color: "#000 !important", fontWeight: "400 !important" }}
      >
        <Trans>
          Эффективный и гибкий контроль времени,
          <br />
          затраченного на проект для команд и бизнеса
        </Trans>
      </p>
    </>
  );
};

const RightContainer = ({ children }) => {
  return (
    <div
      className="content order-1 order-lg-2 d-flex flex-column w-100 pb-0"
      style={{ backgroundColor: "#f3f3f3" }}
    >
      <div className="d-flex flex-column justify-content-center text-center pt-lg-40 pt-md-5 pt-sm-5 px-lg-0 pt-5 px-7">
        {children}
      </div>

      <div
        className="content-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
        style={{ backgroundImage: "url(/media/auth/login_bg.png)" }}
      />
    </div>
  );
};
