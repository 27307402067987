import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { holidaysSelector } from "../../../redux/features/calendarFeatureSlice";
import moment from "moment";
import { CalendarDayContainer } from "../../calendar/main/CalendarDayContainer";
import { ProjectDaySummary } from "./ProjectDaySummary";
import { summarySelector } from "../../../redux/features/projectsFeatureSlice";
import { ProjectDescriptionHint } from "./ProjectTimeline";

const useStyles = makeStyles(() => ({
  noWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  dayHeight: {
    minHeight: "150px"
  }
}));

export const ProjectDaysView = ({ days, date }) => {
  const holidays = useSelector(holidaysSelector);
  const summaries = useSelector(summarySelector);
  const classes = useStyles();

  return days.map((calendarDay, index) => {
    const currentDay = (holidays || []).find(holiday =>
      moment(holiday.date).isSame(calendarDay, "day")
    );

    const isHoliday = (currentDay && currentDay.isHoliday) || false;
    const [, summary] =
      summaries.find(([date]) => date === calendarDay.format("YYYY-MM-DD")) ||
      [];

    return (
      <CalendarDayContainer
        key={index}
        classes={classes.dayHeight}
        calendarDay={calendarDay}
        date={date}
        isHoliday={isHoliday}
        isLoading={Object.keys(summaries).length === 0}
      >
        <ProjectDaySummary
          summary={summary}
          HintComponent={ProjectDescriptionHint}
          placement="right"
        />
      </CalendarDayContainer>
    );
  });
};
