import { projectsToOptions } from "../../hooks/useSearchState";
import { extractErrorInfo } from "../../utils/extractError";
import { createSlice } from "@reduxjs/toolkit";
import { uniq, uniqBy, differenceBy, intersectionBy } from "lodash";
import { openSnackbar } from "./snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import {
  findProjectsByQuery,
  findRecentProjects
} from "./calendarFeatureSlice";

export const createOptsForNewProjects = (projectNames: string[]) =>
  projectNames.map(name => ({
    key: name,
    value: name,
    text: name,
    new: 1
  }));

export const initialState = {
  recentProjects: [],
  options: [],
  selectedProjects: [],
  projectIds: [],
  newProjects: [],
  loading: false
};

const searchFeatureSlice = createSlice({
  initialState,
  name: "search",
  reducers: {
    setRecentProjects: (state, action) => {
      state.recentProjects = uniqBy(action.payload, "value");
      state.options = uniqBy([...action.payload, ...state.options], "value");
    },
    setFoundProjects: (state, action) => {
      state.options = uniqBy([...state.options, ...action.payload], "value");
    },
    setSelectedProjects: (state, action) => {
      state.selectedProjects = uniq(action.payload);
      state.projectIds = state.selectedProjects
        .filter(opt => !opt.new)
        .map(opt => opt.value);
      state.newProjects = state.selectedProjects
        .filter(opt => opt.new)
        .map(opt => opt.value);
      state.options = uniq([...state.selectedProjects, ...state.options]);
    },
    resetOptions: state => {
      if (!state.selectedProjects.length) {
        state.options = state.recentProjects;
      } else {
        state.options = uniqBy(
          [
            ...intersectionBy(state.options, state.selectedProjects, "value"),
            ...state.recentProjects
          ],
          "value"
        );
      }
    },
    addProject: (state, action) => {
      const filteredOpts = state.options.filter(o => !o.new);
      state.selectedProjects = uniqBy(action.payload, "value");
      state.projectIds = intersectionBy(
        state.selectedProjects,
        filteredOpts,
        "value"
      ).map(({ value }) => value);
      state.newProjects = differenceBy(
        state.selectedProjects,
        filteredOpts,
        "value"
      ).map(({ value }) => value);
      state.options = [...state.recentProjects, ...state.selectedProjects];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reset: () => {
      return initialState;
    }
  }
});

export default searchFeatureSlice.reducer;
export const {
  setRecentProjects,
  setFoundProjects,
  setSelectedProjects,
  resetOptions,
  addProject,
  reset
} = searchFeatureSlice.actions;

export const updateOptionsList = searchQuery => async dispatch => {
  const data = await dispatch(findProjectsByQuery(searchQuery));
  dispatch(setFoundProjects(projectsToOptions(data)));
};

export const fetchRecent = ids => async dispatch => {
  try {
    const data = await dispatch(findRecentProjects(ids));
    dispatch(setRecentProjects(projectsToOptions(data)));
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
  }
};
