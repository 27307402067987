export default {
  header: {
    self: {},
    items: [
      {
        title: "Календарь",
        root: true,
        alignment: "left",
        page: "/calendar",
        roles: ["root", "owner", "manager"]
      },
      {
        title: "Проекты",
        root: true,
        alignment: "left",
        exact: true,
        page: "/projects",
        roles: ["root", "owner", "manager"]
      },
      {
        title: "Статистика проектов",
        root: true,
        alignment: "left",
        page: "/projects/statistic",
        roles: ["root", "owner", "manager"]
      },
      {
        title: "Сотрудники",
        root: true,
        alignment: "left",
        page: "/employees",
        roles: ["root", "owner", "manager"]
      },
      {
        title: "Настройки",
        root: true,
        alignment: "left",
        page: "/settings",
        roles: ["root", "owner", "manager"]
      },
      {
        title: "Регистарции",
        root: true,
        alignment: "left",
        page: "/admin/registrations",
        roles: ["root"]
      }
    ]
  },
  aside: {
    self: {},
    items: [
      { section: "Custom" },
      {
        title: "Error Pages",
        root: true,
        bullet: "dot",
        icon: "flaticon-danger",
        submenu: [
          {
            title: "Error Page - 1",
            page: "error/error-v1"
          },
          {
            title: "Error Page - 2",
            page: "error/error-v2"
          },
          {
            title: "Error Page - 3",
            page: "error/error-v3"
          },
          {
            title: "Error Page - 4",
            page: "error/error-v4"
          },
          {
            title: "Error Page - 5",
            page: "error/error-v5"
          },
          {
            title: "Error Page - 6",
            page: "error/error-v6"
          }
        ]
      }
    ]
  }
};
