import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import {
  createProjectRequest,
  deleteProjectByIdRequest,
  getProjectRequest,
  getProjectsListByIdRequest,
  getProjectsListRequest,
  mergeRequest,
  updateProjectRequest
} from "../../crud/project.api";
import { setList, setSummary } from "../features/projectsFeatureSlice";
import { getProjectCalendarSummary } from "../../crud/statistics.api";

export const updateProject = (id, values) => async dispatch => {
  try {
    await updateProjectRequest(id, values);
    dispatch(openSnackbar(Fade, `Проект был успешно обновлён`, "success"));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const deleteProject = id => async dispatch => {
  try {
    await deleteProjectByIdRequest(id);
    dispatch(openSnackbar(Fade, `Проект был успешно удалён`, "success"));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const createProject = values => async dispatch => {
  try {
    const { data } = await createProjectRequest(values);
    dispatch(openSnackbar(Fade, `Проект был успешно создан`, "success"));
    return data;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const getProjectById = id => async dispatch => {
  try {
    const { data } = await getProjectRequest(id);
    return data;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const getProjectsListByIds = ids => async dispatch => {
  try {
    const { data } = await getProjectsListByIdRequest(ids);
    return data;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const mergeProjects = ({ project, projects }) => async dispatch => {
  try {
    await mergeRequest({ project, projects });
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const getFullProjectsList = () => async dispatch => {
  try {
    const { data } = await getProjectsListRequest();
    dispatch(setList(data));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const getSummaries = (projectId, from, to) => async dispatch => {
  try {
    const { data } = await getProjectCalendarSummary(
      projectId,
      from.toISOString(),
      to.toISOString()
    );
    dispatch(setSummary(data.summaries));
    return data.project;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};
