import React from "react";
import { requestPassword } from "../../crud/auth.crud";
import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";
import styled from "styled-components";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Неверный email")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле")
});

const Button = styled.button`
  &&& {
    padding: 13px 26px !important;
    margin: 0 5px;
  }
`;

export default function FormRequestPassword({ history, onSubmit }) {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values.email, setSubmitting);
      }}
    >
      {({
        values,
        status,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form onSubmit={handleSubmit} className="kt-form">
          {status && (
            <div role="alert" className="alert alert-danger">
              <div className="alert-text">{status}</div>
            </div>
          )}
          <div className="form-group">
            <TextField
              type="email"
              label={t("Email")}
              margin="normal"
              fullWidth={true}
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && t(errors.email)}
              error={Boolean(touched.email && errors.email)}
            />
          </div>

          <div className="text-center pt-2">
            <Button
              type="button"
              className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
              onClick={() => history.push("/auth/login")}
            >
              <Trans>Отмена</Trans>
            </Button>
            <Button
              type="submit"
              className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
              disabled={isSubmitting}
            >
              <Trans>Восстановить</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
