import { useMemo } from "react";

/**
 * функция, возвращающая объект конфигурации столбцов таблицы
 * @name createColumns
 * @function
 * @param {Object} extraData объект, содержащий в себе произвольную информацию,
 * требующуюся для создания объекта конфигурации столбцов таблицы
 * @returns {Object} конфигурация столбцов таблицы
 */

/**
 * хук useColumns
 * @name useColumns
 * @function {Object} extraData объект, содержащий в себе произвольную информацию,
 * требующуюся для создания объекта конфигурации столбцов таблицы
 * @returns {Object} конфигурация столбцов таблицы
 */

/**
 * Функция-фабрика для создания хука useColumns. Создание хука должно происходить ВНЕ компонента
 * @param {createColumns} createColumns функция, возвращающая конфигурацию столбцов для таблицы
 * @returns {useColumns}
 */
export const createUseColumns = createColumns => {
  return extraData => {
    return useMemo(() => createColumns(extraData), [extraData]);
  };
};
