import { Fade } from "@material-ui/core";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  transition: Fade,
  message: "",
  type: "info"
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnack: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    closeSnack: (state, action) => {
      return {
        ...state,
        open: false
      };
    },
    resetSnack: () => {
      return initialState;
    }
  }
});

export const openSnackbar = (transition, message, type) => (
  dispatch,
  getState
) => {
  if (!getState().snackbar.open) {
    dispatch(openSnack({ transition, message, type, open: true }));
  }
};

export default snackbarSlice.reducer;
export const { openSnack, closeSnack, resetSnack } = snackbarSlice.actions;
