import React, { useEffect } from "react";
import { AuthLayout, IntroduceMessage } from "../../components/auth/Container";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { onAuthentication } from "../../redux/features/authFeatureSlice";
import FormLogin from "../../components/forms/FormLogin";

const LoginForm = () => {
  const location = useLocation().pathname.split(/[?#]/)[0];
  const goTo = location === "/auth/register" ? "/auth/login" : "/auth/register";
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const { i18n } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    if (user !== undefined && user.status === "first-login") {
      history.push("/auth/company");
    }
  }, [history, user]);

  async function onSubmitLogin(values, rememberMe) {
    await dispatch(onAuthentication({ ...values, lang }, rememberMe));
  }

  return (
    <>
      <a href="#" className="text-center pt-2">
        <img
          src="/media/auth/logo.png"
          style={{ maxHeight: "90px", maxWidth: "90px" }}
          alt=""
        />
      </a>
      <div className="d-flex flex-column-fluid flex-column flex-center">
        <div className="login-form login-signin py-11">
          <div className="text-center pb-8">
            <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
              Workapp
            </h2>
          </div>

          <FormLogin onSubmitLogin={onSubmitLogin} />

          <center>
            <span>
              <Trans>Еще нет аккаунта?</Trans>{" "}
              <Link
                to={goTo}
                className="text-primary font-weight-bolder"
                id="kt_login_signup"
              >
                <Trans>Регистрация</Trans>
              </Link>
            </span>
          </center>
        </div>
      </div>
      <center>
        <a href="https://linkodium.com" target="_blank">
          Linkodium
        </a>{" "}
        (2020)
      </center>
    </>
  );
};

export const NewLogin = () => {
  return <AuthLayout left={<LoginForm />} right={<IntroduceMessage />} />;
};
