import CalendarMini from "./CalendarMini";
import React, { useCallback, useState } from "react";
import { SidebarPagination } from "../../SidebarPagination";

export const CalendarSidebar = ({
  children,
  handleButtonClick,
  buttonContent,
  paginationProps,
  date,
  handleOnPrevMonth,
  handleOnNextMonth
}) => {
  const { state, changePage } = paginationProps;
  const { data, page, totalSize, pageCount } = state;

  return (
    <>
      <div className="row">
        <CalendarMini
          className="col"
          currentDate={date}
          handleOnPrevMonth={handleOnPrevMonth}
          handleOnNextMonth={handleOnNextMonth}
        />
      </div>

      <hr />

      <div className="kt-notification-v2">
        {children}

        {handleButtonClick && buttonContent && (
          <CalendarSidebarButton handleClick={handleButtonClick}>
            {buttonContent}
          </CalendarSidebarButton>
        )}

        <SidebarPagination
          display={data.length < totalSize}
          page={page}
          pageCount={pageCount}
          changePage={changePage}
        />
      </div>
    </>
  );
};

const CalendarSidebarButton = ({ handleClick, children }) => {
  const [classes, setClasses] = useState("");

  const onMouseEnter = useCallback(() => {
    setClasses("kt-bg-fill-success");
  }, []);

  const onMouseLeave = useCallback(() => {
    setClasses("");
  }, []);
  return (
    <div
      onClick={handleClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        cursor: "pointer",
        justifyContent: "center"
      }}
      className={`kt-notification-v2__item ${classes}`}
    >
      <div
        style={{
          margin: "0.5rem 0"
        }}
      >
        {children}
      </div>
    </div>
  );
};
