import React from "react";
import CalendarWeeksHeader from "../header/CalendarWeeksHeader";

export default function CalendarDaysContainer({ children, hideWeeks }) {
  return (
    <div className="kt-portlet__body" style={{ padding: 0 }}>
      <div className="container-fluid">
        {!hideWeeks && <CalendarWeeksHeader />}
        <div className="row">{children}</div>
      </div>
    </div>
  );
}
