import React, { useCallback, useEffect, useState } from "react";
import { withTooltip } from "@vx/tooltip";
import { WorkStatistic } from "../../components/charts/WorkStatistic";
import { useHistory, useParams } from "react-router";
import CalendarMini from "../../components/calendar/sidebar/CalendarMini";
import moment from "moment";
import { getWorkStatistics } from "../../crud/statistics.api";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Trans } from "react-i18next";
import { WorkDaySummary } from "../../components/charts/WorkDaySummary";
import { createSelector } from "reselect";
import { CalendarUserSelect } from "../../components/calendar/header/CalendarUserSelect";
import { fetchHolidays } from "../../redux/features/calendarFeatureSlice";
import { getUsersList } from "../../redux/thunks/user.thunk";

const userSelector = createSelector(
  state => state.settings.users,
  (state, userId) => userId,
  (users, userId) => users.find(user => user.id === userId)
);

const Stats = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector(state => userSelector(state, id));
  const [data, setData] = useState({ timeSpans: [], appSessions: [] });
  const [currentDate, setCurrentDate] = useState(moment().utc(true));

  const handleOnPrevMonth = useCallback(() => {
    const newDate = currentDate.clone().subtract(1, "month");
    dispatch(fetchHolidays(newDate));
    setCurrentDate(newDate);
  }, [currentDate.format()]);

  const handleOnNextMonth = useCallback(() => {
    const newDate = currentDate.clone().add(1, "month");
    dispatch(fetchHolidays(newDate));
    setCurrentDate(newDate);
  }, [currentDate.format()]);

  useEffect(() => {
    dispatch(getUsersList());
    dispatch(fetchHolidays(currentDate));
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getWorkStatistics(id, currentDate.format());
        setData(data);
      } catch (e) {
        const { header, message } = extractErrorInfo(e);
        dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
      }
    })();
  }, [currentDate.format(), id]);

  if (!user) {
    return null;
  }

  return (
    <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space kt-portlet--height-fluid">
      <div className="kt-portlet__head text-center">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {/*prettier-ignore*/}
            <Trans>Статистика работы сотрудника {{user: user.name}} за {{date: currentDate.format("DD.MM.YYYY")}}</Trans>
          </h3>
        </div>
      </div>
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="row row-no-padding h-100 align-items-center">
          <div className="col-xl-2 col-12">
            <div className="d-flex flex-column">
              <div className="col">
                <CalendarUserSelect
                  userId={id}
                  onChange={userId => history.push(`/work-statistic/${userId}`)}
                />
              </div>

              <div className="col">
                <CalendarMini
                  clickable
                  className="w-100 pt-xl-0 pl-xl-5 pr-xl-5 pb-xl-5"
                  currentDate={currentDate}
                  setCurrentDate={setCurrentDate}
                  handleOnPrevMonth={handleOnPrevMonth}
                  handleOnNextMonth={handleOnNextMonth}
                />
              </div>
            </div>
          </div>
          <div className="col-xl-10 col-12">
            <WorkStatistic
              data={data.timeSpans}
              currentDate={currentDate}
              userId={id}
            />
          </div>
          <div className="col-12">
            <WorkDaySummary
              appSessions={data.appSessions}
              timeSpans={data.timeSpans}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Statistics = withTooltip(Stats);
