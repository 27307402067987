import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField
} from "@material-ui/core";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import filterCRUDValues from "../../utils/filterCRUDValues";
import { Trans, useTranslation } from "react-i18next";
import getTimezoneEntries from "../../utils/getTimezones";
import zones from "../../utils/timezones";

const CreateUserSchema = Yup.object().shape({
  position: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  name: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  password: Yup.string().when("role", {
    is: "owner" || "manager",
    then: Yup.string()
      .min(6, "Пароль должен содержать не менее 6 символов")
      .max(255, "Максимальная величина поля 255 символов")
      .required("Обязательное поле"),
    otherwise: Yup.string().notRequired()
  }),
  role: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  email: Yup.string().when("role", {
    is: "owner" || "manager",
    then: Yup.string()
      .email("Неверный email")
      .max(255, "Максимальная величина поля 255 символов")
      .required("Обязательное поле"),
    otherwise: Yup.string().notRequired()
  })
});

const timezones = getTimezoneEntries();

export const AddUser = ({ open, closeModal, handleAdd, currentUser }) => {
  const { t } = useTranslation();
  const { company } = useSelector(state => state.auth);

  const roles = [
    { value: "employee", name: t("Сотрудник") },
    { value: "manager", name: t("Менеджер WorkApp") },
    { value: "owner", name: t("Владелец") }
  ];

  return (
    <Modal className="modal fade" show={open} onHide={closeModal}>
      <Formik
        initialValues={{
          password: "",
          email: "",
          name: "",
          position: "",
          role: "employee",
          timeZone: company.timeZone,
          checkbox: true
        }}
        validationSchema={CreateUserSchema}
        onSubmit={async values => {
          const result = await handleAdd(filterCRUDValues(values));

          if (result) {
            closeModal();
          }
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <Trans>Добавление сотрудника</Trans>
              </Modal.Title>
            </Modal.Header>

            <form
              noValidate={true}
              autoComplete="new-password"
              className="kt-form"
              onSubmit={handleSubmit}
            >
              <Modal.Body>
                <TextField
                  fullWidth
                  size="small"
                  autoComplete="new-password"
                  select
                  label={t("Роль")}
                  name="role"
                  value={values.role}
                  onChange={e => {
                    if (e.target.value === "employee") {
                      setFieldValue("email", "");
                      setFieldValue("password", "");
                    }
                    handleChange(e);
                  }}
                  variant="outlined"
                >
                  {currentUser.role.name === "owner"
                    ? roles.map(role => (
                        <MenuItem key={role.value} value={role.value}>
                          {role.name}
                        </MenuItem>
                      ))
                    : roles
                        .filter(role => role.value !== "owner")
                        .map(role => (
                          <MenuItem key={role.value} value={role.value}>
                            {role.name}
                          </MenuItem>
                        ))}
                </TextField>

                <TextField
                  label={t("ФИО")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  helperText={touched.name && t(errors.name)}
                  error={Boolean(touched.name && errors.name)}
                />

                {values.role !== "employee" && (
                  <>
                    <TextField
                      label={t("Email")}
                      autoComplete="new-password"
                      type="email"
                      margin="normal"
                      className="form-control"
                      size="small"
                      variant="outlined"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && t(errors.email)}
                      error={Boolean(touched.email && errors.email)}
                    />

                    <TextField
                      label={t("Пароль")}
                      autoComplete="new-password"
                      type="password"
                      margin="normal"
                      className="form-control"
                      size="small"
                      variant="outlined"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && t(errors.password)}
                      error={Boolean(touched.password && errors.password)}
                    />
                  </>
                )}

                <TextField
                  label={t("Должность")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="position"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.position}
                  helperText={touched.position && t(errors.position)}
                  error={Boolean(touched.position && errors.position)}
                />

                <FormControlLabel
                  checked={values.checkbox}
                  labelPlacement="end"
                  name="checkbox"
                  label={t("Часовой пояс сотрудника совпадает с компанией")}
                  control={<Checkbox color="primary" />}
                  value={values.checkbox}
                  onChange={e => {
                    if (e.target.value) {
                      values.timeZone = company.timeZone;
                    }

                    return handleChange(e);
                  }}
                  onBlur={handleBlur}
                />

                {!values.checkbox && (
                  <Autocomplete
                    name="timeZone"
                    getOptionLabel={([, value]) => value}
                    options={timezones}
                    value={[values.timeZone, zones[values.timeZone]]}
                    onChange={(e, value) => {
                      if (Array.isArray(value)) {
                        setFieldValue("timeZone", value[0]);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        margin="normal"
                        label={t("Часовой пояс")}
                        variant="outlined"
                      />
                    )}
                  />
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  <Trans>Создать</Trans>
                </Button>
                <Button variant="secondary" onClick={closeModal}>
                  <Trans>Закрыть</Trans>
                </Button>
              </Modal.Footer>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
};
