import { createSlice } from "@reduxjs/toolkit";
import { getSubscription } from "../../crud/subscription.api";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "./snackbarFeatureSlice";
import { Fade } from "@material-ui/core";

const initialState = {};

export const subscriptionSlice = createSlice({
  initialState,
  name: "subscription",
  reducers: {
    setSubscription: (state, action) => {
      return action.payload;
    }
  }
});

export default subscriptionSlice.reducer;
export const { setSubscription } = subscriptionSlice.actions;

export const fetchSubscription = () => async dispatch => {
  try {
    const { data } = await getSubscription();
    dispatch(setSubscription(data));
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
  }
};
