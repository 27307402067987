import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import moment from "moment";
import filterCRUDValues from "../../utils/filterCRUDValues";
import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import * as Yup from "yup";
import {
  addPromocode,
  updatePromocode
} from "../../redux/thunks/promocode.thunk";

const PromocodeSchema = Yup.object().shape({
  code: Yup.string()
    .max(32, "Максимальная величина поля 32 символов")
    .required("Обязательное поле"),
  tariffName: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  validUntil: Yup.date().required("Обязательное поле"),
  activationLimit: Yup.number()
    .min(1, "Значение не может быть меньше 1")
    .required("Обязательное поле"),
  slotsCount: Yup.number()
    .min(1, "Значение не может быть меньше 1")
    .required("Обязательное поле"),
  monthsCount: Yup.number()
    .min(1, "Значение не может быть меньше 1")
    .required("Обязательное поле")
});

export const PromocodeModal = ({ show, close, promocode, refresh }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <Modal className="modal fade" show={show} onHide={close}>
      <Formik
        {...{
          initialValues: {
            code: (promocode && promocode.code) || "",
            tariffName: (promocode && promocode.tariffName) || "",
            validUntil:
              (promocode && moment(promocode.validUntil).toDate()) ||
              new Date(),
            activationLimit: (promocode && promocode.activationLimit) || 0,
            slotsCount: (promocode && promocode.slotsCount) || 0,
            monthsCount: (promocode && promocode.monthsCount) || 0
          },
          validationSchema: PromocodeSchema,
          onSubmit: async values => {
            let result;

            if (promocode) {
              result = await dispatch(
                updatePromocode(promocode.id, filterCRUDValues(values))
              );
            } else {
              result = await dispatch(addPromocode(filterCRUDValues(values)));
            }

            if (result) {
              await refresh();
              close();
            }
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          submitForm
        }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {!promocode ? (
                  <Trans>Добавить новый промокод</Trans>
                ) : (
                  <Trans>Обновить промокод</Trans>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                noValidate={true}
                autoComplete="new-password"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                <TextField
                  label={t("Код")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  helperText={errors.code && t(errors.code)}
                  error={Boolean(errors.code)}
                />
                <TextField
                  label={t("Название тарифа")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="tariffName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.tariffName}
                  helperText={errors.tariffName && t(errors.tariffName)}
                  error={Boolean(errors.tariffName)}
                />
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  margin="normal"
                  inputVariant="outlined"
                  size="small"
                  label={t("Действует до")}
                  format="YYYY-MM-DD"
                  name="validUntil"
                  onBlur={handleBlur}
                  onChange={date => setFieldValue("validUntil", date)}
                  value={values.validUntil}
                  InputAdornmentProps={{ position: "start" }}
                  helperText={errors.validUntil && t(errors.validUntil)}
                  error={Boolean(errors.validUntil)}
                />
                <TextField
                  label={t("Лимит активаций")}
                  autoComplete="new-password"
                  type="number"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="activationLimit"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.activationLimit}
                  helperText={
                    errors.activationLimit && t(errors.activationLimit)
                  }
                  error={Boolean(errors.activationLimit)}
                />
                <TextField
                  label={t("Количество пользователей")}
                  autoComplete="new-password"
                  type="number"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="slotsCount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.slotsCount}
                  helperText={errors.slotsCount && t(errors.slotsCount)}
                  error={Boolean(errors.slotsCount)}
                />
                <TextField
                  label={t("Количество месяцев")}
                  autoComplete="new-password"
                  type="number"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="monthsCount"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.monthsCount}
                  helperText={errors.monthsCount && t(errors.monthsCount)}
                  error={Boolean(errors.monthsCount)}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                <Trans>Закрыть</Trans>
              </Button>
              <Button variant="outline-success" onClick={submitForm}>
                {!promocode ? (
                  <Trans>Сохранить</Trans>
                ) : (
                  <Trans>Обновить</Trans>
                )}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
