import React from "react";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import { Formik } from "formik";
import * as Yup from "yup";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

const CompanySchema = Yup.object().shape({
  password: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref("password"), null], "Пароли не совпадают")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле")
});

const Button = styled.button`
  &&& {
    padding: 13px 26px !important;
  }
`;

export function FormResetPassword({
  loadingButtonStyle,
  loading,
  enableLoading,
  onSubmit
}) {
  const { t } = useTranslation();
  return (
    <Formik
      initialValues={{
        password: "",
        passwordRepeat: ""
      }}
      validationSchema={CompanySchema}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        enableLoading();
        onSubmit(values, setSubmitting, setStatus);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <form
          noValidate={true}
          autoComplete="off"
          className="kt-form"
          onSubmit={handleSubmit}
        >
          <div className="form-group">
            <TextField
              type="password"
              margin="normal"
              label={t("Пароль")}
              placeholder={t("Введите новый пароль")}
              className="kt-width-full"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.password}
              helperText={touched.password && t(errors.password)}
              error={Boolean(touched.password && errors.password)}
            />
          </div>

          <div className="form-group">
            <TextField
              type="password"
              margin="normal"
              label={t("Подтверждение пароля")}
              placeholder={t("Повторите новый пароль")}
              className="kt-width-full"
              name="passwordRepeat"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.passwordRepeat}
              helperText={touched.passwordRepeat && t(errors.passwordRepeat)}
              error={Boolean(touched.passwordRepeat && errors.passwordRepeat)}
            />
          </div>

          <div className="text-center pt-2">
            <Button
              id="kt_login_signin_submit"
              type="submit"
              disabled={isSubmitting}
              className="btn btn-dark font-weight-bolder font-size-h6 px-8 py-4 my-3"
            >
              <Trans>Сбросить</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}
