import { useDispatch, useSelector, batch } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  addProject,
  fetchRecent,
  resetOptions,
  updateOptionsList
} from "../redux/features/searchFeatureSlice";

export const projectsToOptions = projects =>
  projects.map(project => ({
    key: project.id,
    value: project.id,
    text: project.name,
    new: 0
  }));

export const useSearchState = ({ searchQuery }) => {
  const dispatch = useDispatch();
  const searchState = useSelector(state => state.search);
  const [query, setQuery] = useState(searchQuery);
  const [highlight, setHighlight] = useState(undefined);

  const memoizedData = useMemo(() => {
    const onSearchChange = async (e, searchQuery) => {
      if (e === null) {
        return;
      }

      setQuery(searchQuery);
      dispatch(updateOptionsList(searchQuery));
    };

    const onChange = (e, data) => {
      dispatch(addProject(data));
      setHighlight(undefined);
      setQuery("");
      dispatch(resetOptions());
    };

    const onBlur = () => {
      setHighlight(undefined);
      setQuery("");
      dispatch(resetOptions());
    };

    return {
      onSearchChange,
      onChange,
      onBlur
    };
  }, []);

  const onKeyDown = useCallback(
    event => {
      if (event.key !== "Tab" || !highlight) {
        return;
      }

      setQuery("");
      memoizedData.onChange(
        event,
        searchState.selectedProjects.concat(highlight)
      );
    },
    [highlight, searchState.selectedProjects]
  );

  useEffect(() => {
    dispatch(fetchRecent(searchState.projectIds));
  }, []);

  return useMemo(
    () => ({
      query,
      searchState,
      setHighlight,
      onSearchChange: memoizedData.onSearchChange,
      onChange: memoizedData.onChange,
      onBlur: memoizedData.onBlur,
      onKeyDown
    }),
    [query, searchState, memoizedData]
  );
};
