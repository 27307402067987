import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { getTaskHistory } from "../../crud/calendar.crud";
import { Spinner } from "reactstrap";
import { Collapse } from "@material-ui/core";
import moment from "moment";

const toZonedTime = string =>
  moment.utc(string, "YYYY-MM-DD HH:mm:ss.SSSSSS").format();

const HistoryContainer = styled.div`
  &:first-child:hover {
    background: rgba(10, 187, 135, 0.1);
  }

  &:hover {
    background: #f8f9fa;
  }

  padding: 10px 20px !important;

  &:not(:last-child) {
    border-bottom: 1px dashed #ebedf2;
  }
`;

const Current = styled.div`
  position: absolute;
  right: 0;
  color: #0abb87;
  padding: 0 15px;
`;

const HistoryEntry = ({ current, task }) => {
  return (
    <HistoryContainer className="col-md-12">
      {current && <Current>Текущая версия</Current>}
      <div className="row">
        <div className="col-12">
          <b>Дата:</b>{" "}
          {moment(toZonedTime(task.version)).format("DD.MM.YYYY HH:mm")}
        </div>
        <div className="col-12">
          <b>Кто:</b> {task.updatedBy ? task.updatedBy.name : task.user.name}
        </div>
        <div className="col-12">
          <b>Задачи:</b>{" "}
          {Array.isArray(task.projects)
            ? task.projects.map(project => project.name).join(", ")
            : task.projectText}
        </div>
        <div className="col-12">
          <b>Описание:</b> {task.description}
        </div>
      </div>
    </HistoryContainer>
  );
};

export const TaskHistory = ({ task }) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const { data } = await getTaskHistory(task.id);

        setHistory(data.history);
      } catch (e) {}

      setLoading(false);
    })();
  }, []);

  return (
    <>
      <span
        onClick={() => setOpen(prev => !prev)}
        style={{ cursor: "pointer" }}
      >
        История изменений:{" "}
        {Array.isArray(history) && history.length > 0 ? (
          `${history.length} записей`
        ) : loading ? (
          <Spinner color="primary" type="grow" size={"sm"} />
        ) : (
          "нет записей"
        )}
      </span>
      <Collapse in={open}>
        {Array.isArray(history) && history.length > 0 && (
          <div className="row">
            <HistoryEntry current task={task} />

            {history.map(taskEntry => (
              <HistoryEntry key={taskEntry.surrogate} task={taskEntry} />
            ))}
          </div>
        )}
      </Collapse>
    </>
  );
};
