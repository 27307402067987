import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useCallback } from "react";
import {
  deleteTask,
  putTaskIntoView
} from "../redux/features/calendarFeatureSlice";
import { openSnackbar } from "../redux/features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { createTaskByUserId, updateTaskById } from "../crud/calendar.crud";
import { extractErrorInfo } from "../utils/extractError";

export const useTaskCRUD = ({ userId, task, close }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const date = moment(task.startDate);

  const removeHandler = useCallback(async () => {
    const result = await dispatch(deleteTask(task));

    if (result) {
      dispatch(openSnackbar(Fade, t("Задача удалена"), "success"));
      close();
    }
  }, [task, userId, close]);

  const updateHandler = useCallback(
    async ({ projectIds, newProjects, description }) => {
      try {
        const { data } = await updateTaskById({
          id: task.id,
          projectIds,
          newProjects,
          description
        });
        dispatch(putTaskIntoView([data]));
        close();
        dispatch(openSnackbar(Fade, t("Задача обновлена"), "success"));
      } catch (e) {
        const { header, message } = extractErrorInfo(e);
        dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
      }
    },
    [task, userId, close]
  );

  const createHandler = useCallback(
    async ({ projectIds, newProjects, description }) => {
      try {
        const endDate = date.clone().hour(date.clone().hour() + 1);
        const { data } = await createTaskByUserId({
          userId,
          projectIds,
          newProjects,
          description,
          startDate: date.toISOString(),
          endDate: endDate.toISOString()
        });
        dispatch(putTaskIntoView([data]));
        close();
        dispatch(openSnackbar(Fade, t("Задача создана"), "success"));
      } catch (e) {
        const { header, message } = extractErrorInfo(e);
        dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
      }
    },
    [task, userId, close]
  );

  return { createHandler, updateHandler, removeHandler };
};
