import { createSlice } from "@reduxjs/toolkit";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {
  debouncedFetchProjectsByQuery,
  deleteTaskById,
  getHolidaysByMonth,
  getRecentProjects,
  getTasksByUserId,
  getWorkStatsInTimeSpan
} from "../../crud/calendar.crud";
import { monthsMapping } from "../../utils/getDaysInMonth";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "./snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { batch } from "react-redux";
import { createSelector } from "reselect";

const moment = extendMoment(Moment);

const initialState = {
  viewState: "task",
  view: {},
  holidays: [],
  userId: undefined,
  workStats: []
};
const calendar = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    resetView: state => {
      return {
        ...state,
        view: {}
      };
    },
    createMonthView: (state, action) => {
      const { days, start, end } = action.payload;

      const range = moment.range(moment(start, "HH:mm"), moment(end, "HH:mm"));

      for (const day of days) {
        const daySchedule = state.view[day.format("YYYY-MM-DD")] || {};

        for (const slot of range.by("hours")) {
          if (daySchedule[slot.format("HH:mm")] === undefined) {
            daySchedule[slot.format("HH:mm")] = null;
          }
        }

        state.view[day.format("YYYY-MM-DD")] = Object.keys(daySchedule)
          .sort()
          .reduce((acc, key) => ((acc[key] = daySchedule[key]), acc), {});
      }
    },
    putTaskIntoView: (state, action) => {
      const tasks = action.payload;

      for (const task of tasks) {
        const date = moment(task.startDate);
        const day = state.view[date.format("YYYY-MM-DD")];
        if (day) {
          state.view[date.format("YYYY-MM-DD")][date.format("HH:00")] = task;
        } else {
          console.log("no date found", date.format());
        }
      }
    },
    setWorkStatistic: (state, action) => {
      const data = action.payload;
      state.workStats = data;
    },
    resetWorkStatistic: state => {
      state.workStats = [];
    },
    removeFromView: (state, action) => {
      const task = action.payload;
      const date = moment(task.startDate);
      state.view[date.format("YYYY-MM-DD")][date.format("HH:mm")] = null;
    },
    setActiveUser: (state, action) => {
      const { userId } = action.payload;
      return {
        ...state,
        userId
      };
    },
    setViewState: (state, action) => {
      const switchState = action.payload;
      state.viewState = switchState ? "stats" : "task";
    },
    resetHolidays: state => {
      state.holidays = [];
    },
    setHolidays: (state, action) => {
      const { holidays } = action.payload;
      return {
        ...state,
        holidays
      };
    }
  }
});

export const deleteTask = task => async dispatch => {
  try {
    await deleteTaskById(task.id);
    dispatch(removeFromView(task));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const fetchStatistic = (currentDate, userId) => async dispatch => {
  dispatch(resetWorkStatistic());
  try {
    const from = currentDate
      .clone()
      .startOf("month")
      .startOf("isoWeek")
      .startOf("day");
    const to = from
      .clone()
      .add(1, "month")
      .endOf("month")
      .endOf("isoWeek")
      .endOf("day");

    const { data } = await getWorkStatsInTimeSpan(
      from.toISOString(),
      to.toISOString(),
      userId
    );
    dispatch(setWorkStatistic(data));
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
  }
};

export const findProjectsByQuery = (searchQuery: any) => async dispatch => {
  try {
    const { data } = await debouncedFetchProjectsByQuery(searchQuery);

    return data;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));

    return [];
  }
};

export const findRecentProjects = additionalIds => async dispatch => {
  try {
    const { data } = await getRecentProjects(additionalIds);

    return data;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));

    return [];
  }
};

export const fetchTasksAndPutIntoView = (currentDate, userId) => async (
  dispatch,
  getState
) => {
  const company = getState().auth.company;
  const days = Array.from(
    moment
      .range(
        currentDate
          .clone()
          .startOf("year")
          .startOf("isoWeek"),
        currentDate
          .clone()
          .endOf("year")
          .endOf("isoWeek")
      )
      .by("days")
  );

  const from = currentDate
    .clone()
    .startOf("month")
    .startOf("isoWeek")
    .startOf("day");
  const to = from
    .clone()
    .add(1, "month")
    .endOf("month")
    .endOf("isoWeek")
    .endOf("day");

  const workStartsAt = moment(company.workStartsAt, "HH:mm");
  const workEndsAt = moment(company.workEndsAt, "HH:mm").subtract(1, "hour");

  try {
    const { data } = await getTasksByUserId(
      userId,
      from.toISOString(),
      to.toISOString()
    );

    const [earliest, latest] = (data || []).reduce(
      ([prev, next], current) => {
        const currentTime = moment(current.startDate).startOf("hour");
        return [
          prev.hours() < currentTime.hours() ? prev : currentTime,
          next.hours() > currentTime.hours() ? next : currentTime
        ];
      },
      [workStartsAt, workEndsAt]
    );

    dispatch(resetView());
    batch(() => {
      dispatch(
        createMonthView({
          days,
          currentDate,
          start: earliest.format("HH:mm"),
          end: latest.format("HH:mm")
        })
      );
      dispatch(putTaskIntoView(data));
    });
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
  }
};

export const fetchHolidays = (date, projectId) => dispatch => {
  batch(async () => {
    dispatch(resetHolidays());
    try {
      const { data } = await getHolidaysByMonth(
        monthsMapping[date.month()],
        projectId
      );
      dispatch(setHolidays({ holidays: data }));
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  });
};

export const {
  resetView,
  resetHolidays,
  resetWorkStatistic,
  setActiveUser,
  setHolidays,
  createMonthView,
  putTaskIntoView,
  removeFromView,
  setViewState,
  setWorkStatistic
} = calendar.actions;
export default calendar.reducer;
export const holidaysSelector = createSelector(
  state => state.calendar,
  calendar => calendar.holidays || []
);
export const viewStateSelector = createSelector(
  state => state.calendar,
  calendar => calendar.viewState
);
export const tasksSelector = createSelector(
  state => state.calendar.view,
  (state, date) =>
    moment.range(date.clone().startOf("month"), date.clone().endOf("month")),
  (view, range) => {
    if (!Object.keys(view).length) {
      return [];
    }

    const result = [];

    for (const day of range.by("days")) {
      const dayTasks = view[day.format("YYYY-MM-DD")];

      if (dayTasks) {
        result.push(Object.values(dayTasks));
      }
    }

    return [].concat.apply([], result).filter(val => val);
  }
);
