import React, { useState, useEffect, useCallback } from "react";
import { Paper, Popper, Grow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { ClickAwayListener } from "@material-ui/core";
import { bindPopper } from "material-ui-popup-state";
import { TaskCalendarPopperForm } from "./TaskCalendarPopperForm";
import { createSelector } from "reselect";
import {
  closeTaskDialog,
  setWarning,
  TRANSITION_DURATION
} from "../../redux/features/popperTaskFeatureSlice";
import { useTaskDialogState } from "../../hooks/useTaskDialogState";

const useStyles = makeStyles(theme => ({
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid"
    }
  },
  popper: {
    zIndex: 10,
    maxWidth: "350px",
    marginLeft: "7px",
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`
      }
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`
      }
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`
      }
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`
      }
    }
  }
}));

const dialogStateSelector = createSelector(
  state => state.popperTask,
  popperTask => popperTask
);

export const TaskDialogPopper = ({ userId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const popupState = useTaskDialogState();
  const { task, target, isTransiting } = useSelector(dialogStateSelector);
  const [currentTask, setCurrentTask] = useState(undefined);
  const [arrowRef, setArrowRef] = useState(null);
  const [errorClass, setErrorClass] = useState("");
  const [hasError, setError] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const shakeOnError = useCallback(() => {
    setErrorClass("shake-error");
    setError(true);
    setTimeout(() => setErrorClass(""), 500);
  }, []);

  useEffect(() => {
    if (hasChanges) {
      dispatch(setWarning());
    }
  }, [hasChanges]);

  const close = useCallback(() => {
    // popupState.setOpen(false, target);
    setError(false);
    dispatch(closeTaskDialog());
  }, [popupState, target]);

  const handleClickAway = useCallback(() => {
    if (hasChanges) {
      shakeOnError();
      return;
    }

    if (isTransiting) {
      return;
    }

    close();
  }, [hasChanges, isTransiting]);

  useEffect(() => {
    setCurrentTask(task);
    setHasChanges(false);
  }, [task]);

  if (!currentTask) {
    return <></>;
  }

  return (
    <Popper
      {...bindPopper(popupState)}
      placement="right"
      modifiers={{ arrow: { enabled: true, element: arrowRef } }}
      className={classes.popper}
      transition
    >
      {({ TransitionProps }) => (
        <Grow
          {...TransitionProps}
          timeout={TRANSITION_DURATION}
          in={popupState.isOpen}
          mountOnEnter
          unmountOnExit
        >
          <Paper className={errorClass}>
            <span className={classes.arrow} ref={setArrowRef} />
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="popover-body">
                <TaskCalendarPopperForm
                  userId={userId}
                  task={currentTask}
                  hasError={hasError}
                  setHasChanges={setHasChanges}
                  close={close}
                />
              </div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};
