import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem
} from "@material-ui/core";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import zones from "../../utils/timezones";
import filterCRUDValues from "../../utils/filterCRUDValues";
import { Trans, useTranslation } from "react-i18next";
import getTimezoneEntries from "../../utils/getTimezones";

const timezones = getTimezoneEntries();

const UpdateUserSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  position: Yup.string()
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  role: Yup.string().max(255, "Максимальная величина поля 255 символов"),
  password: Yup.string().when("role", {
    is: value => value === "owner" || value === "manager",
    then: Yup.string()
      .min(6, "Пароль должен содержать не менее 6 символов")
      .max(255, "Максимальная величина поля 255 символов"),
    otherwise: Yup.string().notRequired()
  }),
  passwordRepeat: Yup.string().when("password", {
    is: value => value && value.length,
    then: Yup.string()
      .oneOf([Yup.ref("password"), null], "Пароли не совпадают")
      .max(255, "Максимальная величина поля 255 символов")
      .required("Обязательное поле"),
    otherwise: Yup.string().notRequired()
  }),
  email: Yup.string().when("role", {
    is: value => value === "owner" || value === "manager",
    then: Yup.string()
      .email("Неверный email")
      .max(255, "Максимальная величина поля 255 символов")
      .required("Обязательное поле"),
    otherwise: Yup.string().notRequired()
  })
});

export const EditUser = ({
  open,
  user,
  closeModal,
  appToken,
  handleUpdate,
  currentUser,
  handleRemove,
  handleRestore
}) => {
  const { t } = useTranslation();
  const { company } = useSelector(state => state.auth);

  if (!user) {
    return <></>;
  }

  const roles = [
    { value: "employee", name: t("Сотрудник") },
    { value: "manager", name: t("Менеджер WorkApp") },
    { value: "owner", name: t("Владелец") }
  ];

  return (
    <Modal className="modal fade" show={open} onHide={closeModal}>
      <Formik
        initialValues={{
          name: user.name,
          position: user.positionName,
          timeZone: user.timeZone,
          role: user.role.name,
          email: user.email || "",
          password: "",
          passwordRepeat: "",
          checkbox: company.timeZone === user.timeZone
        }}
        validationSchema={UpdateUserSchema}
        onSubmit={async values => {
          const result = await handleUpdate(filterCRUDValues(values));

          if (result) {
            closeModal();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          submitForm
        }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                <Trans>Редактирование сотрудника</Trans>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                noValidate={true}
                autoComplete="new-password"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                {["owner", "root"].includes(currentUser.role.name) &&
                  values.role !== "owner" && (
                    <TextField
                      fullWidth
                      autoComplete="new-password"
                      size="small"
                      select
                      label={t("Роль")}
                      name="role"
                      value={values.role}
                      onChange={handleChange}
                      variant="outlined"
                    >
                      {currentUser.role.name === "owner"
                        ? roles.map(role => (
                            <MenuItem key={role.value} value={role.value}>
                              {role.name}
                            </MenuItem>
                          ))
                        : roles
                            .filter(role => role.value !== "owner")
                            .map(role => (
                              <MenuItem key={role.value} value={role.value}>
                                {role.name}
                              </MenuItem>
                            ))}
                    </TextField>
                  )}

                {values.role !== "employee" && (
                  <>
                    <TextField
                      label={t("Email")}
                      autoComplete="new-password"
                      type="email"
                      margin="normal"
                      className="form-control"
                      size="small"
                      variant="outlined"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && t(errors.email)}
                      error={Boolean(touched.email && errors.email)}
                    />

                    <TextField
                      label={t("Новый пароль")}
                      autoComplete="new-password"
                      type="password"
                      margin="normal"
                      className="form-control"
                      size="small"
                      variant="outlined"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && t(errors.password)}
                      error={Boolean(touched.password && errors.password)}
                    />

                    {values.password.length !== 0 && (
                      <TextField
                        label={t("Повторите новый пароль")}
                        autoComplete="new-password"
                        type="password"
                        margin="normal"
                        className="form-control"
                        size="small"
                        variant="outlined"
                        name="passwordRepeat"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.passwordRepeat}
                        helperText={
                          touched.passwordRepeat && t(errors.passwordRepeat)
                        }
                        error={Boolean(
                          touched.passwordRepeat && errors.passwordRepeat
                        )}
                      />
                    )}
                  </>
                )}

                <TextField
                  label={t("ФИО")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  helperText={touched.name && t(errors.name)}
                  error={Boolean(touched.name && errors.name)}
                />

                <TextField
                  label={t("Должность")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="position"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.position}
                  helperText={touched.position && t(errors.position)}
                  error={Boolean(touched.position && errors.position)}
                />

                <FormControlLabel
                  checked={values.checkbox}
                  labelPlacement="end"
                  name="checkbox"
                  label={t("Часовой пояс сотрудника совпадает с компанией")}
                  control={<Checkbox color="primary" />}
                  value={values.checkbox}
                  onChange={e => {
                    if (e.target.value) {
                      values.timeZone = company.timeZone;
                    }

                    return handleChange(e);
                  }}
                  onBlur={handleBlur}
                />

                {!values.checkbox && (
                  <Autocomplete
                    name="timeZone"
                    getOptionLabel={([, value]) => value}
                    value={[values.timeZone, zones[values.timeZone]]}
                    options={timezones}
                    onChange={(e, value) => {
                      if (Array.isArray(value)) {
                        setFieldValue("timeZone", value[0]);
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        margin="normal"
                        label={t("Часовой пояс")}
                        variant="outlined"
                      />
                    )}
                  />
                )}

                <div className="form-group">
                  <label
                    htmlFor="lkdm_modal_key"
                    className="form-control-label"
                  >
                    <Trans>Ключ авторизации</Trans>:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="token"
                    style={{ backgroundColor: "#f3f3f3" }}
                    readOnly
                    value={appToken}
                  />
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={submitForm}>
                <Trans>Сохранить</Trans>
              </Button>
              {user.status === "active" && user.id !== currentUser.id && (
                <Button variant="warning" onClick={handleRemove}>
                  <Trans>Удалить</Trans>
                </Button>
              )}
              {user.status === "deleted" && user.id !== currentUser.id && (
                <Button variant="warning" onClick={handleRestore}>
                  <Trans>Восстановить</Trans>
                </Button>
              )}
              <Button variant="secondary" onClick={closeModal}>
                <Trans>Закрыть</Trans>
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
