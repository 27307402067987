import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { Button } from "react-bootstrap";

export const ProjectsTableHeader = ({
  mode,
  selected,
  open,
  setProject,
  setMode
}) => {
  const setRegular = useCallback(() => {
    setMode("regular");
  }, []);
  const setMerge = useCallback(() => {
    setMode("merge");
  }, []);
  const handleCreate = useCallback(() => {
    setProject(null);
    open();
  }, []);

  return (
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title">
          <Trans>Проекты</Trans>
        </h3>
      </div>
      <div className="kt-portlet__head-toolbar">
        <div className="kt-portlet__head-actions">
          {selected.length > 1 && (
            <Link
              className="btn btn-outline-primary btn-sm mr-2"
              to={{
                pathname: "/projects/merge",
                state: {
                  selected
                }
              }}
            >
              <i
                className="flaticon2-shrink"
                style={{
                  transform: "rotate(90deg) translateY(4px)",
                  display: "inline-block",
                  paddingRight: 0
                }}
              />
              <Trans>Перейти к объединению</Trans>
            </Link>
          )}
          {mode === "regular" ? (
            <Button
              className="mr-2"
              variant="outline-primary"
              size="sm"
              onClick={setMerge}
            >
              <i className="flaticon2-layers" />
              <Trans>Объединить</Trans>
            </Button>
          ) : (
            <Button
              className="mr-2"
              variant="outline-warning"
              size="sm"
              onClick={setRegular}
            >
              <i className="flaticon2-cross" />
              <Trans>Отменить объединение</Trans>
            </Button>
          )}
          <Button variant="outline-success" size="sm" onClick={handleCreate}>
            <i className="flaticon2-plus" />
            <Trans>Добавить</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};
