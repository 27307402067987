import { getHours, getMinutes } from "../../../utils/getTime";
import React from "react";
import { Hint } from "../../Hint";
import { Link } from "react-router-dom";

export const ProjectDaySummary = ({
  summary,
  hintText,
  HintComponent,
  placement = "left"
}) => {
  if (!summary) {
    return null;
  }

  return (
    <div className="kt-widget4">
      {summary.map(({ user, plan, fact, spent, descriptions }) => (
        <div key={user.id} className="kt-widget4__item">
          <div className="kt-widget4__info text-truncate">
            <Link
              className="kt-widget4__username text-truncate"
              to={`/calendar/${user.id}`}
            >
              {user.name}
            </Link>
            <p className="kt-widget4__text text-truncate">
              {user.positionName}
            </p>
          </div>
          <Hint
            text={
              HintComponent ? (
                <HintComponent {...{ user, plan, fact, spent, descriptions }} />
              ) : (
                hintText
              )
            }
            placement={placement}
            show={!!HintComponent || !!hintText}
          >
            <span className={`btn btn-label-success btn-sm btn-bold btn-upper`}>
              {getHours(spent)} ч. {getMinutes(spent)} м.
            </span>
          </Hint>
        </div>
      ))}
    </div>
  );
};
