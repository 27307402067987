import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { NewLogin } from "../pages/auth/NewLogin";
import { NewValidateEmail } from "../pages/auth/NewValidateEmail";
import { NewBootstrapCompany } from "../pages/auth/NewBootstrapCompany";
import { NewRegistration } from "../pages/auth/NewRegistration";
import { NewResetPassword } from "../pages/auth/NewResetPassword";
import { NewForgotPassword } from "../pages/auth/NewForgotPassword";

export default function Auth({ authInfo }) {
  const { path } = useRouteMatch();
  const isAuthenticated = authInfo.user && authInfo.user.status === "active";
  return (
    <Switch>
      {isAuthenticated && <Redirect to="/" />}
      <Route path={`${path}/company`} component={NewBootstrapCompany} />
      <Route path={`${path}/validate-email`} component={NewValidateEmail} />
      <Route path={`${path}/login`} component={NewLogin} />
      <Route path={`${path}/register`} component={NewRegistration} />
      <Route path={`${path}/forgot-password`} component={NewForgotPassword} />
      <Route path={`${path}/reset-password`} component={NewResetPassword} />
      <Redirect to={`${path}/login`} />
    </Switch>
  );
}
