import { Trans, useTranslation } from "react-i18next";
import moment from "moment";
import { ProjectSelectInput } from "../inputs/ProjectSelectInput";
import { TextField, Zoom } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Button } from "react-bootstrap";
import React, { memo, useEffect, useMemo } from "react";
import { useSearchState } from "../../hooks/useSearchState";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import { isEqual } from "lodash";
import { useTaskCRUD } from "../../hooks/useTaskCRUD";
import { TaskHistory } from "./TaskHistory";

const useStyles = makeStyles(theme => ({
  popoverRow: {
    backgroundColor: "#18a899",
    height: 35,
    width: "350px",
    position: "relative",
    left: -7,
    top: -14,
    padding: "0px 15px 0px 10px",
    display: "flex",
    alignItems: "center"
  },
  popoverTitle: {
    color: "white",
    marginLeft: "auto",
    fontSize: "1.15rem",
    fontWeight: 500
  },
  popoverClose: {
    color: "white",
    cursor: "pointer",
    marginLeft: "auto"
  },
  popoverItemHeader: {
    fontWeight: 500
  }
}));

const createArrayValidator = otherProp => {
  return Yup.array()
    .when(otherProp, (prop, schema) =>
      Array.isArray(prop) && prop.length
        ? schema
        : schema
            .required("Обязательное поле")
            .min(1, "Необходимо выбрать или добавить хотя-бы один проект")
    )
    .ensure()
    .of(
      Yup.string().max(
        255,
        "Максимальная длина названия проекта - 255 символов"
      )
    );
};

const TaskValidationSchema = Yup.object().shape(
  {
    projectIds: createArrayValidator("newProjects"),
    newProjects: createArrayValidator("projectIds")
  },
  [["newProjects", "projectIds"]]
);

const toZonedTime = string =>
  moment.utc(string, "YYYY-MM-DD HH:mm:ss.SSSSSS").format();

const Component = ({ task, userId, close, setHasChanges, hasError }) => {
  const classes = useStyles();
  const date = moment(task.startDate);
  const { t } = useTranslation();
  const projectIds = useMemo(
    () => (task.id ? task.projects.map(p => p.id) : task.projectIds),
    [task]
  );
  const InputLabelProps = useMemo(
    () => ({
      shrink: true
    }),
    []
  );
  const searchProps = useSearchState({
    projectIds,
    searchQuery: task.projectText || ""
  });
  const initialValues = useMemo(
    () => ({
      projectIds: projectIds,
      newProjects: [],
      description: task.description
    }),
    [task]
  );
  const { createHandler, updateHandler, removeHandler } = useTaskCRUD({
    userId,
    task,
    close
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: TaskValidationSchema,
    onSubmit: task.id !== undefined ? updateHandler : createHandler
  });

  useEffect(() => {
    setHasChanges(!isEqual(initialValues, values));
  }, [values]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={`row ${classes.popoverRow}`}>
        <span className={classes.popoverTitle}>
          {date.format("DD.MM.YYYY")} ({date.format("HH:mm")} -{" "}
          {date
            .clone()
            .endOf("hour")
            .format("HH:mm")}
          )
        </span>
        <i onClick={close} className={`fa fa-times ${classes.popoverClose}`} />
      </div>
      <div className="event_update">
        <div className="row">
          <div className="col-md-6 text-center">
            <span className={classes.popoverItemHeader}>
              <Trans>Время заполнения</Trans>
            </span>{" "}
            <p>
              {task.createdAt
                ? moment(task.createdAt).format("DD.MM.YYYY HH:mm")
                : null}
            </p>
          </div>
          <div className="col-md-6 text-center">
            <span className={classes.popoverItemHeader}>
              <Trans>Последняя ред.</Trans>
            </span>{" "}
            <p>
              {task.version
                ? moment(toZonedTime(task.version)).format("DD.MM.YYYY HH:mm")
                : null}
            </p>
          </div>
          <br />
          <br />
          <div className="col-md-12" style={{ marginBottom: 10 }}>
            <div className="form-group mb-5">
              <ProjectSelectInput
                label={t("Проект")}
                margin="normal"
                size="small"
                variant="outlined"
                clearText="Очистить"
                closeText="Закрыть"
                noOptionsText="Проекты не найдены"
                searchProps={searchProps}
                handleChange={handleChange}
                readonly={!!task.projectText}
                helperText={
                  (touched.projectIds || touched.newProjects) &&
                  t(errors.projectIds || errors.newProjects)
                }
                error={Boolean(
                  (touched.projectIds && errors.projectIds) ||
                    (errors.newProjects && touched.newProjects)
                )}
                InputLabelProps={InputLabelProps}
              />
              <TextField
                multiline
                rows={3}
                type="text"
                label={t("Задача")}
                margin="normal"
                className="form-control"
                size="small"
                variant="outlined"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                InputProps={{ readOnly: !!task.projectText }}
                helperText={touched.description && t(errors.description)}
                error={Boolean(touched.description && errors.description)}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </div>
          </div>
          <div className="col-md-12">
            <TaskHistory task={task} />
          </div>
          <div className="col-md-12" style={{ marginBottom: 10 }}>
            {hasError && (
              <Zoom in={hasError}>
                <Alert severity="warning">
                  <AlertTitle>Изменения не сохранены</AlertTitle>
                  Чтобы закрыть окно с <strong>потерей данных</strong> - нажмите
                  кнопку "Закрыть"
                </Alert>
              </Zoom>
            )}
          </div>
          <div className="col-md-12 text-center">
            {!task.projectText && (
              <button
                style={{ marginRight: 5 }}
                type="submit"
                className="btn btn-success"
              >
                <Trans>Сохранить</Trans>
              </button>
            )}
            {task.id && (
              <button
                style={{ marginRight: 5 }}
                type="button"
                onClick={removeHandler}
                className="btn btn-danger"
              >
                <Trans>Удалить</Trans>
              </button>
            )}
            <Button variant="secondary" onClick={close}>
              <Trans>Закрыть</Trans>
            </Button>
            <br />
            <br />
          </div>
        </div>
      </div>
    </form>
  );
};

export const TaskCalendarPopperForm = memo(Component);
