import SettingsUsersHeader from "./SettingsUsersHeader";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditUser } from "../../forms/EditUser";
import { getUsersList, onReordering } from "../../../redux/thunks/user.thunk";
import { AddUser } from "../../forms/AddUser";
import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { reorderUsers } from "../../../redux/features/settingsFeatureSlice";
import { useUserCRUD } from "../../../hooks/useUserCRUD";
import { UserItem } from "./UserItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { MenuItem, TextField } from "@material-ui/core";
import Fuse from "fuse.js";
import { intersectionBy } from "lodash";
import { Trans } from "react-i18next";

const options = {
  includeScore: true,
  threshold: 0.3,
  keys: ["name", "positionName"]
};

const usersSelector = createSelector(
  state => state.auth.user,
  state => state.settings.users,
  (state, query, status) =>
    status.length && status !== "all"
      ? state.settings.users.filter(usr => usr.status === status)
      : state.settings.users,
  (state, query) => {
    const fuse = new Fuse(state.settings.users, options);
    return fuse.search(query).map(({ item }) => item);
  },
  (user, users, statusFiltered, filtered) => {
    return {
      user,
      users,
      filtered: filtered.length
        ? intersectionBy(statusFiltered, filtered, "id")
        : statusFiltered
    };
  }
);

export const SortableUsers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [status, setStatus] = useState("active");
  const [appToken, setAppToken] = useState(undefined);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [editModalState, setEditModalState] = useState(false);
  const [addModalState, setAddModalState] = useState(false);
  const refresh = useCallback(() => dispatch(getUsersList()), []);
  const { user, users, filtered } = useSelector(state =>
    usersSelector(state, query, status)
  );

  useEffect(() => {
    dispatch(getUsersList());
  }, []);

  const onDragEnd = useCallback(
    result => {
      const { destination, source, reason } = result;
      if (!destination || reason === "CANCEL") {
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }

      dispatch(reorderUsers({ from: source.index, to: destination.index }));
      dispatch(onReordering());
    },
    [users]
  );

  const {
    createHandleRemove,
    createHandleRestore,
    createHandleUpdate,
    createOnClickHandler,
    handleAdd
  } = useUserCRUD({
    refresh,
    setAddModalState,
    setAppToken,
    setEditModalState,
    setSelectedUser
  });

  return (
    <div className="kt-portlet kt-portlet-" style={{ maxHeight: "100vh" }}>
      <SettingsUsersHeader handleAddUser={() => setAddModalState(true)} />
      <div className="kt-portlet__body pt-0" style={{ overflowY: "scroll" }}>
        <div className="row">
          <div className="col-md-6">
            <TextField
              onChange={e => setQuery(e.target.value)}
              value={query}
              label={t("Поиск")}
              placeholder={t("Введите имя сотрудника")}
              margin="normal"
              className="search-bar form-control align-self-start"
              size="small"
              variant="outlined"
            />
          </div>
          <div className="col-md-6">
            <TextField
              fullWidth
              size="small"
              margin="normal"
              autoComplete="new-password"
              select
              label={t("Статус")}
              name="role"
              value={status}
              onChange={event => setStatus(event.target.value)}
              variant="outlined"
            >
              <MenuItem value="all">Все</MenuItem>
              <MenuItem selected value="active">
                <Trans>Активные</Trans>
              </MenuItem>
              <MenuItem value="deleted">
                <Trans>Уволенные</Trans>
              </MenuItem>
            </TextField>
          </div>
        </div>

        {!!users.length && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="dorppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className="kt-notification-v2"
                  {...provided.droppableProps}
                  style={{
                    ...provided.droppableProps.style,
                    paddingBottom: "1rem"
                  }}
                >
                  {users.map((user, index) => (
                    <UserItem
                      key={user.id}
                      isDraggable={users.length === filtered.length}
                      filtered={filtered}
                      index={index}
                      user={user}
                      onClick={createOnClickHandler(user)}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {!users.length &&
          t(
            'Список сотрудников пуст. Добавьте сотрудников, нажав на кнопку "Добавить"'
          )}
      </div>

      <EditUser
        currentUser={user}
        open={editModalState}
        user={selectedUser}
        appToken={appToken}
        closeModal={() => setEditModalState(false)}
        handleUpdate={createHandleUpdate(selectedUser)}
        handleRemove={createHandleRemove(selectedUser)}
        handleRestore={createHandleRestore(selectedUser)}
      />

      <AddUser
        open={addModalState}
        closeModal={() => setAddModalState(false)}
        handleAdd={handleAdd}
        currentUser={user}
      />
    </div>
  );
};
