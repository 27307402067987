import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { TooltipWithBounds, withTooltip } from "@vx/tooltip";
import { Group } from "@vx/group";
import { Bar, Line, Circle } from "@vx/shape";
import { Text } from "@vx/text";
import { AxisBottom } from "@vx/axis";
import { PatternLines } from "@vx/pattern";
import { scaleOrdinal, scaleTime } from "@vx/scale";
import { localPoint } from "@vx/event";
import { Legend, LegendLabel, LegendItem } from "@vx/legend";
import { ParentSize } from "@vx/responsive";
import { getTaskSpan } from "../../crud/calendar.crud";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";

const max = (arr, fn) => Math.max(...arr.map(fn));
const min = (arr, fn) => Math.min(...arr.map(fn));

const splitTime = time => {
  const localDate = moment(moment.utc(time, "HH:mm").toDate());
  const [hours, minutes] = localDate.format("HH:mm").split(":");

  return { hours: parseInt(hours), minutes: parseInt(minutes) };
};

const spanSetter = (result, workDay) => () => {
  let start, end;
  const from = moment(workDay.from);
  const to = moment(workDay.to);
  if (
    result.start &&
    from
      .clone()
      .set(splitTime(result.start))
      .utc()
      .isBefore(from)
  ) {
    start = result.start;
  } else {
    start = from
      .clone()
      .utc()
      .format("HH:mm");
  }
  if (
    result.end &&
    to
      .clone()
      .set(splitTime(result.end))
      .utc()
      .isAfter(to)
  ) {
    end = result.end;
  } else {
    end = to
      .clone()
      .utc()
      .format("HH:mm");
  }

  return {
    start,
    end
  };
};

const typeSort = (a, b) => {
  if (a.type === b.type) {
    return 0;
  }

  if (a.type === "plan") {
    return -1;
  }

  if (a.type === "empty") {
    return 1;
  }

  if (a.type === "fill-span" && b.type === "empty") {
    return -1;
  }

  return 1;
};

const getBarConfig = (key, height) => {
  const barHeight = height - height / 3 + 10;
  const diff = barHeight * 0.2;
  switch (key) {
    case "plan":
      return {
        fill: "url(#dLines)",
        barHeight: barHeight,
        y: 0
      };
    case "fill-span":
      return { fill: "#ffb200", barHeight: barHeight - diff, y: diff };
    case "empty":
      return {
        fill: "#ff293a",
        barHeight: barHeight - diff / 1.3,
        y: diff / 1.3
      };

    default:
      return { fill: "yellow", barHeight, y: 10 };
  }
};

const scaleTypes = scaleOrdinal({
  domain: ["plan", "fill-span", "empty"],
  range: [
    <Circle cx="15" cy="15" r="15" fill="url(#dLinesMini)" />,
    <Circle cx="15" cy="15" r="15" fill="#ffb200" />,
    <Circle cx="15" cy="15" r="15" fill="#ff293a" />
  ]
});

const getNameFromType = type => {
  switch (type) {
    case "plan":
      return i18n.t("Рабочий день");
    case "fill-span":
      return i18n.t("Рабочий процесс");
    case "empty":
      return i18n.t("Пропуск");

    default:
      return "";
  }
};

const getWorkMonthSafe = workday => {
  if (!workday) {
    return undefined;
  }

  return moment(workday.from).month();
};

const Component = ({
  data,
  userId,
  tooltipData,
  tooltipLeft,
  tooltipTop,
  tooltipOpen,
  hideTooltip,
  showTooltip
}) => {
  const margin = {
    top: 20,
    left: 50,
    right: 10,
    bottom: 0
  };

  return (
    <div className="row row-no-padding">
      <div className="col-lg-2 col-12 d-flex flex-lg-column flex-md-row justify-content-center">
        <LegendBlock />
      </div>
      <div className="col-lg-10 col-12">
        <ParentSize>
          {({ width }) => (
            <Chart
              data={data}
              userId={userId}
              height={width / 4}
              width={width}
              margin={margin}
              showTooltip={showTooltip}
              hideTooltip={hideTooltip}
            />
          )}
        </ParentSize>
        {tooltipOpen && (
          <TooltipWithBounds
            // set this to random so it correctly updates with parent bounds
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
          >
            <div style={{ width: "150px" }}>
              <div className="row">
                <div
                  className="col-12"
                  style={{ textAlign: "center", marginBottom: "1em" }}
                >
                  <strong>{getNameFromType(tooltipData.type)}</strong>
                </div>
                <div className="col-12">
                  <Trans>Начало</Trans>:{" "}
                  <strong>{moment(tooltipData.from).format("HH:mm")}</strong>
                </div>
                <div className="col-12">
                  <Trans>Конец</Trans>:{" "}
                  <strong>{moment(tooltipData.to).format("HH:mm")}</strong>
                </div>
              </div>
            </div>
          </TooltipWithBounds>
        )}
      </div>
    </div>
  );
};

const LegendBlock = () => {
  return (
    <Legend scale={scaleTypes}>
      {labels => {
        return labels.map((label, i) => {
          const shape = scaleTypes(label.datum);
          const size = 30;
          return (
            <LegendItem
              className="m-3"
              key={`legend-quantile-${i}`}
              flexDirection="row"
            >
              <LegendLabel
                align={"right"}
                margin={0}
                className="text-lg-right text-center"
              >
                {getNameFromType(label.text)}
              </LegendLabel>
              <svg width={size} height={size} style={{ marginLeft: "8px" }}>
                {React.cloneElement(shape)}
              </svg>
            </LegendItem>
          );
        });
      }}
    </Legend>
  );
};

const Chart = ({
  data,
  userId,
  height,
  width,
  margin,
  showTooltip,
  hideTooltip
}) => {
  const handleMouseOverBar = useCallback(
    (event, datum) => {
      const coords = localPoint(event.target.ownerSVGElement, event);
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: datum
      });
    },
    [showTooltip]
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const groupMargin = 30;
  const yMax = height - height / 3 + 10;
  const workDay = data.find(d => d.type === "plan");

  const [{ start, end }, setSpan] = useState({
    start: undefined,
    end: undefined
  });

  useEffect(() => {
    (async () => {
      if (workDay) {
        try {
          const { data: result } = await getTaskSpan(
            userId,
            moment(workDay.from)
              .startOf("month")
              .startOf("day")
              .toISOString(true),
            moment(workDay.to)
              .endOf("month")
              .endOf("day")
              .toISOString(true)
          );
          setSpan(spanSetter(result, workDay));
        } catch (e) {
          const { header, message } = extractErrorInfo(e);
          dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
        }
      }
    })();
  }, [dispatch, userId, getWorkMonthSafe(workDay)]);

  if (!workDay || !start || !end) {
    return <></>;
  }

  const xScale = scaleTime({
    domain: [
      moment(workDay.from)
        .set(splitTime(start))
        .subtract(1, "hours"),
      moment(workDay.to)
        .set(splitTime(end))
        .add(2, "hours")
    ],
    nice: true
  });
  xScale.rangeRound([0, width - margin.left * 2]);

  const workStart = xScale(moment(workDay.from).toDate());
  const workEnd = xScale(moment(workDay.to).toDate());
  const lineHeight = height - height / 3 + 10;
  const canvasHeight = height + groupMargin + margin.top + 20;
  return (
    <svg width={width} height={canvasHeight > 370 ? 370 : canvasHeight}>
      <rect
        x={0}
        y={0}
        width={width}
        height={height + groupMargin + margin.top + 20}
        fill="white"
      />
      <PatternLines
        id="dLines"
        height={30}
        width={30}
        stroke="#dcdcdc"
        strokeWidth={5}
        orientation={["diagonal"]}
      />
      <PatternLines
        id="dLinesMini"
        height={10}
        width={10}
        stroke="#dcdcdc"
        background="#eeeeee"
        strokeWidth={2}
        orientation={["diagonal"]}
      />
      <Group top={margin.top} left={margin.left}>
        <rect
          x={0}
          y={groupMargin}
          width={width - margin.left * 2}
          height={yMax}
          fill="#eeeeee"
        />
        {data &&
          data.sort(typeSort).map((d, i) => {
            const start = xScale(moment(d.from).toDate());
            const end = xScale(moment(d.to).toDate());
            const { barHeight, fill, y } = getBarConfig(d.type, height);
            return (
              <Bar
                key={`bar-${i}`}
                x={start}
                y={y + groupMargin}
                width={end - start}
                height={barHeight}
                fill={fill}
                onMouseMove={e => handleMouseOverBar(e, d)}
                onMouseOut={hideTooltip}
                rx={4}
              />
            );
          })}
        <Line
          from={{ x: workStart, y: groupMargin - 20 }}
          to={{ x: workStart, y: lineHeight + groupMargin }}
          stroke="black"
          strokeWidth="1px"
          opacity={1}
        />
        <Line
          from={{ x: workEnd, y: groupMargin - 20 }}
          to={{ x: workEnd, y: lineHeight + groupMargin }}
          stroke="black"
          strokeWidth="1px"
          opacity={1}
        />
        <Text textAnchor="middle" x={workStart} y={groupMargin - 25}>
          {t("Начало рабочего дня")}
        </Text>
        <Text textAnchor="middle" x={workEnd} y={groupMargin - 25}>
          {t("Конец рабочего дня")}
        </Text>
        <AxisBottom
          top={yMax + groupMargin}
          scale={xScale}
          stroke="black"
          tickStroke="black"
          tickFormat={date => moment(date).format("HH:mm")}
          tickLabelProps={(value, index) => ({
            fill: "#000000",
            x: 0,
            y: 0,
            transform: `translate(${xScale(value)}, 19) rotate(45)`,
            fontSize: 11,
            textAnchor: "middle",
            dy: "0.33em"
          })}
        />
      </Group>
    </svg>
  );
};

export const WorkStatistic = withTooltip(Component);
