import { Grid, Switch, Typography, withStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { setViewState } from "../../redux/features/calendarFeatureSlice";
import { Trans } from "react-i18next";

const ViewSwitch = withStyles({
  switchBase: {
    color: "#000000",
    "&$checked": {
      color: "#ffb200 !important"
    },
    "&$checked + $track": {
      backgroundColor: "rgba(255,156,0,0.85) !important"
    }
  },
  checked: {},
  track: {}
})(Switch);

export const TaskViewSwitcher = () => {
  const dispatch = useDispatch();
  const [switchState, setSwitchState] = useState(false);
  const onChange = useCallback(() => {
    setSwitchState(!switchState);
    dispatch(setViewState(!switchState));
  }, [switchState]);
  return (
    <Typography component="div" className="form-group">
      <Grid
        component="label"
        container
        alignItems="center"
        justify="center"
        spacing={1}
      >
        <Grid item>
          <Trans>Задачи</Trans>
        </Grid>
        <Grid item>
          <ViewSwitch checked={switchState} onChange={onChange} />
        </Grid>
        <Grid item>
          <Trans>Статистика</Trans>
        </Grid>
      </Grid>
    </Typography>
  );
};
