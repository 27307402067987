import axios from "axios";
import { PAYMENT_URL, SUBSCRIPTION_URL } from "../constants/api";

export const recalculateAmounts = (slots, months) => {
  return axios.post(`${PAYMENT_URL}/get-amount`, { slots, monthCount: months });
};

export const createPayment = (slots, months, type) => {
  return axios.post(`${PAYMENT_URL}/create`, {
    slots,
    monthCount: months,
    type
  });
};

export const getSubscription = () => {
  return axios.get(`${SUBSCRIPTION_URL}/info`);
};
