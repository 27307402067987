import React from "react";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/styles";
import { Formik } from "formik";
import { Fade, TextField } from "@material-ui/core";
import { updateUserById } from "../../../crud/settings.crud";
import { updateUserInfo } from "../../../redux/features/authFeatureSlice";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/features/snackbarFeatureSlice";
import { extractErrorInfo } from "../../../utils/extractError";
import { Trans, useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  btn: {
    display: "flex",
    justifyContent: "space-between",
    width: 230,
    alignItems: "center"
  }
}));

export default function SettingsMainParamsForm({ user, handlePasswordChange }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  async function updateUserDetails(values) {
    try {
      const { data } = await updateUserById(user.id, values);
      dispatch(updateUserInfo({ user: data }));
      dispatch(openSnackbar(Fade, t("Информация обновлена"), "success"));
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  }
  return (
    <div className="kt-portlet__body">
      <Formik
        initialValues={{
          name: user.name || "",
          email: user.email || "",
          positionName: user.positionName || ""
        }}
        onSubmit={async values => {
          await updateUserDetails(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form
            noValidate={true}
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit}
          >
            <TextField
              label={t("Email")}
              type="email"
              margin="normal"
              className="form-control"
              size="small"
              variant="outlined"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              helperText={touched.email && t(errors.email)}
              error={Boolean(touched.email && errors.email)}
            />

            <TextField
              label={t("Ваше имя")}
              type="text"
              margin="normal"
              className="form-control"
              size="small"
              variant="outlined"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              helperText={touched.name && t(errors.name)}
              error={Boolean(touched.name && errors.name)}
            />

            <TextField
              label={t("Должность")}
              type="text"
              margin="normal"
              className="form-control"
              size="small"
              variant="outlined"
              name="positionName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.positionName}
              helperText={touched.positionName && t(errors.positionName)}
              error={Boolean(touched.positionName && errors.positionName)}
            />

            <div className={classes.btn}>
              <Button variant="success" type="submit">
                <Trans>Обновить</Trans>
              </Button>
              <Button variant="warning" onClick={handlePasswordChange}>
                <Trans>Изменить пароль</Trans>
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
