import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import * as Sentry from "@sentry/browser";
import store, { persistor } from "./app/redux/store";
import App from "./App";
import "./index.scss";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
import "./_metronic/_assets/plugins/flaticon/flaticon.css";
import "./_metronic/_assets/plugins/flaticon2/flaticon.css";
import { version } from "../package.json";
import { init } from "./app/i18n/i18n";

/**
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL, NODE_ENV } = process.env;

// if (NODE_ENV !== "development") {
//   const release = `workapp-frontend@${version}${
//     process.env.REACT_APP_ENV === "staging" ? "-staging" : ""
//   }`;
//   Sentry.init({
//     dsn: "https://1cdec8dad71140d19d126439e4ef48ac@ouroboros.linkodium.com/2",
//     release
//   });
//
//   let userIdWasSet = false;
//
//   store.subscribe(() => {
//     const { user } = store.getState().auth;
//
//     if (user && user.id && !userIdWasSet) {
//       userIdWasSet = true;
//       Sentry.configureScope(scope =>
//         scope.setUser({ id: user.id, username: user.name, email: user.email })
//       );
//     }
//
//     if (!user) {
//       userIdWasSet = false;
//       Sentry.configureScope(scope => scope.setUser(null));
//     }
//   });
// }

const Layout = React.lazy(() => import("./_metronic/layout/Layout.js"));
moment.locale("ru", {
  monthsShort: [
    "Дек.",
    "Янв.",
    "Фев.",
    "Март",
    "Апр.",
    "Май",
    "Июнь ",
    "Июль",
    "Авг",
    "Сен.",
    "Окт.",
    "Ноя."
  ],
  week: {
    dow: 1
  }
});

init().then(async () => {
  ReactDOM.render(
    <App
      store={store}
      Layout={Layout}
      persistor={persistor}
      basename={PUBLIC_URL}
    />,
    document.getElementById("root")
  );
});
