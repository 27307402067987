import React, { useState } from "react";
import "../../../_metronic/_assets/sass/pages/login/login-6.scss";
import { register } from "../../crud/auth.crud";
import { Alert } from "react-bootstrap";
import FormRegistration from "../../components/forms/FormRegistration";
import { extractErrorInfo } from "../../utils/extractError";
import { Trans, useTranslation } from "react-i18next";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AuthLayout, IntroduceMessage } from "../../components/auth/Container";

const reachGoals = () => {
  try {
    if (window.yaCounter60330370) {
      window.yaCounter60330370.reachGoal("ja_1");
    }

    if (window.ga) {
      window.ga("send", "event", "cat", "ja_1");
    }
  } catch (e) {
    // Sentry.captureException(e);
  }
};

const RegistrationForm = () => {
  const location = useLocation().pathname.split(/[?#]/)[0];
  const goTo = location === "/auth/register" ? "/auth/login" : "/auth/register";
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const onSubmitRegistration = async (
    { name, email, password, repeatedPassword },
    setSubmitting
  ) => {
    try {
      const { data } = await register(
        name,
        email,
        password,
        repeatedPassword,
        lang
      );
      reachGoals();
      disableLoading();
      setSubmitting(false);
      setType("success");
      setMessage(
        t("Письмо для подтверждения отправлено на почту: {{email}}", {
          email: data.email
        })
      );
    } catch (e) {
      disableLoading();
      setSubmitting(false);
      setType("danger");
      const { header, message } = extractErrorInfo(e);
      setMessage(`${header}. ${message || ""}`);
    }
  };

  return (
    <>
      <a href="#" className="text-center pt-2">
        <img
          src="/media/auth/logo.png"
          style={{ maxHeight: "90px", maxWidth: "90px" }}
          alt=""
        />
      </a>
      <div className="d-flex flex-column-fluid flex-column flex-center">
        <div className="login-form login-signin py-11">
          {message.length ? <Alert variant={type}>{message}</Alert> : null}

          <div className="text-center pb-8">
            <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
              <Trans>Регистрация аккаунта</Trans>
            </h2>
          </div>

          <FormRegistration
            loadingButtonStyle={loadingButtonStyle}
            loading={loading}
            enableLoading={enableLoading}
            onSubmitRegistration={onSubmitRegistration}
          />

          <center>
            <span>
              <Trans>Уже есть аккаунт?</Trans>{" "}
              <Link
                to={goTo}
                className="text-primary font-weight-bolder"
                id="kt_login_signup"
              >
                <Trans>Войти</Trans>
              </Link>
            </span>
          </center>
        </div>
      </div>
      <center>
        <a href="https://linkodium.com" target="_blank">
          Linkodium
        </a>{" "}
        (2020)
      </center>
    </>
  );
};

export const NewRegistration = () => {
  return (
    <AuthLayout left={<RegistrationForm />} right={<IntroduceMessage />} />
  );
};
