import { useSelector } from "react-redux";
import {
  holidaysSelector,
  viewStateSelector
} from "../redux/features/calendarFeatureSlice";
import { TaskCalendarDay } from "../components/task/TaskCalendarDay";
import { TaskCalendarDayStatistic } from "../components/task/TaskCalendarDayStatistic";
import React, { useMemo } from "react";
import moment from "moment";

export const useTaskDaysList = ({ userId, date, days }) => {
  const holidays = useSelector(holidaysSelector);
  const viewState = useSelector(viewStateSelector);
  const Component =
    viewState === "task" ? TaskCalendarDay : TaskCalendarDayStatistic;

  return useMemo(
    () =>
      days.map((calendarDay, index) => {
        const currentDay = (holidays || []).find(holiday =>
          moment(holiday.date).isSame(calendarDay, "day")
        );

        const isHoliday = (currentDay && currentDay.isHoliday) || false;

        return (
          <Component
            key={index}
            date={date}
            calendarDay={calendarDay}
            isHoliday={isHoliday}
            userId={userId}
          />
        );
      }),
    [
      Component,
      userId,
      viewState,
      holidays,
      date.format(),
      JSON.stringify(days)
    ]
  );
};
