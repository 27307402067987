import React from "react";
import { Route, Switch } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { Registrations } from "../pages/admin/Registrations";
import { Invoices } from "../pages/admin/Invoices";
import { Promocodes } from "../pages/admin/Promocodes";

export const AdminRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/registrations`} component={Registrations} />
      <Route path={`${path}/invoices`} component={Invoices} />
      <Route path={`${path}/promocodes`} component={Promocodes} />
    </Switch>
  );
};
