import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ruTranslations from "./ru/translations.json";
import enTranslations from "./en/translations.json";

// noinspection NonAsciiCharacters
export const init = async () =>
  await i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      // we init with resources
      resources: {
        ru: {
          translations: ruTranslations
        },
        en: {
          translations: enTranslations
        }
      },
      fallbackLng: "en",
      // debug: process.env.NODE_ENV === "development",

      // have a common namespace used around the full app
      ns: ["translations"],
      defaultNS: "translations",

      // react: {
      //   bindI18n: "languageChanged"
      // },

      keySeparator: false, // we use content as keys
      nsSeparator: false, // namespace separator

      interpolation: {
        escapeValue: false
      }
    });

export default i18n;
export const { t } = i18n;
