import { createSlice } from "@reduxjs/toolkit";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "./snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { disableTutorialByUserId } from "../../crud/settings.crud";
import { setTutorialDisabled } from "./authFeatureSlice";

const initialState = {
  open: false
};

const tutorial = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    openModal: state => {
      state.open = true;
    },
    closeModal: state => {
      state.open = false;
    }
  }
});

export const shouldOpenTutorial = () => (dispatch, getState) => {
  const user = getState().auth.user;

  if (!user.disableTutorial) {
    dispatch(openModal());
  }
};

export const disableTutorial = () => async (dispatch, getState) => {
  const user = getState().auth.user;
  try {
    await disableTutorialByUserId(user.id);
    dispatch(setTutorialDisabled());
    dispatch(closeModal());
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export default tutorial.reducer;
export const { openModal, closeModal } = tutorial.actions;
