import React from "react";
import { InvoicesTable } from "../../components/tables/InvoicesTable";

export const Invoices = () => {
  return (
    <div className="row">
      <div className="col-12">
        <InvoicesTable />
      </div>
    </div>
  );
};
