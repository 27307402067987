import React from "react";
import clsx from "clsx";

export default function CalendarContainer({ singleView, children }) {
  return (
    <div className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch">
      <div
        className={clsx(
          "kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor",
          {
            "p-4": singleView
          }
        )}
      >
        <br />
        <div
          className={clsx("kt-grid__item kt-grid__item--fluid", {
            "kt-container": !singleView,
            "kt-container--fluid": singleView
          })}
        >
          <div className="row">{children}</div>
        </div>
      </div>
    </div>
  );
}
