import React, { useState } from "react";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent
} from "@material-ui/core";
import { SubscriptionConfiguration } from "./SubscriptionConfiguration";
import { PaymentMethods } from "./PaymentMethods";
import { YandexPayment } from "./YandexPayment";
import { InvoicePayment } from "./InvoicePayment";
import { useSelector } from "react-redux";
import i18n from "../../../i18n/i18n";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps() {
  return [
    i18n.t("Настройте вашу подписку"),
    i18n.t("Выберите способ оплаты"),
    i18n.t("Оплатите подписку")
  ];
}

function getStepContent(step, type) {
  switch (step) {
    case 0:
      return <SubscriptionConfiguration />;
    case 1:
      return <PaymentMethods />;
    case 2:
      if (type === "yandex") {
        return <YandexPayment />;
      } else {
        return <InvoicePayment />;
      }

    default:
      return <SubscriptionConfiguration />;
  }
}

export const SubscriptionWizard = ({ subscription, setModalState }) => {
  const email = useSelector(state => state.auth.user.email);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [slots, setSlots] = useState(
    Math.max(subscription.slots, subscription.used)
  );
  const [months, setMonths] = useState(1);
  const [type, setType] = useState(undefined);
  const [total, setTotal] = useState(undefined);
  const [paidUntil, setPaidUntil] = useState(undefined);
  const [paymentId, setPaymentId] = useState(undefined);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      {steps.map((label, index) => (
        <Step key={label}>
          <StepLabel>{label}</StepLabel>
          <StepContent>
            {{
              ...getStepContent(index, type),
              ...{
                props: {
                  email,
                  slots,
                  months,
                  type,
                  total,
                  paymentId,
                  paidUntil,
                  setSlots,
                  setMonths,
                  setType,
                  setTotal,
                  setPaidUntil,
                  setPaymentId,
                  handleNext,
                  handleBack,
                  setModalState,
                  classes
                }
              }
            }}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};
