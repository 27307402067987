import { useDispatch, useSelector } from "react-redux";
import {
  fetchHolidays,
  fetchStatistic,
  fetchTasksAndPutIntoView,
  resetView,
  setViewState,
  viewStateSelector
} from "../redux/features/calendarFeatureSlice";
import { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "./usePrevious";
import { getUsersList } from "../redux/thunks/user.thunk";
import { userIdSelector } from "../redux/features/authFeatureSlice";
import { useDaysInMonth } from "../hooks/useDaysInMonth";

export const useTaskCalendar = ({ currentDate, userId }) => {
  const dispatch = useDispatch();
  const viewState = useSelector(viewStateSelector);
  const currentUserId = useSelector(userIdSelector);
  userId = userId || currentUserId;
  const [date, setDate] = useState(currentDate);
  const previousUserId = usePrevious(userId);
  const days = useDaysInMonth(date);

  useEffect(() => {
    dispatch(setViewState(false));
    dispatch(getUsersList());
    dispatch(fetchHolidays(date));
  }, []);

  useEffect(() => {
    dispatch(fetchHolidays(date));
  }, [date.format()]);

  useEffect(() => {
    if (userId !== previousUserId) {
      dispatch(resetView());
    }

    if (viewState === "task") {
      dispatch(fetchTasksAndPutIntoView(date, userId));
    } else {
      dispatch(fetchStatistic(date, userId));
    }
  }, [date.format(), userId, viewState]);

  const handleOnPrevMonth = useCallback(() => {
    const newDate = date.clone().subtract(1, "month");
    setDate(newDate);
  }, [date.format()]);

  const handleOnNextMonth = useCallback(() => {
    const newDate = date.clone().add(1, "month");
    setDate(newDate);
  }, [date.format()]);

  return useMemo(
    () => ({
      handleOnPrevMonth,
      handleOnNextMonth,
      date,
      days,
      userId
    }),
    [date.format(), days, userId, viewState]
  );
};
