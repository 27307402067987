import CalendarContainer from "./CalendarContainer";
import React from "react";
import CalendarHeader from "./header/CalendarHeader";
import CalendarDaysContainer from "./main/CalendarDaysContainer";
import clsx from "clsx";

export const Calendar = ({
  title,
  singleView,
  sidebar,
  header,
  children,
  hideWeeks
}) => {
  return (
    <CalendarContainer singleView={singleView}>
      {sidebar && !singleView && (
        <div className="col-xl-2 col-lg-12 sidebar_lg">{sidebar}</div>
      )}
      <div
        className={clsx("col-lg-12", {
          "col-xl-10": !singleView,
          "col-xl-12 p-0": singleView
        })}
      >
        <div
          className={clsx("kt-portlet kt-portlet--mobile", {
            "m-0": singleView
          })}
        >
          <CalendarHeader>{header}</CalendarHeader>
          <CalendarDaysContainer hideWeeks={hideWeeks}>
            {children}
          </CalendarDaysContainer>
        </div>
      </div>
    </CalendarContainer>
  );
};
