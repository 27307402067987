import axios from "axios";
import store from "../redux/store";
import {
  logoutAndClearStorage,
  saveTokens
} from "../redux/features/authFeatureSlice";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { REFRESH_URL } from "../constants/api";
import {
  openSnackbar,
  resetSnack
} from "../redux/features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import i18n from "../i18n/i18n";

export const accessTokenSelector = state => state.auth.token;
export const refreshTokenSelector = state => state.auth.refreshToken;
export const setAuthHeader = token => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const refreshAccessToken = async failedRequest => {
  const oldRefreshToken = refreshTokenSelector(store.getState());
  setAuthHeader(oldRefreshToken);

  return axios
    .get(REFRESH_URL, { skipAuthRefresh: true })
    .then(({ data }) => {
      store.dispatch(
        saveTokens({
          token: data.accessToken,
          refreshToken: data.refreshToken
        })
      );

      failedRequest.config.headers.Authorization = "Bearer " + data.accessToken;

      return Promise.resolve();
    })
    .catch(() => Promise.reject(failedRequest));
};

createAuthRefreshInterceptor(axios, refreshAccessToken, {
  skipWhileRefreshing: false
});

axios.interceptors.request.use(config => {
  config.headers.Authorization = axios.defaults.headers.common.Authorization;
  return config;
});

axios.interceptors.response.use(
  request => request,
  failedRequest => {
    if (
      failedRequest.response &&
      failedRequest.response.status === 401 &&
      failedRequest.response.config.url.match(/refresh/)
    ) {
      store.dispatch(resetSnack());
      store.dispatch(logoutAndClearStorage());
      store.dispatch(
        openSnackbar(
          Fade,
          i18n.t("Время сессии истекло, войдите в Ваш аккаунт"),
          "warning"
        )
      );
    }

    return Promise.reject(failedRequest);
  }
);
