import React from "react";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import { Fade, TextField } from "@material-ui/core";
import { updateUserById } from "../../crud/settings.crud";
import * as Yup from "yup";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { extractErrorInfo } from "../../utils/extractError";
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../i18n/i18n";

const ChangePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Пароль должен содержать не менее 6 символов")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле"),
  passwordRepeat: Yup.string()
    .oneOf([Yup.ref("password"), null], "Пароли не совпадают")
    .max(255, "Максимальная величина поля 255 символов")
    .required("Обязательное поле")
});

export default function ModalChangePassword({ isVisible, hideModal, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  async function updatePassword(password) {
    try {
      await updateUserById(id, { password });
      hideModal();
      dispatch(openSnackbar(Fade, t("Пароль изменен"), "success"));
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  }
  return (
    <Modal className="modal fade" show={isVisible} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Trans>Изменение пароля</Trans>
        </Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          password: "",
          passwordRepeat: ""
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={async values => {
          await updatePassword(values.password);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form
            noValidate={true}
            autoComplete="new-password"
            className="kt-form"
            onSubmit={handleSubmit}
          >
            <Modal.Body>
              <div className="form-group">
                <TextField
                  label={t("Введите новый пароль")}
                  autoComplete="new-password"
                  type="password"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && t(errors.password)}
                  error={Boolean(touched.password && errors.password)}
                />

                <TextField
                  label={t("Подтвердите новый пароль")}
                  autoComplete="new-password"
                  type="password"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="passwordRepeat"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.passwordRepeat}
                  helperText={
                    touched.passwordRepeat && t(errors.passwordRepeat)
                  }
                  error={Boolean(
                    touched.passwordRepeat && errors.passwordRepeat
                  )}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" type="submit">
                <Trans>Обновить</Trans>
              </Button>
              <Button variant="secondary" onClick={hideModal}>
                <Trans>Закрыть</Trans>
              </Button>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
}
