import clsx from "clsx";
import { ProjectDaySummary } from "./ProjectDaySummary";
import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { summarySelector } from "../../../redux/features/projectsFeatureSlice";
import { useTranslation } from "react-i18next";
import "moment/min/locales.min";

export const ProjectTimeline = () => {
  const summaries = useSelector(summarySelector);
  const { i18n } = useTranslation();
  moment.locale(i18n.language);

  return (
    <>
      <div className="col-xl-3"></div>
      <div className="col-xl-6">
        <div className="kt-timeline-v1 kt-timeline-v1--justified">
          <div className="kt-timeline-v1__items">
            <div className="kt-timeline-v1__marker" />
            {summaries &&
              summaries.map(([date, summary], index) => {
                return (
                  <ProjectTimelineItem
                    key={date}
                    index={index}
                    date={moment(date).format("DD MMMM")}
                    summary={summary}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

const ProjectTimelineItem = ({ index, date, summary }) => {
  return (
    <div
      className={clsx("kt-timeline-v1__item", {
        "kt-timeline-v1__item--first": index === 0
      })}
    >
      <div className="kt-timeline-v1__item-circle">
        <div className="kt-bg-danger" />
      </div>
      <span
        className="kt-timeline-v1__item-time kt-font-brand"
        style={{ width: "100%" }}
      >
        {date}
      </span>
      <div className="kt-timeline-v1__item-content">
        <div className="kt-timeline-v1__item-body">
          <ProjectDaySummary
            summary={summary}
            HintComponent={ProjectDescriptionHint}
          />
        </div>
      </div>
    </div>
  );
};

export const ProjectDescriptionHint = ({ descriptions }) => {
  if (!descriptions || descriptions.length === 0) {
    return null;
  }

  return (
    <>
      {descriptions.map(({ from, to, text }, index) => (
        <p key={index}>
          <b>
            {moment(from).format("HH:mm")} - {moment(to).format("HH:mm")}
          </b>
          <br />
          {text || "(Не указано)"}
        </p>
      ))}
    </>
  );
};
