import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ProjectsMergeResult } from "../../components/projects/ProjectsMergeResults";
import { ProjectsToMergeList } from "../../components/projects/ProjectsToMergeList";
import { getProjectsListByIds } from "../../redux/thunks/project.thunk";
import { useDispatch } from "react-redux";

export const ProjectsMerge = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [projects, setProjects] = useState([]);
  const [selectedProject, selectProject] = useState(undefined);

  useEffect(() => {
    if (!state || !state.selected) {
      return;
    }

    (async () => {
      const projects = await dispatch(getProjectsListByIds(state.selected));

      setProjects(projects);
      const [first] = projects;
      selectProject(first);
    })();
  }, [state]);

  if (!state || !state.selected) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-md-6">
        <ProjectsMergeResult project={selectedProject} projects={projects} />
      </div>
      <div className="col-md-6">
        <ProjectsToMergeList
          selectedProject={selectedProject}
          projects={projects}
          selectProject={selectProject}
        />
      </div>
    </div>
  );
};
