import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import {
  closeTaskDialog,
  toggleTaskDialog,
  TRANSITION_DURATION
} from "../../redux/features/popperTaskFeatureSlice";

const taskOrDefault = (task, taskTime) => {
  if (task) {
    return task;
  }

  return {
    startDate: taskTime.startOf("hour").format("YYYY-MM-DD HH:mm"),
    endDate: taskTime.endOf("hour").format("YYYY-MM-DD HH:mm"),
    projectIds: [],
    newProjects: [],
    description: ""
  };
};

const taskSelector = createSelector(
  (state, taskTime) =>
    taskOrDefault(
      (state.calendar.view[taskTime.format("YYYY-MM-DD")] || {})[
        taskTime.format("HH:mm")
      ],
      taskTime
    ),
  task => task
);

const scoresSelector = createSelector(
  state => state.score,
  score => score
);

const useStyles = makeStyles(theme => ({
  borderGray: {
    borderColor: "#9b9b9b !important"
  }
}));

let timerId;
const transitionTimeout = () =>
  new Promise(resolve => {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(resolve, TRANSITION_DURATION);
  });

const createToggle = task => event => {
  return async (dispatch, getState) => {
    const target = event.currentTarget;

    const {
      isOpen,
      isTransiting,
      hasWarning,
      target: currentTarget
    } = getState().popperTask;

    if (target !== currentTarget && !hasWarning) {
      if (isOpen) {
        dispatch(closeTaskDialog());
        await transitionTimeout();
      }

      dispatch(toggleTaskDialog({ target, task }));
      return;
    }

    if (isOpen && !isTransiting) {
      return;
    }

    dispatch(toggleTaskDialog({ target, task }));
  };
};

const createBackgroundColors = (task, scores) => {
  const foundColors = [];
  const cssColors = [];

  if (task.projectText) {
    const score = scores.find(score => score.name === task.projectText);

    if (score) {
      foundColors.push(score.color);
    }
  }

  if (task.projects && task.projects.length) {
    const names = task.projects.map(({ name }) => name);
    const colors = scores
      .filter(({ name }) => names.includes(name))
      .map(({ color }) => color);
    foundColors.push(...colors.filter(color => color));
  }

  if (!foundColors.length) {
    return cssColors;
  }

  const sectionSize = 100 / foundColors.length;

  for (const [index, color] of foundColors.entries()) {
    const currentSectionStart = index * sectionSize;
    cssColors.push(`${color} ${currentSectionStart}%`);
    cssColors.push(`${color} ${currentSectionStart + sectionSize}%`);
  }

  return cssColors;
};

export function TaskCalendarItem({ taskTime, inSchedule, isHoliday }) {
  const task = useSelector(state => taskSelector(state, taskTime));
  const scores = useSelector(scoresSelector);
  const dispatch = useDispatch();
  const [gradient, setGradient] = useState(null);
  const classes = useStyles();
  const toggle = useMemo(() => createToggle(task), [task]);

  useEffect(() => {
    const cssColors = createBackgroundColors(task, scores);

    if (cssColors.length) {
      setGradient(`linear-gradient(to right, ${cssColors.join(", ")})`);
    } else {
      setGradient(null);
    }
  }, [JSON.stringify(task), JSON.stringify(scores)]);

  const handleClick = useCallback(
    event => {
      dispatch(toggle(event));
    },
    [toggle]
  );

  return (
    <div>
      <div
        onClick={handleClick}
        style={{ cursor: "pointer", backgroundImage: gradient }}
        className={clsx(
          "event d-block p-1 pl-2 pr-2 mb-1 rounded text-truncate small lkdm_popover",
          {
            [classes.borderGray]: inSchedule && !isHoliday
          }
        )}
        variant="secondary"
      >
        <div className="clock">{moment(task.startDate).format("HH:mm")}</div>
        <span className="task">
          {task.projects && task.projects.length
            ? task.projects.map(p => p.name).join(", ")
            : task.projectText}
        </span>
      </div>
    </div>
  );
}
