import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  lang: "ru"
};

export const updatedFlag = "$$updated";

const settings = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      return {
        ...state,
        users: action.payload
      };
    },
    reorderUsers: (state, action) => {
      const { from, to } = action.payload;
      const tempUser = state.users[from];
      state.users.splice(from, 1);
      state.users.splice(to, 0, tempUser);

      for (const [index, user] of state.users.entries()) {
        if (user.order !== index) {
          user.order = index;
          user[updatedFlag] = true;
        }
      }
    },
    addUser: (state, action) => {
      const { user } = action.payload;
      return {
        ...state,
        users: state.users.concat([user])
      };
    },
    updateUser: (state, action) => {
      const { user } = action.payload;
      return {
        ...state,
        users: state.users.map(userInState => {
          if (userInState.id === user.id) {
            return (userInState = user);
          }
          return userInState;
        })
      };
    },
    deleteUser: (state, action) => {
      const { userId } = action.payload;
      return {
        ...state,
        users: state.users.filter(user => user.id !== userId)
      };
    },
    setLanguage: (state, action) => {
      state.lang = action.payload;
    }
  }
});

export const {
  setUsers,
  updateUser,
  addUser,
  deleteUser,
  setLanguage,
  reorderUsers
} = settings.actions;
export default settings.reducer;
