import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Formik } from "formik";
import filterCRUDValues from "../../utils/filterCRUDValues";
import {
  TextField,
  Switch,
  FormControlLabel,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import * as Yup from "yup";
import { createProject, updateProject } from "../../redux/thunks/project.thunk";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { URL } from "../../constants/api";

const ProjectSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "Максимальная величина поля 32 символов")
    .required("Обязательное поле"),
  description: Yup.string().max(255, "Максимальная величина поля 255 символов")
});

export const ProjectModal = ({ show, close, project, refresh }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isCopied, setCopied] = useState(false);
  const handleSubmit = useCallback(
    async values => {
      let result;

      if (project) {
        result = await dispatch(
          updateProject(project.id, filterCRUDValues(values))
        );
      } else {
        result = await dispatch(createProject(filterCRUDValues(values)));
      }

      if (result) {
        await refresh();
        close();
      }
    },
    [close, dispatch, project, refresh]
  );
  return (
    <Modal className="modal fade" show={show} onHide={close}>
      <Formik
        initialValues={{
          name: (project && project.name) || "",
          description: (project && project.description) || "",
          urlAccess: !!(project && project.urlAccess)
        }}
        validationSchema={ProjectSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          submitForm
        }) => (
          <>
            <Modal.Header closeButton>
              <Modal.Title>
                {!project ? (
                  <Trans>Добавить новый проект</Trans>
                ) : (
                  <Trans>Обновить проект</Trans>
                )}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                noValidate={true}
                autoComplete="new-password"
                className="kt-form"
                onSubmit={handleSubmit}
              >
                <TextField
                  label={t("Название")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  className="form-control"
                  size="small"
                  variant="outlined"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  helperText={errors.name && t(errors.name)}
                  error={Boolean(errors.name)}
                />
                <TextField
                  multiline
                  rows={3}
                  label={t("Описание")}
                  autoComplete="new-password"
                  type="text"
                  margin="normal"
                  size="small"
                  variant="outlined"
                  name="description"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description}
                  helperText={errors.description && t(errors.description)}
                  error={Boolean(errors.description)}
                />
                <FormControlLabel
                  label={t("Доступ по ссылке")}
                  control={
                    <Switch
                      name="urlAccess"
                      value={values.urlAccess}
                      onChange={handleChange}
                      helperText={"test"}
                    />
                  }
                />
                {project && values.urlAccess && (
                  <TextField
                    label={t("Ссылка на проект")}
                    autoComplete="new-password"
                    type="text"
                    margin="normal"
                    className="form-control"
                    size="small"
                    variant="outlined"
                    value={`${URL}/project/view/${project.id}`}
                    helperText={isCopied && t("Скопировано")}
                    InputProps={{
                      readOnly: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CopyToClipboard
                            text={`${URL}/project/view/${project.id}`}
                            onCopy={() => setCopied(true)}
                          >
                            <IconButton color="primary">
                              <FileCopyIcon />
                            </IconButton>
                          </CopyToClipboard>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={close}>
                <Trans>Закрыть</Trans>
              </Button>
              <Button variant="outline-success" onClick={submitForm}>
                {!project ? <Trans>Сохранить</Trans> : <Trans>Обновить</Trans>}
              </Button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};
