import React from "react";
import moment from "moment";
import "react-day-picker/lib/style.css";
import { useParams } from "react-router";
import { TaskCalendar } from "../../components/task/TaskCalendar";

export default function Calendar() {
  const currentDate = moment();
  const { id } = useParams();

  return <TaskCalendar currentDate={currentDate} initialUserId={id} />;
}
