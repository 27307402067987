import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDaysInMonth } from "./useDaysInMonth";
import {
  getFullProjectsList,
  getSummaries
} from "../redux/thunks/project.thunk";
import { fetchHolidays } from "../redux/features/calendarFeatureSlice";

export const useProjectsCalendar = ({
  currentDate,
  projectId: initialProjectId,
  singleView
}) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(currentDate);
  const [projectId, setProjectId] = useState(initialProjectId);
  const [project, setProject] = useState(null);
  const days = useDaysInMonth(date);
  const projects = useSelector(state => state.projects.list);

  useEffect(() => {
    if (projects.length && !projectId) {
      setProjectId(projects[0].id);
    } else {
      setProjectId(initialProjectId);
    }
  }, [projects, projectId, initialProjectId]);

  const handleOnPrevMonth = useCallback(() => {
    const newDate = date.clone().subtract(1, "month");
    setDate(newDate);
  }, [date.format()]);

  const handleOnNextMonth = useCallback(() => {
    const newDate = date.clone().add(1, "month");
    setDate(newDate);
  }, [date.format()]);

  useEffect(() => {
    if (!singleView) {
      dispatch(getFullProjectsList());
    }
  }, [singleView]);

  useEffect(() => {
    dispatch(fetchHolidays(date, projectId));
  }, [date.format(), projectId]);

  useEffect(() => {
    if (projectId) {
      (async () => {
        const project = await dispatch(
          getSummaries(
            projectId,
            date
              .clone()
              .startOf("month")
              .startOf("isoWeek"),
            date
              .clone()
              .endOf("month")
              .endOf("isoWeek")
          )
        );
        setProject(project);
      })();
    }
  }, [date.format(), projectId]);

  return useMemo(
    () => ({
      handleOnPrevMonth,
      handleOnNextMonth,
      date,
      days,
      projectId,
      project
    }),
    [project, days, date.format(), projectId]
  );
};
