import React, { useEffect } from "react";
import { Formik } from "formik";
import { Button, TextField } from "@material-ui/core";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";
import { useDebouncedCallback } from "use-debounce";
import { recalculateAmounts } from "../../../crud/subscription.api";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../../i18n/i18n";

const configurationSchema = Yup.object().shape({
  slots: Yup.number()
    .min(1, i18n.t("Минимальное количество аккаунтов - 1"))
    .required("Обязательное поле"),
  months: Yup.number()
    .min(1, i18n.t("Минимальный срок подписки - 1 месяц"))
    .required("Обязательное поле")
});

export const SubscriptionConfiguration = ({
  slots,
  months,
  total,
  paidUntil,
  setPaidUntil,
  setSlots,
  setTotal,
  setMonths,
  handleNext,
  setModalState,
  classes
}) => {
  const { t } = useTranslation();
  const [updateTotals] = useDebouncedCallback(async ({ slots, months }) => {
    if (slots > 0 && months > 0) {
      const { data } = await recalculateAmounts(slots, months);
      setTotal(data.total);
      setPaidUntil(data.paidUntil);
    } else {
      setTotal(undefined);
      setPaidUntil(undefined);
    }
  }, 500);

  useEffect(() => {
    updateTotals({ slots, months });
  }, []);
  return (
    <>
      <Formik
        initialValues={{
          slots,
          months
        }}
        validationSchema={configurationSchema}
        onSubmit={() => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <form
            noValidate={true}
            autoComplete="off"
            className="kt-form"
            onSubmit={handleSubmit}
          >
            <div className="form-group kt-widget2">
              <TextField
                type="number"
                margin="normal"
                min={1}
                placeholder={t("Число сотрудников")}
                label={t("Введите необходимое количество аккаунтов")}
                className="kt-width-full"
                name="slots"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                  setSlots(parseInt(e.target.value));
                  updateTotals({
                    ...{ slots: values.slots, months: values.months },
                    ...{ [e.target.name]: parseInt(e.target.value) }
                  });
                }}
                value={values.slots}
                helperText={touched.slots && t(errors.slots)}
                error={Boolean(touched.slots && errors.slots)}
              />
            </div>

            <div className="form-group">
              <TextField
                type="number"
                margin="normal"
                min={1}
                label={t("Число месяцев")}
                placeholder={t("Как долго должна действовать подписка")}
                className="kt-width-full"
                name="months"
                onBlur={handleBlur}
                onChange={e => {
                  handleChange(e);
                  setMonths(parseInt(e.target.value));
                  updateTotals({
                    ...{ slots: values.slots, months: values.months },
                    ...{ [e.target.name]: parseInt(e.target.value) }
                  });
                }}
                value={values.months}
                helperText={touched.months && t(errors.months)}
                error={Boolean(touched.months && errors.months)}
              />
            </div>

            {values.slots > 0 && total > 0 && (
              <>
                <Typography gutterBottom variant="subtitle1">
                  {/* prettier-ignore */}
                  <Trans>
                    Вы приобретаете подписку на {{ slots: values.slots }} сотрудников до {{ until: moment(paidUntil).format("DD.MM.YYYY") }}, стоимость: {{ total }} ₽
                  </Trans>
                </Typography>
                <div>
                  <Button
                    onClick={() => setModalState(false)}
                    className={classes.button}
                  >
                    <Trans>Отмена</Trans>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    <Trans>Оплатить</Trans>
                  </Button>
                </div>
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};
