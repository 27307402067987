import React, { useState } from "react";
import "../../../_metronic/_assets/sass/pages/login/login-6.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FormCompany from "../../components/forms/FormCompany";
import filterCRUDValues from "../../utils/filterCRUDValues";
import thunkOnBootstrapCompany from "../../redux/thunks/thunkOnBootstrapCompany";
import { Alert } from "react-bootstrap";
import { extractErrorInfo } from "../../utils/extractError";
import { AuthLayout, IntroduceMessage } from "../../components/auth/Container";

const BootstrapCompanyForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  async function onSubmitCompany(values, setSubmitting) {
    try {
      await dispatch(thunkOnBootstrapCompany(filterCRUDValues(values)));
      setSubmitting(false);
      disableLoading();
    } catch (e) {
      disableLoading();
      setSubmitting(false);
      setType("danger");
      const { header, message } = extractErrorInfo(e);
      setMessage(`${header}. ${message || ""}`);
    }
  }

  if (!user) {
    history.push("/auth/login");
  }

  return (
    <>
      <a href="#" className="text-center pt-2">
        <img
          src="/media/auth/logo.png"
          style={{ maxHeight: "90px", maxWidth: "90px" }}
          alt=""
        />
      </a>
      <div className="d-flex flex-column-fluid flex-column flex-center">
        <div className="login-form login-signin py-11">
          {message.length ? <Alert variant={type}>{message}</Alert> : null}

          <div className="text-center pb-8">
            <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
              Workapp
            </h2>
          </div>

          <FormCompany
            enableLoading={enableLoading}
            loading={loading}
            loadingButtonStyle={loadingButtonStyle}
            onSubmitCompany={onSubmitCompany}
          />
        </div>
      </div>
      <center>
        <a href="https://linkodium.com" target="_blank">
          Linkodium
        </a>{" "}
        (2020)
      </center>
    </>
  );
};

export const NewBootstrapCompany = () => {
  return (
    <AuthLayout left={<BootstrapCompanyForm />} right={<IntroduceMessage />} />
  );
};
