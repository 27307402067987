import React from "react";
import { useTranslation } from "react-i18next";

export default function CalendarMonthsSwitcher({
  handleOnNextMonth,
  handleOnPrevMonth,
  date
}) {
  const { i18n } = useTranslation();
  const months = {
    0: i18n.t("Январь"),
    1: i18n.t("Февраль"),
    2: i18n.t("Март"),
    3: i18n.t("Апрель"),
    4: i18n.t("Май"),
    5: i18n.t("Июнь"),
    6: i18n.t("Июль"),
    7: i18n.t("Август"),
    8: i18n.t("Сентябрь"),
    9: i18n.t("Октябрь"),
    10: i18n.t("Ноябрь"),
    11: i18n.t("Декабрь")
  };
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={handleOnPrevMonth}
        >
          {"<"}
        </button>
      </div>
      <input
        readOnly
        type="text"
        className="form-control"
        style={{ textAlign: "center" }}
        value={`${months[date.month()]} ${date.year()}`}
      />
      <div className="input-group-append">
        <button
          className="btn btn-secondary"
          type="button"
          onClick={handleOnNextMonth}
        >
          {">"}
        </button>
      </div>
    </div>
  );
}
