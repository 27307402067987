import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  list: [],
  summary: undefined
};

const projectsSlice = createSlice({
  initialState,
  name: "projects",
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    resetProjects: () => initialState
  }
});

export default projectsSlice.reducer;
export const { setList, setSummary, resetProjects } = projectsSlice.actions;
export const summarySelector = createSelector(
  state => state.projects.summary,
  summary =>
    (summary ? Object.entries(summary) : []).filter(
      ([, summary]) => summary.length > 0
    )
);
