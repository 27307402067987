import React from "react";
import { Trans } from "react-i18next";

export default function SettingsUsersHeader({ handleAddUser }) {
  return (
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title">
          <Trans>Сотрудники</Trans>
        </h3>
      </div>
      <div className="kt-portlet__head-toolbar">
        <div className="dropdown dropdown-inline">
          <button
            type="button"
            onClick={handleAddUser}
            className="btn btn-success btn-sm"
          >
            <i className="fa fa-plus" />
            <Trans>Добавить</Trans>
          </button>
        </div>
      </div>
    </div>
  );
}
