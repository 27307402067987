import React from "react";
import { Button } from "react-bootstrap";
import { Formik } from "formik";
import { Fade, TextField } from "@material-ui/core";
import { updateCompanyById } from "../../crud/settings.crud";
import { saveCompany } from "../../redux/features/authFeatureSlice";
import { useDispatch } from "react-redux";
import TimeMaskInput from "../TimeMaskInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import zones from "../../utils/timezones.json";
import filterCRUDValues from "../../utils/filterCRUDValues";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { extractErrorInfo } from "../../utils/extractError";
import { Trans, useTranslation } from "react-i18next";

export default function EditCompanyForm({ company, timezones }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  async function updateCompany(values) {
    try {
      const { data } = await updateCompanyById(company.id, values);
      dispatch(saveCompany({ company: data }));
      dispatch(openSnackbar(Fade, t("Компания обновлена"), "success"));
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  }

  return (
    <Formik
      initialValues={{
        name: company.name || "",
        logo: company.logo || "",
        address: company.address || "",
        site: company.site || "",
        description: company.description || "",
        inn: company.inn || "",
        email: company.email || "",
        timeZone: company.timeZone || "",
        workStartsAt: company.workStartsAt || "",
        workEndsAt: company.workEndsAt || ""
      }}
      onSubmit={async values => await updateCompany(filterCRUDValues(values))}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit
      }) => (
        <form
          noValidate={true}
          autoComplete="new-password"
          className="kt-form"
          onSubmit={handleSubmit}
        >
          <TextField
            label={t("Название")}
            autoComplete="new-password"
            type="text"
            margin="normal"
            className="form-control"
            size="small"
            variant="outlined"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            helperText={touched.name && t(errors.name)}
            error={Boolean(touched.name && errors.name)}
          />

          <TextField
            label={t("Начало рабочего дня")}
            autoComplete="new-password"
            type="text"
            margin="normal"
            className="form-control"
            size="small"
            variant="outlined"
            name="workStartsAt"
            onBlur={handleBlur}
            InputProps={{
              inputComponent: TimeMaskInput
            }}
            onChange={handleChange}
            value={values.workStartsAt}
            helperText={touched.workStartsAt && t(errors.workStartsAt)}
            error={Boolean(touched.workStartsAt && errors.workStartsAt)}
          />

          <TextField
            label={t("Конец рабочего дня")}
            autoComplete="new-password"
            type="text"
            margin="normal"
            className="form-control"
            size="small"
            variant="outlined"
            name="workEndsAt"
            onBlur={handleBlur}
            InputProps={{
              inputComponent: TimeMaskInput
            }}
            onChange={handleChange}
            value={values.workEndsAt}
            helperText={touched.workEndsAt && t(errors.workEndsAt)}
            error={Boolean(touched.workEndsAt && errors.workEndsAt)}
          />

          <Autocomplete
            name="timeZone"
            getOptionLabel={([, value]) => value}
            options={timezones}
            value={[values.timeZone, zones[values.timeZone]]}
            defaultValue={["Europe/Moscow", t("Москва (GMT+03:00)")]}
            onChange={(e, value) => {
              if (Array.isArray(value)) {
                setFieldValue("timeZone", value[0]);
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                size="small"
                margin="normal"
                label={t("Часовой пояс")}
                variant="outlined"
              />
            )}
          />

          <Button variant="success" type="submit">
            <Trans>Обновить</Trans>
          </Button>
        </form>
      )}
    </Formik>
  );
}
