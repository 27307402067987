import React, { forwardRef, useEffect, useReducer } from "react";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { createWeekDaysShort, createMonths } from "../../utils/getDaysInMonth";

const SearchBarContainer = styled.div`
  & .search-label {
    width: 100%;
  }
`;

const SizeDropDown = styled(SizePerPageDropdownStandalone)`
  & button {
    width: 100%;
  }
`;

const RangePicker = ({ startDate, endDate, onChange, onCancel }) => {
  const { t } = useTranslation();
  const [key, forceRender] = useReducer(x => x + 1, 0);

  useEffect(() => {
    forceRender();
  }, [t("Применить")]);

  return (
    <DateRangePicker
      key={key}
      initialSettings={{
        startDate,
        endDate,
        moment,
        locale: {
          format: "DD.MM.YYYY",
          applyLabel: t("Применить"),
          cancelLabel: t("Отмена"),
          fromLabel: t("От"),
          toLabel: t("До"),
          weekLabel: "W",
          daysOfWeek: createWeekDaysShort(t),
          monthNames: createMonths(t),
          firstDay: 1
        }
      }}
      onCallback={(startDate, endDate) => {
        onChange({ startDate, endDate });
      }}
      onCancel={onCancel}
    >
      <input type="text" className="form-control" />
    </DateRangePicker>
  );
};

export const PaginatedTable = forwardRef(
  (
    { state, columns, onRangeChange, onRangeCancel = () => {}, ...restProps },
    ref
  ) => {
    const { page, pageSize, data, totalSize, startDate, endDate } = state;
    const { t } = useTranslation();

    return (
      <PaginationProvider
        pagination={paginationFactory({
          custom: true,
          alwaysShowAllBtns: true,
          withFirstAndLast: false,
          page,
          pageSize,
          totalSize
        })}
      >
        {({ paginationProps, paginationTableProps }) => (
          <div ref={ref}>
            <ToolkitProvider
              search
              bootstrap4
              keyField="id"
              data={data}
              columns={columns}
            >
              {toolkitProps => {
                return (
                  <>
                    <div key="header" className="row mb-2">
                      <div className="col-1">
                        <SizeDropDown
                          {...paginationProps}
                          sizePerPageList={[
                            ...paginationProps.sizePerPageList,
                            100
                          ]}
                        />
                      </div>
                      {onRangeChange && (
                        <>
                          <div className="col-4">
                            <RangePicker
                              startDate={startDate}
                              endDate={endDate}
                              onChange={onRangeChange}
                              onCancel={onRangeCancel}
                            />
                          </div>
                          <div className="col-7">
                            <SearchBarContainer>
                              <Search.SearchBar
                                {...toolkitProps.searchProps}
                                placeholder={t("Поиск по названию")}
                              />
                            </SearchBarContainer>
                          </div>
                        </>
                      )}
                    </div>
                    <BootstrapTable
                      key="table"
                      remote={{ search: true, sort: true, pagination: true }}
                      wrapperClasses="table-responsive"
                      {...paginationTableProps}
                      {...toolkitProps.baseProps}
                      {...restProps}
                    />
                    <div key="footer" className="row">
                      <div className="col">
                        <PaginationListStandalone {...paginationProps} />
                      </div>
                    </div>
                  </>
                );
              }}
            </ToolkitProvider>
          </div>
        )}
      </PaginationProvider>
    );
  }
);
