import { Trans, useTranslation } from "react-i18next";
import { Formik } from "formik";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { mergeProjects } from "../../redux/thunks/project.thunk";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  btn: {
    display: "flex",
    justifyContent: "space-between",
    width: 230,
    alignItems: "center"
  }
}));
const ProjectSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "Максимальная величина поля 32 символов")
    .required("Обязательное поле"),
  description: Yup.string().max(255, "Максимальная величина поля 255 символов")
});

export const ProjectsMergeResult = ({ project, projects }) => {
  return (
    <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space">
      <ProjectMergeResultHeader />
      <div className="kt-portlet__body">
        <Alert className="mb-3" severity="info">
          <Trans>
            <AlertTitle>
              Здесь находится форма редактирования результата объединения
            </AlertTitle>
            Дополните информацию, удалите ненужное, либо скопируйте недостающее
            из списка проектов и нажмите "Сохранить"
          </Trans>
        </Alert>
        <ProjectMergeResultForm project={project} projects={projects} />
      </div>
    </div>
  );
};

const ProjectMergeResultHeader = () => {
  return (
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title">
          <Trans>Результат объединения</Trans>
        </h3>
      </div>
    </div>
  );
};

const ProjectMergeResultForm = ({ project, projects }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const handleSubmit = useCallback(
    async values => {
      const result = await dispatch(
        mergeProjects({
          projects: projects.filter(({ id }) => project.id !== id),
          project: { ...project, ...values }
        })
      );

      if (result) {
        history.replace("/projects");
      }
    },
    [project, projects]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: (project && project.name) || "",
        description: (project && project.description) || ""
      }}
      validationSchema={ProjectSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        submitForm
      }) => (
        <form
          noValidate={true}
          autoComplete="new-password"
          className="kt-form"
          onSubmit={handleSubmit}
        >
          <TextField
            label={t("Название")}
            autoComplete="new-password"
            type="text"
            margin="normal"
            className="form-control"
            size="small"
            variant="outlined"
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}
            helperText={errors.name && t(errors.name)}
            error={Boolean(errors.name)}
          />
          <TextField
            multiline
            rows={3}
            label={t("Описание")}
            autoComplete="new-password"
            type="text"
            margin="normal"
            size="small"
            variant="outlined"
            name="description"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description}
            helperText={errors.description && t(errors.description)}
            error={Boolean(errors.description)}
          />

          <div className={classes.btn}>
            <Button variant="success" type="submit">
              <Trans>Сохранить</Trans>
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
