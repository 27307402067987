import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    color: "#595d6e",
    marginTop: 30
  }
}));

export default function AuthHeader({ title }) {
  const classes = useStyles();
  return <h3 className={classes.header}>{title}</h3>;
}
