import React, { useCallback } from "react";
import { PAYMENT_URL } from "../../constants/api";
import { PaginatedTable } from "./PaginatedTable";
import {
  dateFormatter,
  emptyPlaceholderFormatter,
  usePaginationData
} from "./utils";
import { Hint } from "../Hint";
import { Button, ButtonGroup } from "react-bootstrap";
import { useModal } from "../../hooks/useModal";
import { InvoiceInfo } from "./InvoiceInfo";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../../redux/features/snackbarFeatureSlice";
import { useDispatch } from "react-redux";
import { approveInvoicePayment } from "../../crud/payment.api";
import { Fade } from "@material-ui/core";

const createColumns = modalControls => [
  {
    dataField: "createdAt",
    text: "Дата запроса",
    formatter: dateFormatter
  },
  {
    dataField: "company.name",
    text: "Компания",
    formatter: emptyPlaceholderFormatter
  },
  {
    dataField: "company.owner.email",
    text: "Контакт владельца",
    formatter: emptyPlaceholderFormatter
  },
  { dataField: "total", text: "Сумма", formatter: cell => `${cell} руб.` },
  {
    dataField: "",
    isDummyField: true,
    style: { width: "1px" },
    text: "Действия",
    formatExtraData: modalControls,
    formatter: (cell, row, rowIndex, extraData) => {
      const { openModal, onSubmit } = extraData;
      return (
        <ButtonGroup size="md">
          <Hint text="Подробнее">
            <Button
              className="btn-icon"
              variant="outline-info"
              size="md"
              onClick={() =>
                openModal({
                  contentProps: { data: row },
                  submitText: "Подтвердить оплату",
                  onSubmit: onSubmit
                })
              }
            >
              <i className="flaticon2-layers-2" />
            </Button>
          </Hint>
        </ButtonGroup>
      );
    }
  }
];

export const InvoicesTable = () => {
  const dispatch = useDispatch();
  const { openModal, closeModal, Modal } = useModal();
  const { state, onTableChange } = usePaginationData(
    `${PAYMENT_URL}/invoice-requests-paginated`
  );

  const onSubmit = useCallback(async ({ data }) => {
    try {
      await approveInvoicePayment(data.id);
      await onTableChange(undefined, {
        page: state.page,
        sizePerPage: state.pageSize
      });
    } catch (e) {
      const { header, message } = extractErrorInfo(e);
      dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    }
  }, []);

  const columns = createColumns({
    openModal,
    closeModal,
    onSubmit
  });
  return (
    <>
      <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Новые запросы счетов</h3>
          </div>
        </div>

        <div className="kt-portlet__body">
          <PaginatedTable
            state={state}
            onTableChange={onTableChange}
            columns={columns}
          />
        </div>
      </div>
      <Modal title="Подробная информация">
        {props => <InvoiceInfo {...props} />}
      </Modal>
    </>
  );
};
