import { makeStyles } from "@material-ui/styles";
import { NavLink } from "react-router-dom";
import { Trans } from "react-i18next";
import React from "react";
import { useRouteMatch, generatePath } from "react-router";

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "right",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10
    }
  }
}));

export const ProjectsCalendarHeaderButtons = ({ projectId }) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  return (
    <div className={classes.container}>
      <NavLink
        to={`${generatePath(path, { id: projectId })}/summary`}
        className="btn btn-secondary"
      >
        <Trans>Сводка</Trans>
      </NavLink>
      <NavLink
        to={`${generatePath(path, { id: projectId })}/calendar`}
        className="btn btn-secondary"
      >
        <Trans>Календарь</Trans>
      </NavLink>
      <NavLink
        to={`${generatePath(path, { id: projectId })}/timeline`}
        className="btn btn-secondary"
      >
        <Trans>Таймлайн</Trans>
      </NavLink>
    </div>
  );
};
