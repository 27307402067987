import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "en"
};

const i18n = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      const { lang } = action.payload;
      return {
        ...state,
        lang
      };
    }
  }
});

export const { setLanguage } = i18n.actions;
export default i18n.reducer;
