import React from "react";
import { Trans } from "react-i18next";

export default function SettingsCompanyHeader() {
  return (
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <h3 className="kt-portlet__head-title">
          <Trans>Моя компания</Trans>
        </h3>
      </div>
    </div>
  );
}
