import {
  createUser,
  deleteUserById,
  restoreUserById,
  updateUserById
} from "../../crud/settings.crud";
import {
  addUser,
  deleteUser,
  setUsers,
  updatedFlag,
  updateUser
} from "../features/settingsFeatureSlice";
import { openSnackbar } from "../features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { extractErrorInfo } from "../../utils/extractError";
import i18n from "../../i18n/i18n";
import { getUsersByCompanyId } from "../../crud/calendar.crud";

export const update = (
  id,
  { name, position, timeZone, email, password, role }
) => async dispatch => {
  try {
    const { data } = await updateUserById(id, {
      name,
      timeZone,
      positionName: position,
      email,
      password,
      role
    });
    dispatch(updateUser({ user: data }));
    dispatch(openSnackbar(Fade, i18n.t("Сотрудник обновлен"), "success"));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const add = ({
  name,
  position,
  timeZone,
  email,
  password,
  role
}) => async dispatch => {
  try {
    const { data } = await createUser(
      email,
      name,
      password,
      position,
      role,
      timeZone
    );
    dispatch(addUser({ user: data }));
    dispatch(openSnackbar(Fade, i18n.t("Сотрудник успешно создан"), "success"));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const remove = user => async dispatch => {
  try {
    const { data } = await deleteUserById(user.id);
    dispatch(deleteUser({ userId: data.id }));
    dispatch(openSnackbar(Fade, i18n.t("Сотрудник удален"), "success"));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const restore = user => async dispatch => {
  try {
    const { data } = await restoreUserById(user.id);
    dispatch(addUser({ user: data }));
    dispatch(openSnackbar(Fade, i18n.t("Сотрудник восстановлен"), "success"));
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const onReordering = () => async (dispatch, getState) => {
  const updatedUsers = getState().settings.users.filter(
    user => user[updatedFlag]
  );

  try {
    for (const user of updatedUsers) {
      await updateUserById(user.id, { order: user.order });
    }
  } catch (e) {
    await dispatch(getUsersList());
    extractErrorInfo(e);
    dispatch(
      openSnackbar(
        Fade,
        `Не удалось сохранить порядок сотрудников, изменения были отменены`,
        "error"
      )
    );
  }
};

export const getUsersList = () => async (dispatch, getState) => {
  let id;
  const { company } = getState().auth;

  if (company) {
    id = company.id;
  }

  try {
    const { data } = await getUsersByCompanyId(id);
    dispatch(setUsers(data));
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
  }
};
