import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, generatePath } from "react-router";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { CalendarItemsSelect } from "../../calendar/CalendarItemsSelect";
import CalendarMonthsSwitcher from "../../calendar/header/CalendarMonthsSwitcher";
import { ProjectsCalendarHeaderButtons } from "./ProjectsCalendarHeaderButtons";
import clsx from "clsx";

export const ProjectsCalendarHeader = ({
  title,
  date,
  projectId,
  singleView,
  handleOnNextMonth,
  handleOnPrevMonth
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path } = useRouteMatch();
  const handleChange = useCallback(
    id => history.push(`${generatePath(path, { id })}/summary`),
    [path]
  );
  const projects = useSelector(state => state.projects.list);
  return (
    <div className="row">
      <div className="col-md-12">
        <div
          className="kt-portlet__head"
          style={{ marginTop: -25, border: 0, justifyContent: "center" }}
        >
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">{title}</h3>
          </div>
        </div>
      </div>
      {singleView ? null : (
        <div className="col-md-3">
          <CalendarItemsSelect
            items={projects}
            currentId={projectId}
            onChange={handleChange}
            label={t("Проект")}
            placeholder={t("Начните вводить название проекта")}
          />
        </div>
      )}

      <div
        className={clsx({
          "col-md-6": !singleView,
          "col-md-9": singleView
        })}
        style={{ textAlign: "center" }}
      >
        <CalendarMonthsSwitcher
          date={date}
          handleOnNextMonth={handleOnNextMonth}
          handleOnPrevMonth={handleOnPrevMonth}
        />
      </div>

      <div className="col-md-3">
        <ProjectsCalendarHeaderButtons projectId={projectId} date={date} />
      </div>
    </div>
  );
};
