import React from "react";
import { Trans } from "react-i18next";

export default function SettingsMainParamsHeader() {
  return (
    <div className="kt-portlet__head">
      <div className="kt-portlet__head-label">
        <span className="kt-portlet__head-icon kt-hide">
          <i className="la la-gear" />
        </span>
        <h3 className="kt-portlet__head-title">
          <Trans>Мой профиль</Trans>
        </h3>
      </div>
    </div>
  );
}
