import React from "react";
import { colors } from "../../../constants/colors";

export const CalendarSidebarItem = ({ icon, index, title, subtitle }) => {
  return (
    <>
      <div className="kt-notification-v2__item-icon">
        <span
          className={`kt-badge kt-badge--unified-${
            colors[index % colors.length]
          } kt-badge--lg kt-badge--bold`}
        >
          {!icon && title[0].toUpperCase()}
          {icon && <i className={icon} />}
        </span>
      </div>
      <div className="kt-notification-v2__itek-wrapper">
        <div className="kt-notification-v2__item-title">{title}</div>
        <div className="kt-notification-v2__item-desc">{subtitle}</div>
      </div>
    </>
  );
};
