import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * @typedef {Object} ControlledSelectRow
 * @property {Array<string>} selected
 * @property {function} onSelect
 * @property {function} onSelectAll
 */

/**
 * Хук, реализующий отслеживание изменений при выборе/отмене выбора строки в таблице
 * @param {Object} config selectRow конфигурация
 * @param {boolean} hidden флаг, скрывающий столбец с выбором строки
 * @param classes стиль, который будет применён к выбранной строке
 * @return ControlledSelectRow
 */
export const useControlledSelectRow = (config, hidden, cls) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (hidden) {
      setSelected([]);
    }
  }, [hidden]);

  const onSelect = useCallback(
    (row, isSelect) => {
      if (hidden) {
        return;
      }

      if (isSelect) {
        setSelected([...selected, row.id]);
      } else {
        setSelected(selected.filter(id => id !== row.id));
      }
    },
    [selected, hidden]
  );

  const onSelectAll = useCallback(
    (isSelect, rows) => {
      if (hidden) {
        return;
      }

      if (isSelect) {
        setSelected(rows.map(({ id }) => id));
      } else {
        setSelected([]);
      }
    },
    [hidden]
  );

  const classes = useCallback(
    row => {
      if (hidden || selected.length === 0) {
        return;
      }

      return cls;
    },
    [selected, hidden, cls]
  );

  return useMemo(
    () => ({
      ...config,
      selected,
      onSelect,
      onSelectAll,
      classes,
      hideSelectColumn: true
    }),
    [selected, onSelect, onSelectAll, hidden]
  );
};
