import React, { useEffect, useState } from "react";
import "../../../_metronic/_assets/sass/pages/login/login-6.scss";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Alert } from "react-bootstrap";
import AuthContainer from "../../components/auth/AuthContainer";
import AuthLogo from "../../components/auth/AuthLogo";
import AuthHeader from "../../components/auth/AuthHeader";
import FormRequestPassword from "../../components/forms/FormRequestPassword";
import { Trans, useTranslation } from "react-i18next";
import { requestPassword } from "../../crud/auth.crud";
import { extractErrorInfo } from "../../utils/extractError";
import { useDispatch, useSelector } from "react-redux";
import { onAuthentication } from "../../redux/features/authFeatureSlice";
import FormLogin from "../../components/forms/FormLogin";
import { AuthLayout, IntroduceMessage } from "../../components/auth/Container";

export default function ForgotPassword() {
  const history = useHistory();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [isRequested, setRequested] = useState(false);

  if (isRequested) {
    history.push("/auth/login");
    setRequested(false);
  }

  const onSubmit = async (email, setSubmitting) => {
    try {
      await requestPassword(email);
      setType("success");
      setMessage(t("Проверьте свою почту"));
      setSubmitting(false);
      setTimeout(() => {
        setRequested(true);
      }, 4000);
    } catch (e) {
      setType("danger");
      setSubmitting(false);
      const { header, message } = extractErrorInfo(e);
      setMessage(`${header}. ${message || ""}`);
    }
  };

  return (
    <AuthContainer>
      <div className="kt-login__container">
        <div className="kt-login__body">
          <AuthLogo />
          <AuthHeader title={t("Забыли пароль?")} />
          {message.length ? <Alert variant={type}>{message}</Alert> : null}
          <FormRequestPassword history={history} onSubmit={onSubmit} />
        </div>
      </div>
    </AuthContainer>
  );
}

const ForgotPasswordForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [isRequested, setRequested] = useState(false);

  if (isRequested) {
    history.push("/auth/login");
    setRequested(false);
  }

  const onSubmit = async (email, setSubmitting) => {
    try {
      await requestPassword(email);
      setType("success");
      setMessage(t("Проверьте свою почту"));
      setSubmitting(false);
      setTimeout(() => {
        setRequested(true);
      }, 4000);
    } catch (e) {
      setType("danger");
      setSubmitting(false);
      const { header, message } = extractErrorInfo(e);
      setMessage(`${header}. ${message || ""}`);
    }
  };

  return (
    <>
      <a href="#" className="text-center pt-2">
        <img
          src="/media/auth/logo.png"
          style={{ maxHeight: "90px", maxWidth: "90px" }}
          alt=""
        />
      </a>
      <div className="d-flex flex-column-fluid flex-column flex-center">
        <div className="login-form login-signin py-11">
          {message.length ? <Alert variant={type}>{message}</Alert> : null}

          <div className="text-center pb-8">
            <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
              <Trans>Забыли пароль?</Trans>
            </h2>
          </div>

          <FormRequestPassword history={history} onSubmit={onSubmit} />
        </div>
      </div>
      <center>
        <a href="https://linkodium.com" target="_blank">
          Linkodium
        </a>{" "}
        (2020)
      </center>
    </>
  );
};

export const NewForgotPassword = () => {
  return (
    <AuthLayout left={<ForgotPasswordForm />} right={<IntroduceMessage />} />
  );
};
