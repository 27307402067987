import React from "react";
import SettingsMainParamsHeader from "./SettingsMainParamsHeader";
import SettingsMainParamsForm from "./SettingsMainParamsForm";

export default function SettingsMainParams({ user, handlePasswordChange }) {
  return (
    <div className="kt-portlet">
      <SettingsMainParamsHeader />
      <SettingsMainParamsForm
        user={user}
        handlePasswordChange={handlePasswordChange}
      />
    </div>
  );
}
