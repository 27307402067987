import React from "react";
import { createSelector } from "reselect";
import moment from "moment";
import { useSelector } from "react-redux";
import { ProgressBar } from "react-bootstrap";
import "./TaskProgressStatistic.css";
import { Trans } from "react-i18next";

const createTotalFactCalc = date => (acc, stats) => {
  if (!stats.date) {
    return acc;
  }

  const day = moment(stats.date, "YYYY-MM-DD");

  if (!date.isSame(day, "month")) {
    return acc;
  }

  if (stats.count) {
    acc += stats.count;
  }

  return acc;
};

const totalPlanCalc = (dailyPlan, stats, holidays, currentMonth) => {
  return (
    dailyPlan *
    (stats || []).filter(({ date }) => {
      const day = moment(date, "YYYY-MM-DD");
      const holiday =
        holidays.find(holiday => moment(holiday.date).isSame(day, "day")) || {};
      return (
        ![6, 7].includes(day.isoWeekday()) &&
        currentMonth.isSame(day, "month") &&
        !holiday.isHoliday
      );
    }).length
  );
};

const workProgressSelector = createSelector(
  state => state.calendar,
  state => state.auth.company,
  (state, date) => date,
  (calendar, company, date) => {
    let max;
    const dailyPlan = moment(company.workEndsAt, "HH:mm").diff(
      moment(company.workStartsAt, "HH:mm"),
      "hours"
    );
    const totalPlan = totalPlanCalc(
      dailyPlan,
      calendar.workStats,
      calendar.holidays,
      date
    );
    const totalFact = (calendar.workStats || []).reduce(
      createTotalFactCalc(date),
      0
    );

    max = totalPlan > totalFact ? totalPlan : totalFact;

    return {
      totalPlan,
      totalFact,
      max,
      viewState: calendar.viewState
    };
  }
);

export const TaskProgressStatistic = ({ date }) => {
  const { totalPlan, totalFact, max, viewState } = useSelector(state =>
    workProgressSelector(state, date)
  );

  if (viewState === "task") {
    return null;
  }

  return (
    <>
      <ProgressBar style={{ height: "40px", marginTop: "35px" }}>
        <ProgressBar
          striped
          className="bg-success flex-shrink-0"
          max={max}
          now={totalFact}
        />
        <ProgressBar className="bg-warning" max={max} now={totalPlan} />
      </ProgressBar>
      <span className="kt-badge kt-badge--dark kt-badge--inline kt-badge--lg kt-badge--rounded progress-bar__floating-label">
        <Trans>Факт</Trans>: {totalFact} <Trans>ч</Trans>.
      </span>
      <span className="kt-badge kt-badge--dark kt-badge--inline kt-badge--lg kt-badge--rounded progress-bar__floating-label">
        <Trans>План</Trans>: {totalPlan} <Trans>ч</Trans>.
      </span>
    </>
  );
};
