import React from "react";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import "./TaskCalendarDayStatistic.css";
import moment from "moment";
import clsx from "clsx";
import { Trans, useTranslation } from "react-i18next";
import { useWorkDayStats } from "../../hooks/useWorkDayStats";
import { CalendarDayContainer } from "../calendar/main/CalendarDayContainer";

const workStatsSelector = createSelector(
  state => state.calendar.workStats,
  (state, date) => date,
  (workStats, date) =>
    (workStats || []).find(stats =>
      moment(stats.date, "YYYY-MM-DD").isSame(date, "day")
    ) || {}
);

export const TaskCalendarDayStatistic = ({ calendarDay, isHoliday, date }) => {
  const { t } = useTranslation();
  const workStats = useSelector(state => workStatsSelector(state, calendarDay));
  const company = useSelector(state => state.auth.company);

  const {
    start,
    end,
    plan,
    count,
    isLateStart,
    isEarlierStart,
    isLateEnd,
    isEarlierEnd
  } = useWorkDayStats(workStats, company);

  return (
    <CalendarDayContainer
      calendarDay={calendarDay}
      date={date}
      isHoliday={isHoliday}
      isLoading={!workStats.date}
    >
      {workStats.date && (count !== undefined || !isHoliday) && (
        <div className="kt-widget12 text-center">
          <div className="kt-widget12__content">
            <div className="kt-widget12__item">
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">
                  <Trans>Запуск WorkApp</Trans>
                </span>
                <span
                  className={clsx("kt-widget12__value-no-color btn", {
                    "btn-label-brand": start && !isLateStart && !isEarlierStart,
                    "btn-label-danger":
                      start && (isEarlierStart || isLateStart),
                    "btn-label-warning": !start
                  })}
                >
                  {start || t("Не запускалось")}
                </span>
              </div>
            </div>
            <div className="kt-widget12__item">
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">
                  <Trans>Завершение рабочего дня</Trans>
                </span>
                <span
                  className={clsx("kt-widget12__value-no-color btn", {
                    "btn-label-brand": end && !isLateEnd && !isEarlierEnd,
                    "btn-label-danger": end && (isEarlierEnd || isLateEnd),
                    "btn-label-warning": !end
                  })}
                >
                  {end || t("Не завершён")}
                </span>
              </div>
            </div>
            <div className="kt-widget12__item">
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">
                  <Trans>План</Trans>
                </span>
                <span className="kt-widget12__value">
                  {plan} <Trans>ч</Trans>.
                </span>
              </div>
            </div>
            <div className="kt-widget12__item">
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">
                  <Trans>Факт</Trans>
                </span>
                <span className="kt-widget12__value">
                  {count || 0} <Trans>ч</Trans>.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </CalendarDayContainer>
  );
};
