import React, { useMemo, useState } from "react";
import { dateFormatter, usePaginationData } from "./utils";
import { PROMOCODE_URL } from "../../constants/api";
import { PaginatedTable } from "./PaginatedTable";
import { Button, ButtonGroup } from "react-bootstrap";
import { Hint } from "../Hint";
import { useModalState } from "../../hooks/useModalState";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";
import { PromocodeModal } from "../promocode/PromocodeModal";
import { updatePromocode } from "../../redux/thunks/promocode.thunk";

const useColumns = (setPromocode, open, dispatch, refresh) => {
  return useMemo(
    () => [
      {
        dataField: "code",
        text: "Код"
      },
      {
        dataField: "createdAt",
        text: "Дата создания",
        formatter: dateFormatter
      },
      {
        dataField: "validUntil",
        text: "Дата истечения",
        formatter: dateFormatter
      },
      { dataField: "tariffName", text: "Название тарифа" },
      { dataField: "activationCount", text: "Количество активаций" },
      {
        dataField: "",
        isDummyField: true,
        style: { width: "1px" },
        text: "Действия",
        formatExtraData: { setPromocode, open, dispatch, refresh },
        formatter: (cell, row, rowIndex, extraData) => {
          const { open, setPromocode, dispatch, refresh } = extraData;
          return (
            <ButtonGroup size="md">
              <Hint text="Редактировать">
                <Button
                  className="btn-icon"
                  variant="outline-info"
                  size="md"
                  onClick={() => {
                    setPromocode(row);
                    open();
                  }}
                >
                  <i className="flaticon2-pen" />
                </Button>
              </Hint>
              <Hint text={row.disabled ? "Включить" : "Отключить"}>
                <Button
                  className="btn-icon"
                  variant={row.disabled ? "outline-success" : "outline-danger"}
                  size="md"
                  onClick={async () => {
                    await dispatch(
                      updatePromocode(row.id, { disabled: !row.disabled })
                    );
                    await refresh();
                  }}
                >
                  <i
                    className={
                      row.disabled ? "flaticon2-check-mark" : "flaticon2-cross"
                    }
                  />
                </Button>
              </Hint>
            </ButtonGroup>
          );
        }
      }
    ],
    []
  );
};

export const PromocodesTable = () => {
  const dispatch = useDispatch();
  const [promocode, setPromocode] = useState(null);
  const { state, onTableChange, refresh } = usePaginationData(
    `${PROMOCODE_URL}`
  );
  const [open, close, isOpen] = useModalState(false);
  const columns = useColumns(setPromocode, open, dispatch, refresh);
  return (
    <>
      <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">Промокоды</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="kt-portlet__head-actions">
              <Button
                variant="outline-success"
                size="sm"
                onClick={() => {
                  setPromocode(null);
                  open();
                }}
              >
                <i className="flaticon2-plus" />
                <Trans>Добавить</Trans>
              </Button>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body">
          <PaginatedTable
            state={state}
            onTableChange={onTableChange}
            columns={columns}
          />
        </div>
      </div>
      <PromocodeModal
        promocode={promocode}
        show={isOpen}
        close={close}
        refresh={refresh}
      />
    </>
  );
};
