import React from "react";
import { ProjectsTable } from "../../components/projects/ProjectsTable";

export const Projects = () => {
  return (
    <div className="row settings__layout">
      <div className="col-12">
        <ProjectsTable />
      </div>
    </div>
  );
};
