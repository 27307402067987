import React from "react";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/browser";

class ErrorCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, count: 0 };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  componentDidCatch(error, errorInfo) {
    if (!this.props.history.location.pathname.match("error")) {
      // if (process.env.NODE_ENV !== "development") {
      //   Sentry.withScope(scope => {
      //     scope.setExtras(errorInfo);
      //     Sentry.captureException(error);
      //   });
      // }
      this.props.history.replace("/error");
    }
  }

  render() {
    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(ErrorCatcher);
