import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { CalendarSidebarItem } from "../components/calendar/sidebar/CalendarSidebarItem";
import { useLocation, useRouteMatch } from "react-router";

export const useSidebarProjectsList = ({ data, currentId }) => {
  const { params } = useRouteMatch();
  const { pathname } = useLocation();
  return useMemo(
    () =>
      data.map((item, index) => (
        <Link
          key={item.id}
          to={pathname.replace(params.id, item.id)}
          className={
            item.id === currentId
              ? "kt-notification-v2__item active"
              : "kt-notification-v2__item"
          }
        >
          <CalendarSidebarItem
            icon="flaticon2-pie-chart"
            index={index}
            title={item.name}
            subtitle={item.description}
          />
        </Link>
      )),
    [data, currentId, pathname]
  );
};
