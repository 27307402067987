import { dateFormatter } from "../tables/utils";
import React, { useCallback, useMemo } from "react";
import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Trans } from "react-i18next";

const useStyles = makeStyles(theme => {
  return {
    item: {
      padding: "1.1rem !important",
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.background.default,
          0.1
        )} !important`
      }
    },
    selected: {
      backgroundColor: `${lighten(theme.palette.success.light, 0.2)}`,
      "&:hover": {
        backgroundColor: `${lighten(
          theme.palette.success.light,
          0.5
        )} !important`
      }
    }
  };
});

export const ProjectsToMergeList = ({
  selectedProject,
  projects,
  selectProject
}) => {
  const projectsList = useMemo(
    () =>
      projects.map(project => (
        <ProjectsToMergeItem
          key={project.id}
          project={project}
          selectedProject={selectedProject}
          selectProject={selectProject}
        />
      )),
    [selectedProject, projects]
  );

  return (
    <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            <Trans>Объединяемые проекты</Trans>
          </h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <Alert className="mb-3" severity="info">
          <Trans>
            <AlertTitle>Здесь находятся объединяемые проекты</AlertTitle>
            Кликните по проекту, который хотите взять за основу для объединения
          </Trans>
        </Alert>
        <div className="kt-widget1" style={{ padding: "0" }}>
          {projectsList}
        </div>
      </div>
    </div>
  );
};

const ProjectsToMergeItem = ({ project, selectProject, selectedProject }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (window.getSelection().toString() === "") {
      selectProject(project);
    }
  }, [project]);

  return (
    <div
      className={clsx("kt-widget1__item", classes.item, {
        [classes.selected]: selectedProject && project.id === selectedProject.id
      })}
      onClick={handleClick}
    >
      <div className="kt-widget1__info">
        <h3 className="kt-widget1__title">
          <Trans>Название: {{ name: project.name }}</Trans>
        </h3>
        <span className="kt-widget1__desc">
          <Trans>
            Описание:{" "}
            {{
              description: project.description
                ? project.description
                : "Отсутствует"
            }}
          </Trans>
        </span>
      </div>
      <div className="d-flex flex-column">
        {/* prettier-ignore */}
        <Trans>
          Дата создания: <span className={`kt-widget1__number`} style={{ fontWeight: "initial" }}>{{ date: dateFormatter(project.createdAt) }}</span>
        </Trans>
      </div>
    </div>
  );
};
