import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { rootReducer } from "./rootReducer";
import { createLogger } from "redux-logger";
import { accessTokenSelector, setAuthHeader } from "../crud/axios";
import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";
import {
  createStateSyncMiddleware,
  initStateWithPrevTab
} from "redux-state-sync";
import { setUseProxies } from "immer";
setUseProxies(true);

const middlewares = [];
const persistConfig = {
  storage,
  key: "root",
  whitelist: ["auth", "tutorial", "subscription", "paginationConfig"]
};

let logger = createLogger({
  level: "info",
  collapsed: true
});

if (process.env.NODE_ENV === "development") {
  middlewares.push(logger);
}

if (process.env.NODE_ENV !== "development") {
  // middlewares.push(createSentryMiddleware(Sentry));
}

const persistedReducer = persistReducer(persistConfig, (state, action) => {
  if (action.type === "RESET") {
    storage.removeItem("persist:root");
    state = undefined;
  }
  return rootReducer(state, action);
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false
    }),
    ...middlewares,
    createStateSyncMiddleware({
      prepareState: state => ({ auth: state.auth }),
      whitelist: [
        "auth/saveTokens",
        "auth/setAuthTokens",
        "auth/saveCompany",
        "auth/setUser",
        "auth/logout",
        "auth/loginUser"
      ]
    })
  ],
  devTools: process.env.NODE_ENV === "development"
});

initStateWithPrevTab(store);

store.subscribe(() => {
  const accessToken = accessTokenSelector(store.getState());
  setAuthHeader(accessToken);
});

export const persistor = persistStore(store);
export default store;
