import { createReducer, combineReducers } from "@reduxjs/toolkit";
import auth from "./features/authFeatureSlice";
import settings from "./features/settingsFeatureSlice";
import calendar from "./features/calendarFeatureSlice";
import snackbar from "./features/snackbarFeatureSlice";
import impersonate from "./features/impersonatePerformerFeatureSlice";
import tutorial from "./features/tutorialFeatureSlice";
import popperTask from "./features/popperTaskFeatureSlice";
import search from "./features/searchFeatureSlice";
import score from "./features/scoreFeatureSlice";
import projects from "./features/projectsFeatureSlice";
import subscription from "./features/subscriptionFeatureSlice";
import paginationConfig from "./features/paginationConfigFeatureSlice";
import { metronic } from "../../_metronic";

const rehydrate = createReducer(false, {
  REHYDRATE: (state, action) => (state = true)
});

export const rootReducer = combineReducers({
  auth,
  calendar,
  settings,
  snackbar,
  impersonate,
  tutorial,
  paginationConfig,
  search,
  score,
  projects,
  subscription,
  popperTask,
  i18n: metronic.i18n,
  builder: metronic.builder,
  rehydrate
});
