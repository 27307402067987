import { useDispatch } from "react-redux";
import React, { useCallback } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { Hint } from "../Hint";
import { deleteProject } from "../../redux/thunks/project.thunk";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const ProjectsTableActionButtons = ({
  row,
  open,
  refresh,
  setProject
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onEdit = useCallback(() => {
    setProject(row);
    open();
  }, [row]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteProject(row.id));
    await refresh();
  }, [row]);

  return (
    <ButtonGroup size="md">
      <Hint text={t("Редактировать")}>
        <Button
          className="btn-icon"
          variant="outline-info"
          size="md"
          onClick={onEdit}
        >
          <i className="flaticon2-pen" />
        </Button>
      </Hint>
      <Hint text={t("Сводка")}>
        <Link
          className="btn-icon btn btn-outline-success btn-md"
          to={`/projects/statistic/${row.id}/summary`}
        >
          <i className="flaticon2-chart2" />
        </Link>
      </Hint>
      <Hint text={row.deleted ? t("Восстановить") : t("Удалить")}>
        <Button
          className="btn-icon"
          variant={row.deleted ? "outline-success" : "outline-danger"}
          size="md"
          onClick={onDelete}
        >
          <i
            className={row.deleted ? "flaticon2-check-mark" : "flaticon2-cross"}
          />
        </Button>
      </Hint>
    </ButtonGroup>
  );
};
