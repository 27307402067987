import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { TaskCalendarRangeDialog } from "./TaskCalendarRangeDialog";
import { Trans } from "react-i18next";
import moment from "moment";
import { extendMoment } from "moment-range";
import { Link } from "react-router-dom";
import {
  resetScore,
  scoreProjects
} from "../../redux/features/scoreFeatureSlice";
import { tasksSelector } from "../../redux/features/calendarFeatureSlice";

extendMoment(moment);

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    textAlign: "right",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "start",
    [theme.breakpoints.down("sm")]: {
      marginTop: 10
    }
  }
}));

const scoresSelector = createSelector(
  state => state.score,
  scores => scores
);

export const TaskCalendarHeaderButtons = ({ userId, date }) => {
  const dispatch = useDispatch();
  const tasks = useSelector(state => tasksSelector(state, date));
  const scores = useSelector(scoresSelector);
  const classes = useStyles();

  useEffect(() => {
    if (scores && scores.length) {
      dispatch(scoreProjects(tasks));
    }
  }, [JSON.stringify(tasks)]);

  const handleClick = useCallback(() => {
    if (scores && scores.length) {
      dispatch(resetScore({ scores: [] }));
    } else {
      dispatch(scoreProjects(tasks));
    }
  }, [tasks]);

  return (
    <div className={`${classes.container}`}>
      <button
        onClick={handleClick}
        style={scores && scores.length ? { color: "#591df1" } : null}
        type="button"
        className="btn btn-secondary"
      >
        <Trans>Скоринг</Trans>
      </button>
      <Link to={`/work-statistic/${userId}`} className="btn btn-secondary">
        <Trans>Подробнее</Trans>
      </Link>
      <TaskCalendarRangeDialog userId={userId} date={date} />
    </div>
  );
};
