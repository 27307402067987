import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
const colors = {
  0: "#fa5676",
  1: "#ffb04d",
  2: "#94e687",
  3: "#3598f1",
  4: "#bdc7cf",
  5: "#44cfc7",
  6: "#c27acf"
};

const scoreSlice = createSlice({
  initialState,
  name: "score",
  reducers: {
    setScore: (state, action) => {
      return action.payload;
    },
    resetScore: () => initialState
  }
});

export default scoreSlice.reducer;
export const { setScore, resetScore } = scoreSlice.actions;

export const calculateScore = tasks => {
  return tasks.reduce((acc, task) => {
    if ((!task.projects || task.projects.length === 0) && task.projectText) {
      if (acc[task.projectText]) {
        acc[task.projectText] += 1;
      } else {
        acc[task.projectText] = 1;
      }

      return acc;
    }

    if (task.projects.length) {
      for (const project of task.projects) {
        if (acc[project.name]) {
          acc[project.name] += 1 / task.projects.length;
        } else {
          acc[project.name] = 1 / task.projects.length;
        }
      }
    }

    return acc;
  }, {});
};

export const scoreProjects = tasks => dispatch => {
  const scoring = [];
  const scoreMap = calculateScore(tasks);

  for (const [projectName, totalSpent] of Object.entries(scoreMap)) {
    scoring.push({ score: totalSpent, name: projectName });
  }

  scoring.sort((a, b) => b.score - a.score);

  scoring.forEach((score, i) => {
    if (i < 7) {
      score.color = colors[i]; /* stringToColour(score.name); */
    } else {
      score.color = undefined;
    }
  });

  dispatch(setScore(scoring));
};
