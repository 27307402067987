import { TaskCalendarSidebar } from "./TaskCalendarSidebar";
import { TaskCalendarHeader } from "./TaskCalendarHeader";
import { TaskCalendarBody } from "./TaskCalendarBody";
import React from "react";
import { useTaskCalendar } from "../../hooks/useTaskCalendar";
import { Calendar } from "../calendar/Calendar";
import { TaskDialogPopper } from "./TaskDialogPopper";

export const TaskCalendar = ({ currentDate, initialUserId }) => {
  const {
    userId,
    handleOnPrevMonth,
    handleOnNextMonth,
    date,
    days
  } = useTaskCalendar({ currentDate, userId: initialUserId });

  return (
    <Calendar
      sidebar={
        <TaskCalendarSidebar
          userId={userId}
          date={date}
          handleOnPrevMonth={handleOnPrevMonth}
          handleOnNextMonth={handleOnNextMonth}
        />
      }
      header={
        <TaskCalendarHeader
          date={date}
          userId={userId}
          handleOnNextMonth={handleOnNextMonth}
          handleOnPrevMonth={handleOnPrevMonth}
        />
      }
    >
      <TaskCalendarBody userId={userId} days={days} date={date} />
      <TaskDialogPopper userId={userId} />
    </Calendar>
  );
};
