import React from "react";
import { Redirect, Route } from "react-router-dom";

const isAllowed = (user, allowedRoles) => {
  let allowed = true;
  allowed &= user.status === "active";
  allowed &= allowedRoles.includes(user.role.name);
  return allowed;
};

export const ProtectedRoute = ({
  children,
  allowedRoles,
  authInfo,
  ...props
}) => {
  const { user } = authInfo;

  return (
    <Route
      {...props}
      render={({ location }) => {
        if (!user || !isAllowed(user, allowedRoles)) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          );
        }

        return children;
      }}
    />
  );
};
