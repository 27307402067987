import React from "react";
import { RegistrationsTable } from "../../components/tables/RegistrationsTable";

export const Registrations = () => {
  return (
    <div className="row">
      <div className="col-12">
        <RegistrationsTable />
      </div>
    </div>
  );
};
