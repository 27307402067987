import axios from "axios";
import {
  LOGIN_URL,
  REGISTER_URL,
  REQUEST_PASSWORD_URL,
  VERIFY_EMAIL_URL,
  COMPANY_BOOTSTRAP,
  RESET_PASSWORD_URL,
  IMPERSONATE_URL
} from "../constants/api";

export async function login(email, password, rememberMe, lang) {
  return axios.post(LOGIN_URL, { email, password, rememberMe, lang });
}

export async function register(name, email, password, passwordRepeat, lang) {
  return await axios.post(REGISTER_URL, {
    name,
    email,
    password,
    passwordRepeat,
    lang
  });
}

export const impersonateById = id => {
  return axios.post(IMPERSONATE_URL, { id });
};

export async function requestPassword(email) {
  return await axios.post(REQUEST_PASSWORD_URL, { email });
}

export async function verifyEmail(token) {
  return await axios.get(VERIFY_EMAIL_URL, { params: { token } });
}

export async function resetPassword(token, password, passwordRepeat) {
  return await axios.post(
    RESET_PASSWORD_URL,
    { password, passwordRepeat },
    { params: { token } }
  );
}

export async function bootstrap({
  company,
  email,
  positionName,
  timeZone,
  workStartsAt,
  workEndsAt
}) {
  return await axios.post(COMPANY_BOOTSTRAP, {
    positionName,
    company: {
      name: company,
      email,
      timeZone,
      workStartsAt,
      workEndsAt
    }
  });
}
