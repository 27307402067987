import React from "react";
import { ProjectsCalendar } from "../../components/projects/calendar/ProjectsCalendar";
import moment from "moment";
import { useParams } from "react-router";

export const ProjectsCalendarPage = () => {
  const date = moment();
  const { id } = useParams();
  return <ProjectsCalendar currentDate={date} initialId={id} />;
};
