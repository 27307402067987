import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditCompanyForm from "../../components/forms/EditCompanyForm";
import ModalChangePassword from "../../components/modals/ModalChangePassword";
import getTimezoneEntries from "../../utils/getTimezones";
import SettingsCompanyHeader from "../../components/settings/company/SettingsCompanyHeader";
import SettingsMainParams from "../../components/settings/params/SettingsMainParams";

const timezones = getTimezoneEntries();

export default function Settings() {
  const { user, company } = useSelector(state => state.auth);
  const [isVisibleEditCompanyModal, toggleEditCompanyModal] = useState(false);
  const [isVisibleChangePasswordModal, toggleChangePasswordModal] = useState(
    false
  );

  return (
    <>
      <div className="row settings__layout">
        <div className="col-md-6">
          <SettingsMainParams
            user={user}
            handlePasswordChange={() => toggleChangePasswordModal(true)}
          />
        </div>
        <div className="col-md-6">
          <div className="kt-portlet">
            <SettingsCompanyHeader />
            <div className="kt-portlet__body">
              <EditCompanyForm
                timezones={timezones}
                company={company}
                hideModal={toggleEditCompanyModal}
                isVisible={isVisibleEditCompanyModal}
              />
            </div>
          </div>
        </div>
      </div>

      <ModalChangePassword
        isVisible={isVisibleChangePasswordModal}
        hideModal={() => toggleChangePasswordModal(false)}
        id={user.id}
      />
    </>
  );
}
