import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "react-i18next";
import { tasksSelector } from "../../redux/features/calendarFeatureSlice";
import { getHours, getMinutes } from "../../utils/getTime";

const scoresSelector = createSelector(
  state => state.score,
  scores => scores
);

export const TaskScoringInfo = ({ date }) => {
  const scores = useSelector(scoresSelector);
  const tasks = useSelector(state => tasksSelector(state, date));
  const totalHours = tasks.length;
  return (
    <div className="row row-col-separator-lg row-no-padding ">
      {scores &&
        scores
          .slice(0, 7)
          .map(score => (
            <TaskScore
              key={score.name}
              scoring={score}
              totalHours={totalHours}
            />
          ))}
      {scores && scores.length > 7 && (
        <CombinedScore scores={scores} totalHours={totalHours} />
      )}
    </div>
  );
};

const TaskScore = ({ totalHours, scoring }) => {
  const totalPercent = (scoring.score / (totalHours / 100)).toFixed(2);
  const [hours, setHours] = useState(() => getHours(scoring));
  const [minutes, setMinutes] = useState(() => getMinutes(scoring));

  useEffect(() => {
    setHours(getHours(scoring.score));
    setMinutes(getMinutes(scoring.score));
  }, [scoring]);

  return (
    <div className="col-md-12 col-lg-6 col-xl-3 scoring-item">
      <div className="kt-widget24" style={{ padding: "none" }}>
        <div className="kt-widget24__details">
          <div className="kt-widget24__info">
            <h4 className="kt-widget24__title">{scoring.name}</h4>
          </div>
          <span className="kt-widget24__stats" style={{ color: scoring.color }}>
            {hours} <Trans>ч</Trans> {minutes} <Trans>м</Trans>
          </span>
        </div>

        <div className="progress progress--sm">
          <div
            style={{
              backgroundColor: scoring.color,
              width: `${totalPercent}%`
            }}
            className="progress-bar"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div className="kt-widget24__action">
          <span className="kt-widget24__change">{totalPercent}%</span>
          <span className="kt-widget24__number">
            {hours} <Trans>ч</Trans> {minutes} <Trans>м</Trans>{" "}
            <Trans>из</Trans> {totalHours} <Trans>ч</Trans>
          </span>
        </div>
      </div>
    </div>
  );
};

const CombinedScore = ({ scores, totalHours }) => {
  const other = useMemo(
    () =>
      scores.slice(7).reduce(
        (acc, score) => {
          acc.score = score.score + (acc.score || 0);
          return acc;
        },
        { name: "Другое" }
      ),
    [scores]
  );

  const totalPercent = (other.score / (totalHours / 100)).toFixed(2);
  const [hours, setHours] = useState(() => getHours(other.score));
  const [minutes, setMinutes] = useState(() => getMinutes(other.score));

  useEffect(() => {
    setHours(getHours(other.score));
    setMinutes(getMinutes(other.score));
  }, [other]);
  return (
    <div className="col-md-12 col-lg-6 col-xl-3 scoring-item">
      <div className="kt-widget24" style={{ padding: "none" }}>
        <div className="kt-widget24__details">
          <div className="kt-widget24__info">
            <h4 className="kt-widget24__title">{other.name}</h4>
          </div>
          <span className="kt-widget24__stats">
            {hours} <Trans>ч</Trans> {minutes} <Trans>м</Trans>
          </span>
        </div>

        <div className="progress progress--sm">
          <div
            style={{
              width: `${totalPercent}%`
            }}
            className="progress-bar kt-bg-dark"
            role="progressbar"
            aria-valuenow="50"
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
        <div className="kt-widget24__action">
          <span className="kt-widget24__change">{totalPercent}%</span>
          <span className="kt-widget24__number kt-font-dark">
            {hours} <Trans>ч</Trans> {minutes} <Trans>м</Trans>{" "}
            <Trans>из</Trans> {totalHours} <Trans>ч</Trans>
          </span>
        </div>
      </div>
    </div>
  );
};
