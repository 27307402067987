import { Button, Modal } from "react-bootstrap";
import { Trans } from "react-i18next";
import React, { useCallback, useReducer } from "react";
import { createSlice } from "@reduxjs/toolkit";

export const useModal = () => {
  const initialState = {
    open: false,
    props: {},
    submitText: undefined,
    onSubmit: () => {}
  };
  const {
    actions: { open, close },
    reducer
  } = createSlice({
    name: "modal-hook",
    initialState,
    reducers: {
      open: (state, action) => {
        const { contentProps, submitText, onSubmit } = action.payload;
        state.props = contentProps;
        state.submitText = submitText;
        state.onSubmit = onSubmit;
        state.open = true;
      },
      close: () => initialState
    }
  });

  const [state, dispatch] = useReducer(reducer, initialState);
  const openModal = ({ contentProps, submitText, onSubmit }) =>
    dispatch(open({ contentProps, submitText, onSubmit }));
  const closeModal = () => dispatch(close());

  const ModalComponent = ({ title, children }) => {
    const { open, props, submitText, onSubmit } = state;
    const submitAndClose = useCallback(async () => {
      await onSubmit(props);
      closeModal();
    }, [onSubmit]);
    return (
      <Modal
        centered
        size="lg"
        className="modal fade"
        show={open}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children(props)}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            <Trans>Закрыть</Trans>
          </Button>
          {submitText && (
            <Button variant="success" onClick={submitAndClose}>
              {submitText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return { openModal, closeModal, Modal: ModalComponent };
};
