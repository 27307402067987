import { captureException } from "@sentry/browser";
import i18n from "../i18n/i18n";

export const extractErrorInfo = error => {
  // captureException(error);

  if (isAxiosError(error)) {
    if (error.response.status === 502) {
      return {
        header: i18n.t("Ошибка"),
        message: i18n.t(
          "Сервер находится в процессе обновления и скоро снова станет доступен"
        )
      };
    }

    return {
      header: i18n.t("Ошибка:"),
      message:
        error.response.data.message ||
        i18n.t("Что-то пошло не так, повторите попытку позднее")
    };
  }

  if (isConnectionFailure(error)) {
    return {
      header: i18n.t("Ошибка подключения"),
      message: i18n.t("Проверьте ваше подключение к сети")
    };
  }

  return {
    header: i18n.t("Произошла непредвиденная ошибка"),
    message: i18n.t(
      "Если ошибка возникла снова - свяжитесь с технической поддержкой в личном кабинете"
    )
  };
};

const isAxiosError = error => {
  return !!error.response && error.isAxiosError;
};

const isConnectionFailure = error => {
  return !error.response && error.isAxiosError;
};
