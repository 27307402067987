import { bootstrap } from "../../crud/auth.crud";
import {
  saveCompany,
  setStatus,
  updateUserInfo
} from "../../redux/features/authFeatureSlice";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { getUsersList } from "./user.thunk";
import { shouldOpenTutorial } from "../features/tutorialFeatureSlice";
import { batch } from "react-redux";
import { fetchSubscription } from "../features/subscriptionFeatureSlice";

export default function thunkOnBootstrapCompany(values) {
  return async (dispatch, getState) => {
    return new Promise(resolve =>
      batch(async () => {
        try {
          const user = getState().auth.user;
          const { positionName } = values;
          const { data } = await bootstrap(values);
          dispatch(saveCompany({ company: data }));
          dispatch(updateUserInfo({ user: { ...user, positionName } }));
          await dispatch(getUsersList());
          dispatch(setStatus({ status: "active" }));
          dispatch(fetchSubscription());
          dispatch(shouldOpenTutorial());
        } catch (e) {
          const { header, message } = extractErrorInfo(e);
          dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
        }
        resolve();
      })
    );
  };
}
