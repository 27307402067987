import { createSlice } from "@reduxjs/toolkit";
import {
  reset as resetSearch,
  setSelectedProjects
} from "./searchFeatureSlice";
import { projectsToOptions } from "../../hooks/useSearchState";
import { batch } from "react-redux";

const initialState = {
  task: undefined,
  target: undefined,
  isOpen: false,
  isTransiting: false,
  hasWarning: false,
  loading: false
};
let timeout;
export const TRANSITION_DURATION = 100;

const popperTaskSlice = createSlice({
  initialState,
  name: "popperTask",
  reducers: {
    setOpen: (state, action) => {
      const { target, task } = action.payload;
      state.isTransiting = true;
      state.task = task;
      state.target = target;
      state.isOpen = true;
    },
    setWarning: (state, action) => {
      state.hasWarning = true;
    },
    endTransition: state => {
      state.isTransiting = false;
    },
    updateTarget: (state, action) => {
      state.target = action.payload;
    },
    close: state => {
      state.isOpen = false;
      state.isTransiting = true;
    },
    reset: () => initialState
  }
});

export const toggleTaskDialog = payload => dispatch => {
  const { task } = payload;

  if (timeout) {
    clearTimeout(timeout);
    timeout = undefined;
  }

  batch(() => {
    dispatch(resetSearch());
    dispatch(
      setSelectedProjects(task.id ? projectsToOptions(task.projects) : [])
    );
    dispatch(setOpen(payload));
  });

  setTimeout(() => {
    dispatch(endTransition());
  }, TRANSITION_DURATION);
};

export const closeTaskDialog = () => dispatch => {
  batch(() => {
    dispatch(close());
    timeout = setTimeout(() => {
      dispatch(reset());
      timeout = undefined;
    }, TRANSITION_DURATION);
  });
};

export default popperTaskSlice.reducer;
export const {
  setOpen,
  setWarning,
  endTransition,
  updateTarget,
  close,
  reset
} = popperTaskSlice.actions;
