import React from "react";
import { PromocodesTable } from "../../components/tables/PromocodesTable";

export const Promocodes = () => {
  return (
    <div className="row">
      <div className="col-12">
        <PromocodesTable />
      </div>
    </div>
  );
};
