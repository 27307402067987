import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { TaskCalendarItem } from "./TaskCalendarItem";
import { createSelector } from "reselect";
import moment from "moment";
import { extendMoment } from "moment-range";
import { CalendarDayContainer } from "../calendar/main/CalendarDayContainer";
extendMoment(moment);

const createTasksList = (tasks, calendarDay, userId, company, isHoliday) => {
  if (!tasks) {
    return <></>;
  }

  const { start, end } = getWorkingHours(calendarDay, company);
  const range = moment.range(start, end);

  return Object.keys(tasks).map(time => {
    const [hours, minutes] = time.split(":").map(parseInt);
    const taskTime = calendarDay.clone().set({ hours, minutes });
    return (
      <TaskCalendarItem
        key={taskTime.format()}
        taskTime={taskTime}
        userId={userId}
        inSchedule={range.contains(taskTime, { excludeEnd: true })}
        isHoliday={isHoliday}
      />
    );
  });
};

const viewSelector = createSelector(
  state => state.calendar,
  calendar => calendar.view || {}
);

const getWorkingHours = (calendarDay, company) => {
  const [startHours, startMinutes] = company.workStartsAt
    .split(":")
    .map(parseInt);
  const [endHours, endMinutes] = company.workEndsAt.split(":").map(parseInt);
  const start = calendarDay
    .clone()
    .set({ hours: startHours, minutes: startMinutes });
  const end = calendarDay.clone().set({ hours: endHours, minutes: endMinutes });
  return { start, end };
};

export const TaskCalendarDay = ({ calendarDay, isHoliday, date, userId }) => {
  const view = useSelector(viewSelector);
  const company = useSelector(state => state.auth.company);
  const tasks = view[calendarDay.format("YYYY-MM-DD")];
  const dayInWeek = calendarDay.day();

  const tasksList = useMemo(
    () =>
      createTasksList(
        tasks,
        calendarDay,
        userId,
        company,
        dayInWeek === 0 || dayInWeek === 6 || isHoliday
      ),
    [userId, JSON.stringify(tasks), calendarDay.format()]
  );

  return (
    <CalendarDayContainer
      calendarDay={calendarDay}
      date={date}
      isHoliday={isHoliday}
      isLoading={!Array.isArray(tasksList)}
    >
      {tasksList}
    </CalendarDayContainer>
  );
};
