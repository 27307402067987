import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const Hint = ({ children, text, placement = "top", show = true }) => {
  if (!show) {
    return children;
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={<Tooltip id={`tooltip-${placement}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};
