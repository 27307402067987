import React, { useRef, useState } from "react";
import { PROJECT_URL } from "../../constants/api";
import { useModalState } from "../../hooks/useModalState";
import { useDispatch, useSelector } from "react-redux";
import { createUseColumns } from "../../hooks/createUseColumns";
import { useControlledSelectRow } from "../../hooks/useControlledSelectRow";
import { lighten } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { ProjectSelectHelperPopper } from "./ProjectSelectHelperPopper";
import { ProjectsTableHeader } from "./ProjectsTableHeader";
import { dateFormatter, usePaginationData } from "../tables/utils";
import { PaginatedTable } from "../tables/PaginatedTable";
import { ProjectsTableActionButtons } from "./ProjectsTableActionButtons";
import { ProjectModal } from "./ProjectModal";
import { Trans } from "react-i18next";

const createColumns = extraData => [
  {
    dataField: "name",
    text: <Trans>Назввание</Trans>,
    style: { verticalAlign: "middle" },
    sort: true
  },
  {
    dataField: "createdAt",
    text: <Trans>Дата создания</Trans>,
    style: { verticalAlign: "middle" },
    formatter: dateFormatter,
    sort: true
  },
  {
    dataField: "",
    isDummyField: true,
    style: { width: "1px" },
    text: <Trans>Действия</Trans>,
    formatExtraData: extraData,
    formatter: (cell, row, rowIndex, extraData) => {
      const { open, setProject, refresh } = extraData;
      return (
        <ProjectsTableActionButtons
          row={row}
          open={open}
          setProject={setProject}
          refresh={refresh}
        />
      );
    }
  }
];

const useColumns = createUseColumns(createColumns);

const useStyles = makeStyles(theme => ({
  selectedRow: {
    backgroundColor: theme.palette.success.light,
    "&:hover": {
      backgroundColor: `${lighten(theme.palette.success.light, 0.5)} !important`
    }
  },
  toMergeRow: {
    backgroundColor: theme.palette.warning.light,
    "&:hover": {
      backgroundColor: `${lighten(theme.palette.warning.light, 0.5)} !important`
    }
  }
}));

const defaultSelectRowConfig = {
  mode: "checkbox",
  clickToSelect: true,
  selectColumnStyle: { verticalAlign: "middle" },
  headerColumnStyle: { width: "1px" }
};

export const ProjectsTable = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const ref = useRef(null);
  const [mode, setMode] = useState("regular");
  const [project, setProject] = useState(null);
  const selectRow = useControlledSelectRow(
    defaultSelectRowConfig,
    mode === "regular",
    classes.toMergeRow
  );
  const { selected } = selectRow;
  const {
    state,
    onTableChange,
    refresh,
    onRangeChange,
    onRangeCancel
  } = usePaginationData(`${PROJECT_URL}`);
  const [open, close, isOpen] = useModalState(false);
  const columns = useColumns({ setProject, open, dispatch, refresh });
  const initialConfig = useSelector(
    state => state.paginationConfig[PROJECT_URL] || {}
  );
  return (
    <>
      <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space kt-portlet--height-fluid">
        <ProjectsTableHeader
          mode={mode}
          selected={selected}
          open={open}
          setProject={setProject}
          setMode={setMode}
        />

        <div className="kt-portlet__body">
          <PaginatedTable
            hover
            ref={ref}
            state={state}
            onRangeChange={onRangeChange}
            onRangeCancel={onRangeCancel}
            onTableChange={onTableChange}
            columns={columns}
            defaultSorted={[
              {
                dataField: initialConfig.sortField,
                order: initialConfig.sortOrder
              }
            ]}
            selectRow={selectRow}
          />
        </div>
      </div>
      <ProjectModal
        project={project}
        show={isOpen}
        close={close}
        refresh={refresh}
      />
      <ProjectSelectHelperPopper
        containerRef={ref}
        text={
          <Trans>
            Выберите проекты, которые вы хотите объединить (Выбрано:{" "}
            {{ selected: selected.length }})
          </Trans>
        }
        isOpen={mode === "merge"}
      />
    </>
  );
};
