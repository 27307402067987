import React from "react";
import { ProjectsCalendar } from "../../components/projects/calendar/ProjectsCalendar";
import moment from "moment";
import { useParams } from "react-router";

export const ProjectView = () => {
  const date = moment();
  const { id } = useParams();

  return <ProjectsCalendar singleView currentDate={date} initialId={id} />;
};
