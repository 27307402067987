import { Calendar } from "../../calendar/Calendar";
import React from "react";
import { ProjectsCalendarSidebar } from "./ProjectsCalendarSidebar";
import { ProjectsCalendarHeader } from "./ProjectsCalendarHeader";
import { ProjectSummary } from "./ProjectSummary";
import { Redirect, Route, Switch } from "react-router-dom";
import { useLocation, useRouteMatch, generatePath } from "react-router";
import { ProjectDaysView } from "./ProjectDaysView";
import { useProjectsCalendar } from "../../../hooks/useProjectsCalendar";
import { ProjectTimeline } from "./ProjectTimeline";

export const ProjectsCalendar = ({
  currentDate,
  initialId,
  singleView = false
}) => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const {
    projectId,
    handleOnNextMonth,
    handleOnPrevMonth,
    date,
    days,
    project
  } = useProjectsCalendar({ currentDate, projectId: initialId, singleView });

  if (!projectId) {
    return null;
  }

  return (
    <Calendar
      singleView={singleView}
      hideWeeks={pathname.search("calendar") < 0}
      sidebar={
        <ProjectsCalendarSidebar
          date={date}
          projectId={projectId}
          handleOnPrevMonth={handleOnPrevMonth}
          handleOnNextMonth={handleOnNextMonth}
        />
      }
      header={
        <ProjectsCalendarHeader
          title={project ? project.name : undefined}
          date={date}
          singleView={singleView}
          projectId={projectId}
          handleOnPrevMonth={handleOnPrevMonth}
          handleOnNextMonth={handleOnNextMonth}
        />
      }
    >
      <Switch>
        <Route exact path={`${path}/summary`}>
          <ProjectSummary
            singleView={singleView}
            projectId={projectId}
            date={date}
          />
        </Route>
        <Route exact path={`${path}/calendar`}>
          <ProjectDaysView days={days} date={date} />
        </Route>
        <Route exact path={`${path}/timeline`}>
          <ProjectTimeline />
        </Route>
        {singleView ? (
          <Redirect
            exact
            from={path}
            to={`${generatePath(path, { id: projectId })}/calendar`}
          />
        ) : (
          <Redirect
            exact
            from={path}
            to={`${generatePath(path, { id: projectId })}/summary`}
          />
        )}
      </Switch>
    </Calendar>
  );
};
