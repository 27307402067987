import React, { useState, useEffect } from "react";
import "../../../_metronic/_assets/sass/pages/login/login-6.scss";
import { Alert } from "react-bootstrap";
import AuthLogo from "../../components/auth/AuthLogo";
import AuthHeader from "../../components/auth/AuthHeader";
import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "../../utils/use-query";
import { FormResetPassword } from "../../components/forms/FormResetPassword";
import { useHistory } from "react-router";
import { extractErrorInfo } from "../../utils/extractError";
import { resetPassword } from "../../crud/auth.crud";
import AuthContainer from "../../components/auth/AuthContainer";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onAuthentication } from "../../redux/features/authFeatureSlice";
import FormLogin from "../../components/forms/FormLogin";
import { AuthLayout, IntroduceMessage } from "../../components/auth/Container";

export function ResetPassword() {
  const query = useQuery();
  const token = query.get("token");
  const history = useHistory();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  useEffect(() => {
    if (!token) {
      history.replace("/auth/login");
    }
  }, [token]);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const onSubmit = async ({ password, passwordRepeat }, setSubmitting) => {
    try {
      await resetPassword(token, password, passwordRepeat);
      setLoading(false);
      disableLoading();
      setSubmitting(false);
      setType("success");
      setMessage(
        t(
          "Пароль был изменён. Сейчас вы будете перенаправлены на страницу авторизации"
        )
      );
      setTimeout(() => {
        history.replace("/auth/login");
      }, 4000);
    } catch (e) {
      setType("danger");
      setSubmitting(false);
      disableLoading();
      const { header, message } = extractErrorInfo(e);
      setMessage(`${header}. ${message || ""}`);
    }
  };

  return (
    <AuthContainer>
      <div className="kt-login__container">
        <div className="kt-login__body">
          <AuthLogo />
          <AuthHeader title={t("Сброс пароля")} />
          {message.length ? <Alert variant={type}>{message}</Alert> : null}
          <FormResetPassword
            enableLoading={enableLoading}
            loading={loading}
            loadingButtonStyle={loadingButtonStyle}
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </AuthContainer>
  );
}

const ResetPasswordForm = () => {
  const query = useQuery();
  const token = query.get("token");
  const history = useHistory();
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  useEffect(() => {
    if (!token) {
      history.replace("/auth/login");
    }
  }, [token]);

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const onSubmit = async ({ password, passwordRepeat }, setSubmitting) => {
    try {
      await resetPassword(token, password, passwordRepeat);
      setLoading(false);
      disableLoading();
      setSubmitting(false);
      setType("success");
      setMessage(
        t(
          "Пароль был изменён. Сейчас вы будете перенаправлены на страницу авторизации"
        )
      );
      setTimeout(() => {
        history.replace("/auth/login");
      }, 4000);
    } catch (e) {
      setType("danger");
      setSubmitting(false);
      disableLoading();
      const { header, message } = extractErrorInfo(e);
      setMessage(`${header}. ${message || ""}`);
    }
  };

  return (
    <>
      <a href="#" className="text-center pt-2">
        <img
          src="/media/auth/logo.png"
          style={{ maxHeight: "90px", maxWidth: "90px" }}
          alt=""
        />
      </a>
      <div className="d-flex flex-column-fluid flex-column flex-center">
        <div className="login-form login-signin py-11">
          {message.length ? <Alert variant={type}>{message}</Alert> : null}

          <div className="text-center pb-8">
            <h2 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
              <Trans>Сброс пароля</Trans>
            </h2>
          </div>

          <FormResetPassword
            enableLoading={enableLoading}
            loading={loading}
            loadingButtonStyle={loadingButtonStyle}
            onSubmit={onSubmit}
          />
        </div>
      </div>
      <center>
        <a href="https://linkodium.com" target="_blank">
          Linkodium
        </a>{" "}
        (2020)
      </center>
    </>
  );
};

export const NewResetPassword = () => {
  return (
    <AuthLayout left={<ResetPasswordForm />} right={<IntroduceMessage />} />
  );
};
