import React from "react";
import {
  dateFormatter,
  emptyPlaceholderFormatter,
  usePaginationData,
  userStatusFormatter
} from "./utils";
import { USER_URL } from "../../constants/api";
import { PaginatedTable } from "./PaginatedTable";
import { Button, ButtonGroup } from "react-bootstrap";
import { Hint } from "../Hint";
import { impersonate } from "../../redux/thunks/impersonate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

const createColumns = (dispatch, history) => {
  return [
    {
      dataField: "createdAt",
      text: "Дата регистрации",
      formatter: dateFormatter,
      sort: true
    },
    { dataField: "email", text: "Email", sort: true },
    { dataField: "name", text: "Имя", sort: true },
    {
      dataField: "status",
      text: "Статус",
      formatter: userStatusFormatter,
      sort: true
    },
    {
      dataField: "company.name",
      text: "Компания",
      formatter: emptyPlaceholderFormatter
    },
    {
      dataField: "totalCount",
      text: "Сотрудников",
      sort: true,
      formatter: cell => (cell ? cell : 0)
    },
    {
      dataField: "activity",
      text: "Активность",
      sort: true,
      formatter: cell => (cell ? cell : 0)
    },
    {
      dataField: "activityThisMonth",
      text: "В этом мес.",
      sort: true,
      formatter: cell => (cell ? cell : 0)
    },
    {
      dataField: "",
      text: "Действия",
      isDummyField: true,
      formatExtraData: [dispatch, history],
      style: { width: "1px" },
      formatter: (cell, row, rowIndex, extraData) => {
        const [dispatch, history] = extraData;
        return (
          <ButtonGroup size="md">
            <Hint text="Войти">
              <Button
                className="btn-icon"
                variant="outline-info"
                size="md"
                onClick={() => dispatch(impersonate(row.id, history))}
              >
                <i className="flaticon-users-1" />
              </Button>
            </Hint>
          </ButtonGroup>
        );
      }
    }
  ];
};

const defaultSorted = [
  {
    dataField: "createdAt",
    order: "desc"
  }
];

export const RegistrationsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state, onTableChange } = usePaginationData(
    `${USER_URL}/registrations-paginated`
  );
  const columns = createColumns(dispatch, history);
  return (
    <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--space kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Последние регистрации</h3>
        </div>
      </div>

      <div className="kt-portlet__body">
        <PaginatedTable
          state={state}
          onTableChange={onTableChange}
          columns={columns}
          defaultSorted={defaultSorted}
        />
      </div>
    </div>
  );
};
