import { useWorkSummary } from "../../hooks/useWorkSummary";
import moment from "moment";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export const WorkDaySummary = ({ appSessions, timeSpans }) => {
  const { t } = useTranslation();
  const { plan, actual, start, end, empty } = useWorkSummary(
    appSessions,
    timeSpans
  );

  if (!plan) {
    return <></>;
  }

  return (
    <div className="kt-widget kt-widget--project-1">
      <div className="kt-widget__body">
        <div className="kt-widget__stats justify-content-between">
          <div className="kt-widget__item text-center">
            <span className="kt-widget__date">
              <Trans>Запуск приложения WA</Trans>
            </span>
            <div className="kt-widget__label">
              <span className="btn btn-label-brand btn-sm btn-bold btn-upper">
                {start
                  ? moment(start).format("HH:mm DD-MM-YYYY")
                  : t("Не запускалось")}
              </span>
            </div>
          </div>

          <div className="kt-widget__item text-center">
            <span className="kt-widget__date">
              <Trans>Завершение р.д.</Trans>
            </span>
            <div className="kt-widget__label">
              <span className="btn btn-label-brand btn-sm btn-bold btn-upper">
                {end
                  ? moment(end).format("HH:mm DD-MM-YYYY")
                  : t("Не завершён")}
              </span>
            </div>
          </div>

          <div className="kt-widget__item text-center">
            <span className="kt-widget__date">
              <Trans>План</Trans>
            </span>
            <div className="kt-widget__label">
              <span className="btn btn-label-brand btn-sm btn-bold btn-upper">
                {moment(plan.to).diff(moment(plan.from), "hours")}{" "}
                <Trans>ч</Trans>.
              </span>
            </div>
          </div>

          <div className="kt-widget__item text-center">
            <span className="kt-widget__date">
              <Trans>Факт</Trans>
            </span>
            <div className="kt-widget__label">
              <span className="btn btn-label-brand btn-sm btn-bold btn-upper">
                {actual} <Trans>ч</Trans>.
              </span>
            </div>
          </div>

          <div className="kt-widget__item text-center">
            <span className="kt-widget__date">
              <Trans>Факт (с учётом пропусков)</Trans>
            </span>
            <div className="kt-widget__label">
              <span className="btn btn-label-brand btn-sm btn-bold btn-upper">
                {actual - empty} <Trans>ч</Trans>.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
