import React from "react";
import { Switch, Route } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { Projects } from "../pages/home/Projects";
import { ProjectsMerge } from "../pages/home/ProjectsMerge";
import { ProjectsCalendarPage } from "../pages/home/ProjectsCalendarPage";

export const ProjectRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={Projects} />
      <Route path={`${path}/merge`} component={ProjectsMerge} />
      <Route
        path={`${path}/statistic/:id([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12})?`}
        component={ProjectsCalendarPage}
      />
    </Switch>
  );
};
