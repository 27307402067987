import { createSlice } from "@reduxjs/toolkit";

export const initialState = {};

const paginationConfigFeatureSlice = createSlice({
  initialState,
  name: "paginationConfig",
  reducers: {
    setConfig: (state, action) => {
      const { key, config } = action.payload;
      state[key] = config;
    }
  }
});

export default paginationConfigFeatureSlice.reducer;
export const { setConfig } = paginationConfigFeatureSlice.actions;
