import moment from "moment";
import React from "react";

export const InvoiceInfo = ({ data }) => {
  const { company } = data;
  const { owner } = company;
  return (
    <div className="kt-portlet kt-portlet--bordered-semi kt-portlet--unelevate">
      <div className="kt-portlet__body" style={{ paddingBottom: 0 }}>
        <div className="kt-widget12">
          <div className="kt-widget12__content">
            <div className="kt-widget12__item">
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">
                  Стоимость, за пользователя
                </span>
                <span className="kt-widget12__value">
                  {data.costPerSlot} руб.
                </span>
              </div>

              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">
                  Количество доступных пользователей
                </span>
                <span className="kt-widget12__value">{data.slots}</span>
              </div>
            </div>
            <div className="kt-widget12__item">
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">Общая сумма счёта</span>
                <span className="kt-widget12__value">{data.total} руб.</span>
              </div>

              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">Дата запроса</span>
                <span className="kt-widget12__value">
                  {moment(data.createdAt).format("YYYY-MM-DD HH:mm")}
                </span>
              </div>
            </div>
            <div className="kt-widget12__item" style={{ marginBottom: 0 }}>
              <div className="kt-widget12__info">
                <span className="kt-widget12__desc">Контактная информация</span>
                <div className="kt-widget13" style={{ paddingBottom: 0 }}>
                  <div className="kt-widget13__item">
                    <span className="kt-widget13__desc">Название компании</span>
                    <span className="kt-widget13__text kt-widget13__text--bold">
                      {company.name}
                    </span>
                  </div>
                  <div className="kt-widget13__item">
                    <span className="kt-widget13__desc kt-align-right">
                      Контактный email:
                    </span>
                    <span className="kt-widget13__text kt-widget13__text--bold">
                      {company.email}
                    </span>
                  </div>
                  <div className="kt-widget13__item">
                    <span className="kt-widget13__desc">Руководитель:</span>
                    <span className="kt-widget13__text">{owner.name}</span>
                  </div>
                  <div className="kt-widget13__item">
                    <span className="kt-widget13__desc">Контактный email:</span>
                    <span className="kt-widget13__text kt-widget13__text--bold">
                      {owner.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
