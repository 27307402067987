import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authenticated: false,
  user: undefined,
  token: undefined,
  company: undefined,
  refreshToken: undefined
};

const impersonatePerformer = createSlice({
  name: "impersonatePerformer",
  initialState,
  reducers: {
    setPerformer: (state, action) => {
      return action.payload;
    },
    resetPerformer: () => {
      return initialState;
    }
  }
});

export default impersonatePerformer.reducer;
export const { setPerformer, resetPerformer } = impersonatePerformer.actions;
