import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import i18n from "../../i18n/i18n";
import {
  activatePromocodeByCode,
  createPromocode,
  updatePromocodeById
} from "../../crud/promocode.api";

export const activatePromocode = code => async dispatch => {
  try {
    await activatePromocodeByCode(code);
    dispatch(
      openSnackbar(Fade, i18n.t("Промокод был успеншно применён!"), "success")
    );
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const updatePromocode = (id, values) => async dispatch => {
  try {
    await updatePromocodeById(id, values);
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};

export const addPromocode = values => async dispatch => {
  try {
    await createPromocode(values);
    return true;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return false;
  }
};
