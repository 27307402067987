import { createSlice } from "@reduxjs/toolkit";
import * as routerHelpers from "../../router/RouterHelpers";
import { getCompanyByUserId } from "../../crud/settings.crud";
import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "./snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import { login } from "../../crud/auth.crud";
import { setActiveUser } from "./calendarFeatureSlice";
import { getUsersList } from "../thunks/user.thunk";
import { shouldOpenTutorial } from "./tutorialFeatureSlice";
import { batch } from "react-redux";
import { createSelector } from "reselect";
import { fetchSubscription } from "./subscriptionFeatureSlice";

const initialState = {
  authenticated: false,
  user: undefined,
  token: undefined,
  company: undefined,
  refreshToken: undefined,
  disableTutorial: false
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
    setAuthTokens: (state, action) => {
      const { token, refreshToken } = action.payload;
      state.token = token;
      state.refreshToken = refreshToken;
    },
    updateUserInfo: (state, action) => {
      const { user } = action.payload;
      state.user = user;
    },
    saveCompany: (state, action) => {
      const { company } = action.payload;

      return { ...state, company };
    },
    saveTokens: (state, action) => {
      const { token, refreshToken } = action.payload;

      return { ...state, token, refreshToken };
    },
    setStatus: (state, action) => {
      const { status } = action.payload;

      return { ...state, user: { ...state.user, status } };
    },
    register: (state, action) => {
      const { token } = action.payload;

      return { token, user: undefined };
    },
    logout: (state, action) => {
      routerHelpers.forgotLastLocation();
      return initialState;
    },
    restorePerformer: (state, action) => {
      return action.payload;
    },
    setTutorialDisabled: state => {
      state.disableTutorial = true;
    }
  }
});

export const onAuthentication = (
  { email, password, lang },
  rememberMe
) => async dispatch => {
  return new Promise(resolve =>
    batch(async () => {
      try {
        const {
          data: { user, jwtToken, refreshToken }
        } = await login(email, password, rememberMe, lang);

        dispatch(
          setAuthTokens({
            token: jwtToken,
            refreshToken
          })
        );

        if (user && user.status !== "first-login") {
          await dispatch(fetchCompany());
          await dispatch(getUsersList());
        }

        if (user && user.status === "active") {
          dispatch(fetchSubscription());
        }

        dispatch(setActiveUser({ userId: user.id }));

        dispatch(
          setUser({
            user
          })
        );
        dispatch(shouldOpenTutorial());
      } catch (e) {
        const { header, message } = extractErrorInfo(e);
        dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
      }
      resolve();
    })
  );
};

export const fetchCompany = () => async dispatch => {
  try {
    const { data } = await getCompanyByUserId();
    dispatch(saveCompany({ company: data }));
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
  }
};

export const logoutAndClearStorage = () => dispatch => {
  dispatch(logout());
  dispatch({ type: "RESET" });
};

export const {
  setUser,
  setAuthTokens,
  updateUserInfo,
  saveCompany,
  saveTokens,
  setStatus,
  register,
  logout,
  restorePerformer,
  setTutorialDisabled
} = auth.actions;
export default auth.reducer;
export const userIdSelector = createSelector(
  state => state.auth,
  auth => auth.user.id
);
export const companySelector = createSelector(
  state => state.auth,
  auth => auth.company
);
