import axios from "axios";
import { PROJECT_URL } from "../constants/api";

export const mergeRequest = data => {
  return axios.post(`${PROJECT_URL}/merge`, data);
};

export const getProjectRequest = id => {
  return axios.get(`${PROJECT_URL}/${id}`, {
    headers: {
      "X-Project-Token": id
    }
  });
};

export const getProjectsListRequest = () => {
  return axios.get(`${PROJECT_URL}/list`);
};

export const getProjectsListByIdRequest = ids => {
  return axios.post(`${PROJECT_URL}/by-id`, ids);
};

export const updateProjectRequest = (id, values) => {
  return axios.post(`${PROJECT_URL}/${id}`, values);
};

export const deleteProjectByIdRequest = id => {
  return axios.delete(`${PROJECT_URL}/${id}`);
};

export const createProjectRequest = values => {
  return axios.post(`${PROJECT_URL}/create`, values);
};
