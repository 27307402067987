import { extractErrorInfo } from "../../utils/extractError";
import { openSnackbar } from "../features/snackbarFeatureSlice";
import { Fade } from "@material-ui/core";
import {
  getProjectCalendarSummary,
  getProjectSummary
} from "../../crud/statistics.api";

export const getSummary = (projectId, from, to) => async dispatch => {
  try {
    const { data } = await getProjectSummary(
      projectId,
      from.toISOString(),
      to.toISOString()
    );
    return data;
  } catch (e) {
    const { header, message } = extractErrorInfo(e);
    dispatch(openSnackbar(Fade, `${header}. ${message || ""}`, "error"));
    return undefined;
  }
};
