import axios from "axios";
import { STATISTICS_URL } from "../constants/api";

export const getWorkStatistics = (userId, day) => {
  return axios.get(`${STATISTICS_URL}/working-day`, {
    params: { userId, day }
  });
};

export const getProjectSummary = (projectId, from, to) => {
  return axios.get(`${STATISTICS_URL}/project/summary`, {
    params: { projectId, from, to },
    headers: { "X-Project-Token": projectId }
  });
};

export const getProjectCalendarSummary = (projectId, from, to) => {
  return axios.get(`${STATISTICS_URL}/project/calendar`, {
    params: { projectId, from, to },
    headers: { "X-Project-Token": projectId }
  });
};
