import React from "react";
import MaskedInput from "react-text-mask";
import { useTranslation } from "react-i18next";

export default function TimeMaskInput({ inputRef, ...other }) {
  const { t } = useTranslation();
  const { value } = other;
  const isTwenty = value[0] === "2";
  const mask = [/[0-2]/, isTwenty ? /[0-3]/ : /[0-9]/, ":", "00"];
  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholder={t("ЧЧ:ММ")}
    />
  );
}
